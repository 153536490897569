import React, { useEffect, useRef, useState } from "react";
import * as echarts from "echarts";
import axios from "axios";
import '../PieChart.css';
import { useLanguage } from '../../../../../../LanguageContext';

const LineChartWithPie = () => {
  const { language } = useLanguage();
  const chartRef = useRef(null);
  const [selectedYear, setSelectedYear] = useState("2021");
  const [chartData, setChartData] = useState([]);

  const monthTranslations = {
    January: { english: 'January', french: 'Janvier', arabic: 'يناير' },
    February: { english: 'February', french: 'Février', arabic: 'فبراير' },
    March: { english: 'March', french: 'Mars', arabic: 'مارس' },
    April: { english: 'April', french: 'Avril', arabic: 'أبريل' },
    May: { english: 'May', french: 'Mai', arabic: 'مايو' },
    June: { english: 'June', french: 'Juin', arabic: 'يونيو' },
    July: { english: 'July', french: 'Juillet', arabic: 'يوليو' },
    August: { english: 'August', french: 'Août', arabic: 'أغسطس' },
    September: { english: 'September', french: 'Septembre', arabic: 'سبتمبر' },
    October: { english: 'October', french: 'Octobre', arabic: 'أكتوبر' },
    November: { english: 'November', french: 'Novembre', arabic: 'نوفمبر' },
    December: { english: 'December', french: 'Décembre', arabic: 'ديسمبر' },
  };

  const genderTranslations = {
    "Males": { english: 'Males', french: 'Hommes', arabic: 'ذكور' },
    "Females": { english: 'Females', french: 'Femmes', arabic: 'إناث' },
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.post(`${process.env.REACT_APP_API}/api/monthly_statistics_population_by_gender`, { year: selectedYear });
        setChartData(response.data);
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };

    fetchData();
  }, [selectedYear]);

  useEffect(() => {
    if (!chartData.length) return;

    const chart = echarts.init(chartRef.current);

    const months = {
      "January": monthTranslations.January[language],
      "February": monthTranslations.February[language],
      "March": monthTranslations.March[language],
      "April": monthTranslations.April[language],
      "May": monthTranslations.May[language],
      "June": monthTranslations.June[language],
      "July": monthTranslations.July[language],
      "August": monthTranslations.August[language],
      "September": monthTranslations.September[language],
      "October": monthTranslations.October[language],
      "November": monthTranslations.November[language],
      "December": monthTranslations.December[language],
    };

    const genders = {
      "Males": genderTranslations["Males"][language],
      "Females": genderTranslations["Females"][language],
    };

    const translatedData = chartData.map((item) => {
      const category = item[0];
      const translatedCategory = genders[category] || category;
      const data = item.map((value, index) => {
        if (index === 0) return translatedCategory;
        return value;
      });
      return data;
    });

    const dataset = {
      source: translatedData,
    };

    const option = {
      legend: {
        textStyle: {
          color: "white" 
        },
        data: Object.values(genders), 
      },
      tooltip: {
        trigger: "axis",
        showContent: false,
      },
      dataset: dataset,
      xAxis: { 
        type: "category",
        axisLabel: {
          interval: 0,
          fontSize: 14,
          textStyle: {
            color: "white" 
          },
          formatter: (value) => months[value] || value 
        },
        data: dataset.source[0].slice(1), 
      },
      yAxis: { 
        gridIndex: 0,
        axisLabel: {
          textStyle: {
            color: "white" 
          }
        }
      },
      grid: { top: "55%" },
      series: [
        { type: "line", smooth: true, seriesLayoutBy: "row", emphasis: { focus: "series" } },
        { type: "line", smooth: true, seriesLayoutBy: "row", emphasis: { focus: "series" } },
        {
          type: "pie",
          id: "pie",
          radius: "30%",
          center: ["50%", "25%"],
          emphasis: { focus: "self" },
          label: { formatter: "{b}: {@January} ({d}%)",textStyle: {color: "white" },fontSize:15},
          encode: { itemName: "product", value: "January", tooltip: "January" },
        },
      ],
    };
    

    chart.on("updateAxisPointer", function (event) {
      const xAxisInfo = event.axesInfo[0];
      if (xAxisInfo) {
        const dimension = xAxisInfo.value + 1;
        chart.setOption({
          series: {
            id: "pie",
            label: { 
              textStyle: {
                color: "white" 
              },
              
              formatter: "{b}: {@[" + dimension + "]} ({d}%)" },
            encode: { value: dimension, tooltip: dimension },
          },
        });
      }
    });

    chart.setOption(option);

    return () => {
      chart.dispose();
    };
  }, [chartData, language]);

  return (
    <>
      <div>
        <label htmlFor="year"  style={{fontSize:"1rem", fontWeight:"600", color:"white"}}>
          {language === 'english' ? 'Select a year:' : language === 'french' ? 'Sélectionnez une année:' : 'اختر عامًا:'}
        </label>
        <select className='select' id="year" onChange={(e) => setSelectedYear(e.target.value)} value={selectedYear}>
          {["2018", "2019", "2020", "2021", "2022", "2023", "2024", "2025", "2026", "2027", "2028", "2029", "2030", "2031", "2032"].map(year => (
            <option key={year} value={year}>{year}</option>
          ))}
        </select>
      </div>
      <div ref={chartRef} style={{ width: "100%", height: "425px" }} />
    </>
  );
};

export default LineChartWithPie;