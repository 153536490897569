import React, { useState } from 'react';
import { Container, Row, Col, Button } from 'reactstrap';
import Slider from 'react-slick';
import { AiOutlineTable, AiOutlineBarChart ,   } from 'react-icons/ai';
import { FaGears } from "react-icons/fa6";
import { FaRegChartBar, FaIndustry, FaTrafficLight ,FaBuilding } from 'react-icons/fa';
import { GiWaterDrop } from 'react-icons/gi';
import { MdAttachMoney, MdOutlineRealEstateAgent, MdOutlineDevicesOther } from 'react-icons/md';
import { MdOutlineHotel } from "react-icons/md";
import { FaPeopleRoof } from "react-icons/fa6";
import electronic from './Modals/ElectronicModal/Chart/Component';
import climate from './Modals/EnvironmentAndEnergyModal/Charts/qatar_monthly_statistics_climateComponent';
import sewage from './Modals/EnvironmentAndEnergyModal/Charts/qatar_monthly_statistics_sewage_waterComponent'
import totalwater from './Modals/EnvironmentAndEnergyModal/Charts/qatar_monthly_statistics_total_water_productionComponent'
import waterconsumation from './Modals/EnvironmentAndEnergyModal/Charts/qatar_monthly_statistics_water_consumptionComponent'
import deposits from './Modals/FinanceModal/Charts/qatar_monthly_statistics_commercial_banks_depositsComponent' 
import money from './Modals/FinanceModal/Charts/qatar_monthly_statistics_money_supplyComponent'
import shares from './Modals/FinanceModal/Charts/qatar_monthly_statistics_qatar_exchange_number_of_sharesComponent'
import priceindex from './Modals/PriceIndexModal/Charts/Component'
import properties from './Modals/PropertiesModal/Charts/number_of_sold_properties_by_municipalityComponent'
import propertiesvalue from './Modals/PropertiesModal/Charts/Qatar_Monthly_Statistics_Value_Sold_Properties_by_municipalityComponent'
import averageroomrate from './Modals/TourismModal/Charts/qatar_monthly_statistics_average_room_rateComponent'
import hotelroomrate from './Modals/TourismModal/Charts/qatar_monthly_statistics_hotel_average_room_rateComponent'
import hoteloccupancy from './Modals/TourismModal/Charts/qatar_monthly_statistics_hotel_occupancy_rateComponent'
import revenue from './Modals/TourismModal/Charts/qatar_monthly_statistics_revenue_per_available_roomComponent'
import entrymode from './Modals/TourismModal/Charts/qatar_monthly_statistics_visitor_arrivals_by_mode_of_entryComponent'
import groupvisitor from './Modals/TourismModal/Charts/qatar_monthly_statistics_visitors_by_country_groupComponent'
import trade from './Modals/TradeModal/Charts/Component'
import licenses from './Modals/TrafficModal/Charts/qatar_monthly_statistics_new_driving_licensesComponent'
import vehicle from './Modals/TrafficModal/Charts/qatar_monthly_statistics_registered_new_vehicleComponent'
import violation  from './Modals/TrafficModal/Charts/qatar_monthly_statistics_traffic_violationsComponent'
import deaths from './Modals/PopulationModal/Charts/BarChart/qatar_monthly_statistics_deaths_by_monthComponent'
import divorces from './Modals/PopulationModal/Charts/BarChart/qatar_monthly_statistics_divorcesComponent'
import livebirth from './Modals/PopulationModal/Charts/BarChart/qatar_monthly_statistics_live_birthsComponent'
import marriages from './Modals/PopulationModal/Charts/BarChart/qatar_monthly_statistics_marriagesComponent'
import social from './Modals/PopulationModal/Charts/BarChart/qatar_monthly_statistics_social_security_valueComponent'
import population from './Modals/PopulationModal/Charts/BarChart/qatar_monthly_statistics_total_populationComponent.jsx'
import deathgender from './Modals/PopulationModal/Charts/PieChart/qatar_monthly_statistics_deaths_by_genderComponent'
import deathnationality from './Modals/PopulationModal/Charts/PieChart/qatar_monthly_statistics_deaths_by_nationalityComponent'
import divorcesnationality from './Modals/PopulationModal/Charts/PieChart/qatar_monthly_statistics_divorces_by_nationality_genderComponent'
import livebithgender from './Modals/PopulationModal/Charts/PieChart/qatar_monthly_statistics_live_births_by_genderComponent'
import livebirthnationality  from './Modals/PopulationModal/Charts/PieChart/qatar_monthly_statistics_live_births_by_nationalityComponent'
import mariagesnationality from './Modals/PopulationModal/Charts/PieChart/qatar_monthly_statistics_marriages_by_nationalityComponent'
import agegroup from './Modals/PopulationModal/Charts/PieChart/qatar_monthly_statistics_population_by_age_groupComponent'
import populationgender from './Modals/PopulationModal/Charts/PieChart/qatar_monthly_statistics_population_by_genderComponent'
import realstate from './Modals/PropertiesModal/Charts/weekly_real_estate_newsletterComponent'
import './CardSlider.css';
import electro from './Tables/ElectronicTable/table_component';
import tclimate from './Tables/EnvironmentAndEnergyModal/Charts/qatar_monthly_statistics_climateComponent';
import tsewage from './Tables/EnvironmentAndEnergyModal/Charts/qatar_monthly_statistics_sewage_waterComponent'
import ttotalwater from './Tables/EnvironmentAndEnergyModal/Charts/qatar_monthly_statistics_total_water_productionComponent'
import twaterconsumation from './Tables/EnvironmentAndEnergyModal/Charts/qatar_monthly_statistics_water_consumptionComponent'
import tdeposits from './Tables/FinanceModal/Charts/qatar_monthly_statistics_commercial_banks_depositsComponent' 
import tmoney from './Tables/FinanceModal/Charts/qatar_monthly_statistics_money_supplyComponent'
import tshares from './Tables/FinanceModal/Charts/qatar_monthly_statistics_qatar_exchange_number_of_sharesComponent'
import tpriceindex from './Tables/PriceIndexModal/Charts/Component'
import tproperties from './Tables/PropertiesModal/Charts/number_of_sold_properties_by_municipalityComponent'
import tpropertiesvalue from './Tables/PropertiesModal/Charts/Qatar_Monthly_Statistics_Value_Sold_Properties_by_municipalityComponent'
import taverageroomrate from './Tables/TourismModal/Charts/qatar_monthly_statistics_average_room_rateComponent'
import thotelroomrate from './Tables/TourismModal/Charts/qatar_monthly_statistics_hotel_average_room_rateComponent'
import thoteloccupancy from './Tables/TourismModal/Charts/qatar_monthly_statistics_hotel_occupancy_rateComponent'
import trevenue from './Tables/TourismModal/Charts/qatar_monthly_statistics_revenue_per_available_roomComponent'
import tentrymode from './Tables/TourismModal/Charts/qatar_monthly_statistics_visitor_arrivals_by_mode_of_entryComponent'
import tgroupvisitor from './Tables/TourismModal/Charts/qatar_monthly_statistics_visitors_by_country_groupComponent'
import ttrade from './Tables/TradeModal/Charts/Component'
import tlicenses from './Tables/TrafficModal/Charts/qatar_monthly_statistics_new_driving_licensesComponent'
import tvehicle from './Tables/TrafficModal/Charts/qatar_monthly_statistics_registered_new_vehicleComponent'
import tviolation  from './Tables/TrafficModal/Charts/qatar_monthly_statistics_traffic_violationsComponent'
import tdeaths from './Tables/PopulationModal/Charts/BarChart/qatar_monthly_statistics_deaths_by_monthComponent'
import tdivorces from './Tables/PopulationModal/Charts/BarChart/qatar_monthly_statistics_divorcesComponent'
import tlivebirth from './Tables/PopulationModal/Charts/BarChart/qatar_monthly_statistics_live_birthsComponent'
import tmarriages from './Tables/PopulationModal/Charts/BarChart/qatar_monthly_statistics_marriagesComponent'
import tsocial from './Tables/PopulationModal/Charts/BarChart/qatar_monthly_statistics_social_security_valueComponent'
import tpopulation from './Tables/PopulationModal/Charts/BarChart/qatar_monthly_statistics_total_populationComponent.jsx'
import tdeathgender from './Tables/PopulationModal/Charts/PieChart/qatar_monthly_statistics_deaths_by_genderComponent'
import tdeathnationality from './Tables/PopulationModal/Charts/PieChart/qatar_monthly_statistics_deaths_by_nationalityComponent'
import tdivorcesnationality from './Tables/PopulationModal/Charts/PieChart/qatar_monthly_statistics_divorces_by_nationality_genderComponent'
import tlivebithgender from './Tables/PopulationModal/Charts/PieChart/qatar_monthly_statistics_live_births_by_genderComponent'
import tlivebirthnationality  from './Tables/PopulationModal/Charts/PieChart/qatar_monthly_statistics_live_births_by_nationalityComponent'
import tmariagesnationality from './Tables/PopulationModal/Charts/PieChart/qatar_monthly_statistics_marriages_by_nationalityComponent'
import tagegroup from './Tables/PopulationModal/Charts/PieChart/qatar_monthly_statistics_population_by_age_groupComponent'
import tpopulationgender from './Tables/PopulationModal/Charts/PieChart/qatar_monthly_statistics_population_by_genderComponent'
import trealstate from './Tables/PropertiesModal/Charts/weekly_real_estate_newsletterComponent'
import { useLanguage } from "../../LanguageContext"; 
import translations from '../../../translations.json'; 
const CardSlider = () => {
  const [activeCategory, setActiveCategory] = useState('Population');
  const [activeChartComponent, setActiveChartComponent] = useState(null);
  const [activeTableComponent, setActiveTableComponent] = useState(null);
  const { language } = useLanguage(); 
  const currentTranslations = translations[language]; 

  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 1,
    draggable: false, // Disable sliding with the mouse

    responsive: [
      {
        breakpoint: 1350,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
    nextArrow: <NextArrow />,
    prevArrow: <PrevArrow />,
  };
  const populationData = [
    {
      title: "Live Births by Nationality",
      body: "This table shows monthly live birth numbers by nationality and gender, including Qatari and non-Qatari males and females.",
      title_ar: "مواليد أحياء حسب الجنسية",
      body_ar: "يُظهر هذا الجدول عدد المواليد الأحياء شهريًا حسب الجنسية والجنس، بما في ذلك الذكور والإناث القطريين وغير القطريين.",
      title_fr: "Naissances vivantes par nationalité",
      body_fr: "Ce tableau montre le nombre mensuel de naissances vivantes par nationalité et sexe, y compris les hommes et les femmes qataris et non-qataris."
    },
    {
      title: "Marriages",
      body: "This table presents the total number of marriages registered each month in Qatar.",
      title_ar: "الزواج",
      body_ar: "يُقدم هذا الجدول إجمالي عدد عقود الزواج المسجلة شهريًا في قطر.",
      title_fr: "Mariages",
      body_fr: "Ce tableau présente le nombre total de mariages enregistrés chaque mois au Qatar."
    },
    {
      title: "Marriages by Nationality",
      body: "This table provides details on the number of marriages each month by the nationality of the spouses.",
      title_ar: "الزواج حسب الجنسية",
      body_ar: "يوفر هذا الجدول تفاصيل عن عدد عقود الزواج شهريًا حسب جنسية الزوجين.",
      title_fr: "Mariages par nationalité",
      body_fr: "Ce tableau fournit des détails sur le nombre de mariages chaque mois par la nationalité des époux."
    },
    {
      title: "Population by Age Group",
      body: "This table shows the monthly population data categorized by different age groups.",
      title_ar: "السكان حسب الفئة العمرية",
      body_ar: "يُظهر هذا الجدول بيانات السكان الشهرية المصنفة حسب مختلف الفئات العمرية.",
      title_fr: "Population par groupe d'âge",
      body_fr: "Ce tableau montre les données démographiques mensuelles classées par différents groupes d'âge."
    },
    {
      title: "Population by Gender",
      body: "This table provides the monthly population statistics split by gender.",
      title_ar: "السكان حسب الجنس",
      body_ar: "يوفر هذا الجدول إحصاءات السكان الشهرية مقسمة حسب الجنس.",
      title_fr: "Population par sexe",
      body_fr: "Ce tableau fournit les statistiques démographiques mensuelles ventilées par sexe."
    },
    {
      title: "Social Security Value",
      body: "This table displays the monthly social security value in thousand Qatari Riyals.",
      title_ar: "قيمة الضمان الاجتماعي",
      body_ar: "يعرض هذا الجدول قيمة الضمان الاجتماعي الشهرية بالألوف من الريالات القطرية.",
      title_fr: "Valeur de la sécurité sociale",
      body_fr: "Ce tableau affiche la valeur mensuelle de la sécurité sociale en milliers de rials qataris."
    },
    {
      title: "Deaths by Gender",
      body: "This table presents the total number of deaths each month categorized by gender.",
      title_ar: "الوفيات حسب الجنس",
      body_ar: "يُقدم هذا الجدول إجمالي عدد الوفيات شهريًا مصنفة حسب الجنس.",
      title_fr: "Décès par sexe",
      body_fr: "Ce tableau présente le nombre total de décès chaque mois classés par sexe."
    },
    {
      title: "Deaths by Month",
      body: "This table shows the total number of deaths for each month.",
      title_ar: "الوفيات حسب الشهر",
      body_ar: "يُظهر هذا الجدول إجمالي عدد الوفيات لكل شهر.",
      title_fr: "Décès par mois",
      body_fr: "Ce tableau montre le nombre total de décès pour chaque mois."
    },
    {
      title: "Deaths by Nationality",
      body: "This table provides the number of deaths each month, broken down by nationality and gender.",
      title_ar: "الوفيات حسب الجنسية",
      body_ar: "يوفر هذا الجدول عدد الوفيات شهريًا، مقسمًا حسب الجنسية والجنس.",
      title_fr: "Décès par nationalité",
      body_fr: "Ce tableau fournit le nombre de décès chaque mois, ventilé par nationalité et sexe."
    },
    {
      title: "Divorces",
      body: "This table shows the total number of divorces registered each month.",
      title_ar: "الطلاق",
      body_ar: "يُظهر هذا الجدول إجمالي عدد الطلاق المسجل شهريًا.",
      title_fr: "Divorces",
      body_fr: "Ce tableau montre le nombre total de divorces enregistrés chaque mois."
    },
    {
      title: "Divorces by Nationality and Gender",
      body: "This table provides details on divorces each month by the nationality and gender of the individuals.",
      title_ar: "الطلاق حسب الجنسية والجنس",
      body_ar: "يوفر هذا الجدول تفاصيل عن الطلاق شهريًا حسب جنسية الأفراد وجنسهم.",
      title_fr: "Divorces par nationalité et sexe",
      body_fr: "Ce tableau fournit des détails sur les divorces chaque mois par la nationalité et le sexe des individus."
    },
    {
      title: "Live Births",
      body: "This table shows the total number of live births each month.",
      title_ar: "مواليد أحياء",
      body_ar: "يُظهر هذا الجدول إجمالي عدد المواليد الأحياء شهريًا.",
      title_fr: "Naissances vivantes",
      body_fr: "Ce tableau montre le nombre total de naissances vivantes chaque mois."
    },
    {
      title: "Live Births by Gender",
      body: "This table provides the total number of live births each month split by gender.",
      title_ar: "مواليد أحياء حسب الجنس",
      body_ar: "يوفر هذا الجدول إجمالي عدد المواليد الأحياء شهريًا مقسمًا حسب الجنس.",
      title_fr: "Naissances vivantes par sexe",
      body_fr: "Ce tableau fournit le nombre total de naissances vivantes chaque mois ventilé par sexe."
    },
    {
      title: "Total Population",
      body: "This table presents the total population of Qatar each month.",
      title_ar: "إجمالي السكان",
      body_ar: "يُقدم هذا الجدول إجمالي سكان قطر شهريًا.",
      title_fr: "Population totale",
      body_fr: "Ce tableau présente la population totale du Qatar chaque mois."
    }
  ];
  const priceIndexData = [
    {
      title: "Consumer Price Index",
      body: "This table shows the monthly consumer price index, including categories such as food and beverage, housing, and more.",
      title_ar: "مؤشر أسعار المستهلك",
      body_ar: "يُظهر هذا الجدول مؤشر أسعار المستهلك الشهري، بما في ذلك فئات مثل الغذاء والمشروبات والسكن والمزيد.",
      title_fr: "Indice des prix à la consommation",
      body_fr: "Ce tableau montre l'indice des prix à la consommation mensuel, y compris des catégories telles que les aliments et les boissons, le logement, et plus."
    }
  ];
  const tradeData = [
    {
      title: "Arriving Vessels Movements",
      body: "This table shows the monthly number of arriving vessels and their total net tonnage.",
      title_ar: "حركة السفن الواصلة",
      body_ar: "يُظهر هذا الجدول عدد السفن الواصلة شهريًا وإجمالي حمولتها الصافية.",
      title_fr: "Mouvements des navires arrivant",
      body_fr: "Ce tableau montre le nombre mensuel de navires arrivant et leur tonnage net total."
    }
  ];
  const environmentData = [
    {
      title: "Sewage Water",
      body: "This table shows the monthly amount of wastewater received and treated.",
      title_ar: "مياه الصرف الصحي",
      body_ar: "يُظهر هذا الجدول كمية مياه الصرف الصحي المستلمة والمعالجة شهريًا.",
      title_fr: "Eaux usées",
      body_fr: "Ce tableau montre la quantité mensuelle d'eaux usées reçues et traitées."
    },
    {
      title: "Total Water Production",
      body: "This table shows the total monthly water production in thousand cubic meters.",
      title_ar: "إجمالي إنتاج المياه",
      body_ar: "يُظهر هذا الجدول إجمالي إنتاج المياه الشهري بالألف متر مكعب.",
      title_fr: "Production totale d'eau",
      body_fr: "Ce tableau montre la production totale d'eau mensuelle en milliers de mètres cubes."
    },
    {
      title: "Water Consumption",
      body: "This table provides the total monthly water consumption in thousand cubic meters.",
      title_ar: "استهلاك المياه",
      body_ar: "يوفر هذا الجدول إجمالي استهلاك المياه الشهري بالألف متر مكعب.",
      title_fr: "Consommation d'eau",
      body_fr: "Ce tableau fournit la consommation totale d'eau mensuelle en milliers de mètres cubes."
    },
    {
      title: "Climate",
      body: "This table shows the monthly climate statistics including temperature, wind speed, humidity, and pressure.",
      title_ar: "المناخ",
      body_ar: "يُظهر هذا الجدول إحصاءات المناخ الشهرية بما في ذلك درجة الحرارة وسرعة الرياح والرطوبة والضغط.",
      title_fr: "Climat",
      body_fr: "Ce tableau montre les statistiques climatiques mensuelles, y compris la température, la vitesse du vent, l'humidité et la pression."
    }
  ];
  const financeData = [
    {
      title: "Commercial Banks Deposits",
      body: "This table shows the total monthly deposits in commercial banks, including public sector, private sector, and non-resident deposits.",
      title_ar: "ودائع البنوك التجارية",
      body_ar: "يُظهر هذا الجدول إجمالي الودائع الشهرية في البنوك التجارية، بما في ذلك القطاع العام والقطاع الخاص والودائع غير المقيمة.",
      title_fr: "Dépôts des banques commerciales",
      body_fr: "Ce tableau montre le total des dépôts mensuels dans les banques commerciales, y compris le secteur public, le secteur privé et les dépôts non résidents."
    },
    {
      title: "Money Supply",
      body: "This table shows the monthly money supply statistics, including currency issued and various money supply measures.",
      title_ar: "عرض النقود",
      body_ar: "يُظهر هذا الجدول إحصاءات عرض النقود الشهرية، بما في ذلك العملة المصدرة ومختلف مقاييس عرض النقود.",
      title_fr: "Masse monétaire",
      body_fr: "Ce tableau montre les statistiques mensuelles de la masse monétaire, y compris la monnaie émise et diverses mesures de la masse monétaire."
    },
    {
      title: "Qatar Exchange Number of Shares",
      body: "This table provides the number of shares traded on the Qatar Exchange each month.",
      title_ar: "عدد الأسهم المتداولة في بورصة قطر",
      body_ar: "يوفر هذا الجدول عدد الأسهم المتداولة في بورصة قطر شهريًا.",
      title_fr: "Nombre d'actions négociées à la Bourse du Qatar",
      body_fr: "Ce tableau fournit le nombre d'actions négociées à la Bourse du Qatar chaque mois."
    }
  ];
  const trafficData = [
    {
      title: "New Driving Licenses",
      body: "This table shows the total number of new driving licenses issued each month, categorized by nationality.",
      title_ar: "رخص القيادة الجديدة",
      body_ar: "يُظهر هذا الجدول إجمالي عدد رخص القيادة الجديدة الصادرة شهريًا، مصنفة حسب الجنسية.",
      title_fr: "Nouveaux permis de conduire",
      body_fr: "Ce tableau montre le nombre total de nouveaux permis de conduire délivrés chaque mois, classés par nationalité."
    },
    {
      title: "Registered New Vehicles",
      body: "This table provides the total number of new vehicles registered each month, categorized by vehicle type.",
      title_ar: "مركبات جديدة مسجلة",
      body_ar: "يوفر هذا الجدول إجمالي عدد المركبات الجديدة المسجلة شهريًا، مصنفة حسب نوع المركبة.",
      title_fr: "Nouveaux véhicules enregistrés",
      body_fr: "Ce tableau fournit le nombre total de nouveaux véhicules enregistrés chaque mois, classés par type de véhicule."
    },
    {
      title: "Traffic Violations",
      body: "This table shows the total number of traffic violations recorded each month, categorized by type of violation.",
      title_ar: "مخالفات المرور",
      body_ar: "يُظهر هذا الجدول إجمالي عدد مخالفات المرور المسجلة شهريًا، مصنفة حسب نوع المخالفة.",
      title_fr: "Infractions de la circulation",
      body_fr: "Ce tableau montre le nombre total d'infractions de la circulation enregistrées chaque mois, classées par type d'infraction."
    }
  ];
  const tourismData = [
    {
      title: "Hotel Average Room Rate",
      body: "This table shows the average room rate for different categories of hotels each month.",
      title_ar: "متوسط سعر الغرفة في الفنادق",
      body_ar: "يُظهر هذا الجدول متوسط سعر الغرفة لفئات مختلفة من الفنادق شهريًا.",
      title_fr: "Tarif moyen des chambres d'hôtel",
      body_fr: "Ce tableau montre le tarif moyen des chambres pour différentes catégories d'hôtels chaque mois."
    },
    {
      title: "Hotel Occupancy Rate",
      body: "This table shows the monthly occupancy rate for deluxe and standard hotels.",
      title_ar: "معدل إشغال الفنادق",
      body_ar: "يُظهر هذا الجدول معدل إشغال الفنادق الفاخرة والعادية شهريًا.",
      title_fr: "Taux d'occupation des hôtels",
      body_fr: "Ce tableau montre le taux d'occupation mensuel pour les hôtels de luxe et les hôtels standard."
    },
    {
      title: "Revenue per Available Room",
      body: "This table shows the revenue per available room for different categories of hotels each month.",
      title_ar: "الإيرادات لكل غرفة متاحة",
      body_ar: "يُظهر هذا الجدول الإيرادات لكل غرفة متاحة لفئات مختلفة من الفنادق شهريًا.",
      title_fr: "Revenus par chambre disponible",
      body_fr: "Ce tableau montre les revenus par chambre disponible pour différentes catégories d'hôtels chaque mois."
    },
    {
      title: "Visitor Arrivals by Mode of Entry",
      body: "This table shows the total number of visitor arrivals each month, categorized by mode of entry.",
      title_ar: "وصول الزوار حسب وسيلة الدخول",
      body_ar: "يُظهر هذا الجدول إجمالي عدد الزوار الواصلين شهريًا، مصنفة حسب وسيلة الدخول.",
      title_fr: "Arrivées de visiteurs par mode d'entrée",
      body_fr: "Ce tableau montre le nombre total d'arrivées de visiteurs chaque mois, classées par mode d'entrée."
    },
    {
      title: "Visitors by Country Group",
      body: "This table provides the number of visitors each month, categorized by their country group.",
      title_ar: "الزوار حسب مجموعة الدول",
      body_ar: "يوفر هذا الجدول عدد الزوار شهريًا، مصنفًا حسب مجموعة بلدانهم.",
      title_fr: "Visiteurs par groupe de pays",
      body_fr: "Ce tableau fournit le nombre de visiteurs chaque mois, classés par groupe de pays."
    },
    {
      title: "Average Room Rate",
      body: "This table shows the average room rate for deluxe and standard rooms each month.",
      title_ar: "متوسط سعر الغرفة",
      body_ar: "يُظهر هذا الجدول متوسط سعر الغرفة للفنادق الفاخرة والعادية شهريًا.",
      title_fr: "Tarif moyen des chambres",
      body_fr: "Ce tableau montre le tarif moyen des chambres pour les chambres de luxe et les chambres standard chaque mois."
    }
  ];
  const electronicData = [
    {
      title: "Transactions Through Qatar e-Government",
      body: "This table shows the total number of transactions conducted through the Qatar e-Government portal each month.",
      title_ar: "المعاملات من خلال بوابة الحكومة الإلكترونية في قطر",
      body_ar: "يُظهر هذا الجدول إجمالي عدد المعاملات التي تتم من خلال بوابة الحكومة الإلكترونية في قطر شهريًا.",
      title_fr: "Transactions via le portail du gouvernement électronique du Qatar",
      body_fr: "Ce tableau montre le nombre total de transactions effectuées via le portail du gouvernement électronique du Qatar chaque mois."
    }
  ];
  const propertiesData = [
    {
      title: "Number of Sold Properties by Municipality",
      body: "This table shows the total number of properties sold each month, categorized by municipality.",
      title_ar: "عدد العقارات المباعة حسب البلدية",
      body_ar: "يُظهر هذا الجدول إجمالي عدد العقارات المباعة شهريًا، مصنفة حسب البلدية.",
      title_fr: "Nombre de propriétés vendues par municipalité",
      body_fr: "Ce tableau montre le nombre total de propriétés vendues chaque mois, classées par municipalité."
    },
    {
      title: "Value of Sold Properties by Municipality",
      body: "This table provides the total value of properties sold each month, categorized by municipality.",
      title_ar: "قيمة العقارات المباعة حسب البلدية",
      body_ar: "يوفر هذا الجدول القيمة الإجمالية للعقارات المباعة شهريًا، مصنفة حسب البلدية.",
      title_fr: "Valeur des propriétés vendues par municipalité",
      body_fr: "Ce tableau fournit la valeur totale des propriétés vendues chaque mois, classées par municipalité."
    },
 
  ];



  const realEstateInfrastructureData = [
    {
      title: 'Weekly Real Estate Newsletter',
      title_ar: 'نشرة العقارات الأسبوعية',
      title_fr: 'Bulletin immobilier hebdomadaire',
      body: "Contains information about real estate contracts, including date, municipality, zone, area, price, and value.",
      body_ar: "تحتوي على معلومات عن عقود العقارات، بما في ذلك التاريخ والبلدية والمنطقة والمساحة والسعر والقيمة.",
      body_fr: "Contient des informations sur les contrats immobiliers, y compris la date, la municipalité, la zone, la superficie, le prix et la valeur."
    }
    // Add more data for this category
  ];

  const categories = [
    { name: 'Population', data: populationData },
    { name: 'Price Index', data: priceIndexData },
    { name: 'Trade', data: tradeData },
    { name: 'Environment & Energy', data: environmentData },
    { name: 'Finance', data: financeData },
    { name: 'Traffic', data: trafficData },
    { name: 'Tourism', data: tourismData },
    { name: 'Electronic', data: electronicData },
    { name: 'Properties', data: propertiesData },
    { name: 'Real Estate and Infrastructure', data: realEstateInfrastructureData },
    
  ];  
  const categoryIcons = {
    Population: <FaPeopleRoof size={50} />,
    "Price Index": <FaRegChartBar size={50}/>,
    Trade: <FaIndustry size={50}/>,
    "Environment & Energy": <GiWaterDrop size={50}/>,
    "Real Estate and Infrastructure": <FaBuilding size={50} />,
    Finance: <MdAttachMoney size={50}/>,
    Traffic: <FaTrafficLight size={50}/>,
    Tourism: <MdOutlineHotel size={50}/>,
    Electronic: <MdOutlineDevicesOther size={50}/>,
    Properties: <MdOutlineRealEstateAgent size={50}/>,
    
  };
  
  const cards = categories.find(category => category.name === activeCategory)?.data || [];
  const chartComponents = {
    // Mapping of card titles to components
    "Transactions Through Qatar e-Government": electronic,
    "Live Births by Nationality" : livebirthnationality,
    "Marriages": marriages ,
    "Marriages by Nationality" : mariagesnationality,
    "Population by Age Group" : agegroup ,
    "Population by Gender" :populationgender,
    "Social Security Value":social,
    "Deaths by Gender":deathgender,
    "Deaths by Month":deaths,
    "Deaths by Nationality" : deathnationality,
    "Divorces" : divorces,
    "Divorces by Nationality and Gender" : divorcesnationality,
    "Live Births":livebirth,
    "Live Births by Gender":livebithgender,
    "Total Population":population,
    "Consumer Price Index":priceindex,
    "Arriving Vessels Movements":trade,
    "Sewage Water":sewage,
    "Total Water Production":totalwater,
    "Water Consumption":waterconsumation,
    "Climate":climate,
    "Commercial Banks Deposits":deposits,
    "Money Supply":money,
    "New Driving Licenses":licenses,
    "Registered New Vehicles":vehicle,
    "Traffic Violations":violation,
    "Hotel Average Room Rate":hotelroomrate,
    "Hotel Occupancy Rate":hoteloccupancy,
    "Revenue per Available Room":revenue,
    "Visitor Arrivals by Mode of Entry":entrymode,
    "Visitors by Country Group":groupvisitor,
    "Average Room Rate":averageroomrate,
    "Number of Sold Properties by Municipality":properties,
    "Value of Sold Properties by Municipality":propertiesvalue,
    "Qatar Exchange Number of Shares":shares,
    "Weekly Real Estate Newsletter":realstate  

  };


  const handleChartIconClick = (title) => {
    const Component = chartComponents[title];
    if (Component) {
      setActiveChartComponent(<Component onClose={handleCloseChartComponent} />);
    }
  };

  const handleCloseChartComponent = () => {
    setActiveChartComponent(null);
  };


  const tableComponents = {
    // Mapping of card titles to components
    "Transactions Through Qatar e-Government": electro, 
    "Live Births by Nationality" : tlivebirthnationality,
    "Marriages": tmarriages ,
    "Marriages by Nationality" : tmariagesnationality,
    "Population by Age Group" : tagegroup ,
    "Population by Gender" :tpopulationgender,
    "Social Security Value":tsocial,
    "Deaths by Gender":tdeathgender,
    "Deaths by Month":tdeaths,
    "Deaths by Nationality" :tdeathnationality,
    "Divorces" :tdivorces,
    "Divorces by Nationality and Gender" :tdivorcesnationality,
    "Live Births":tlivebirth,
    "Live Births by Gender":tlivebithgender,
    "Total Population":tpopulation,
    "Consumer Price Index":tpriceindex,
    "Arriving Vessels Movements":ttrade,
    "Sewage Water":tsewage,
    "Total Water Production":ttotalwater,
    "Water Consumption":twaterconsumation,
    "Climate":tclimate,
    "Commercial Banks Deposits":tdeposits,
    "Money Supply":tmoney,
    "New Driving Licenses":tlicenses,
    "Registered New Vehicles":tvehicle,
    "Traffic Violations":tviolation,
    "Hotel Average Room Rate":thotelroomrate,
    "Hotel Occupancy Rate":thoteloccupancy,
    "Revenue per Available Room":trevenue,
    "Visitor Arrivals by Mode of Entry":tentrymode,
    "Visitors by Country Group":tgroupvisitor,
    "Average Room Rate":taverageroomrate,
    "Number of Sold Properties by Municipality":tproperties,
    "Value of Sold Properties by Municipality":tpropertiesvalue,
    "Qatar Exchange Number of Shares":tshares,
    "Weekly Real Estate Newsletter":trealstate  
 
  };
  const apicomponent = {
    "Live Births by Nationality" : "qatar-monthly-statistics-live-births-by-nationality",
    "Marriages":  "qatar-monthly-statistics-marriages",
    "Marriages by Nationality" : "qatar-monthly-statistics-marriages-by-nationality-2022",
    "Population by Age Group" :  "qatar-monthly-statistics-population-by-age-group",
    "Population by Gender" : "qatar-monthly-statistics-population-by-gender",
    "Social Security Value": "qatar-monthly-statistics-social-security-value",
    "Deaths by Gender": "qms-deaths-by-gender",
    "Deaths by Month": "qms-deaths-by-month",
    "Deaths by Nationality" : "qms-deaths-by-nationality",
    "Divorces" : "qms-divorces",
    "Divorces by Nationality and Gender" : "qms-divorces-by-nationality-gender",
    "Live Births": "qms-live-births",
    "Live Births by Gender": "qms-live-births-by-gender",
    "Total Population": "qatar-monthly-statistics-total-population-of-2022",
    "Consumer Price Index": "qms-arriving-vessels-movements",
    "Arriving Vessels Movements": "qms-arriving-vessels-movements1",
    "Sewage Water": "qatar-monthly-statistics-sewage-water",
    "Total Water Production": "qatar-monthly-statistics-total-water-production",
    "Water Consumption": "qatar-monthly-statistics-water-consumption",
    "Climate": "qms-climate-2022",
    "Commercial Banks Deposits": "qms-commercial-banks-deposits",
    "Money Supply": "qatar-monthly-statistics-money-supply",
    "New Driving Licenses": "qatar-monthly-statistics-new-driving-licenses",
    "Registered New Vehicles": "qatar-monthly-statistics-registered-new-vehicle",
    "Traffic Violations": "qatar-monthly-statistics-traffic-violations",
    "Hotel Average Room Rate": "qms-hotel-average-room-rate",
    "Hotel Occupancy Rate": "qatar-monthly-statistics-hotel-occupancy-rate-2022",
    "Revenue per Available Room": "qatar-monthly-statistics-revenue-per-available-room",
    "Visitor Arrivals by Mode of Entry": "qatar-monthly-statistics-visitor-arrivals-by-mode-of-entery",
    "Visitors by Country Group": "qatar-monthly-statistics-tourism",
    "Average Room Rate": "qms-average-room-rate",
    "Transactions Through Qatar e-Government": "qatar-monthly-statistics-transactions-through-qatar-e-government",
    "Number of Sold Properties by Municipality": "number-of-sold-properties-by-municipality",
    "Value of Sold Properties by Municipality": "qatar-monthly-statisticsvisitor-arrivals-by-mode-of-entry",
    "Qatar Exchange Number of Shares": "qatar-monthly-statistics-qatar-exchange-number-of-shares",
    "Weekly Real Estate Newsletter": "weekly-real-estate-newsletter"
  };
  const handleApiIconClick = (title) => {
    // We use apicomponent[title] directly, since it holds the dataset name
    const datasetUrl = `/api/${apicomponent[title]}`; 

    // Navigate to the URL
    window.location.href = datasetUrl;
  };
  const handleTableIconClick = (title) => {
    const ComponentTable = tableComponents[title];
    if (ComponentTable) {
      setActiveTableComponent(<ComponentTable onClose={handleCloseTableComponent} />);
    }
  };

  const handleCloseTableComponent = () => {
    setActiveTableComponent(null);
  };
  return (
    <div>
      <Container style={{ direction: language === 'arabic' ? 'rtl' : 'ltr' }} className="card-slider-container">
        <h1>{currentTranslations["Qatar Monthly Statistics"]}</h1>
        <h4 style={{ marginBottom: "20px", fontWeight: "bold" }}>{currentTranslations["Period from January 2018 until March 2024"]}</h4>

        <Row>
          <Col>
            <div className="button-group">
              {categories.map((category, index) => (
                <button
                  key={index}
                  className={`category-button ${activeCategory === category.name ? 'btnn' : ''}`}
                  onClick={() => setActiveCategory(category.name)}
                >
                  {currentTranslations[category.name]} 
                </button>
              ))}
            </div>
          </Col>
        </Row>
        <Row>
          <Col>
            <h2 className="population-header">
            <span className="icon-title-gap" style={{ marginRight: language === 'arabic' ? '0' : '10px', marginLeft: language === 'arabic' ? '10px' : '0' }}>{categoryIcons[activeCategory]}</span>  {currentTranslations[activeCategory]}
            </h2>
            {cards.length < 5 ? (
              <div className="card-grid">
                {cards.map((card, index) => (
                  <div className="card" key={index}>
                    <div className="card-title">{language === 'arabic' ? card.title_ar : language === 'french' ? card.title_fr : card.title}</div>
                    <div className="card-body">{language === 'arabic' ? card.body_ar : language === 'french' ? card.body_fr : card.body}</div>
                    <div className="card-footer">
                    <button
  className="transparent-btn"
  onClick={() => handleTableIconClick(card.title)}
  style={{ float: language === 'arabic' ? 'right' : 'left' }}
  title={language === 'english' ? 'Table' : language === 'french' ? 'Tableau' : 'الجدول'}
>
  <AiOutlineTable />
</button>
<button
  className="transparent-btn"
  onClick={() => handleChartIconClick(card.title)}
  style={{ float: language === 'arabic' ? 'right' : 'left' }}
  title={language === 'english' ? 'Chart' : language === 'french' ? 'Diagramme' : 'مخطط'}
>
  <AiOutlineBarChart />
</button>

<button
  className="transparent-btn"
  onClick={() => handleApiIconClick(card.title)}
  style={{ float: language === 'arabic' ? 'right' : 'left' }}
  title={language === 'english' ? 'API Settings' : language === 'french' ? 'Paramètres API' : 'إعدادات الواجهة البرمجية'}
>
  <FaGears />
</button>
                    </div>
                  </div>
                ))}
              </div>
            ) : (
              <div className="card-slider">
                <Slider {...settings}>
                  {cards.map((card, index) => (
                    <div className="card" key={index}>
                      <div className="card-title">{language === 'arabic' ? card.title_ar : language === 'french' ? card.title_fr : card.title}</div>
                      <div className="card-body">{language === 'arabic' ? card.body_ar : language === 'french' ? card.body_fr : card.body}</div>
                      <div className="card-footer">
                      <button
  className="transparent-btn"
  onClick={() => handleTableIconClick(card.title)}
  style={{ float: language === 'arabic' ? 'right' : 'left' }}
  title={language === 'english' ? 'Table' : language === 'french' ? 'Tableau' : 'الجدول'}
>
  <AiOutlineTable />
</button>
<button
  className="transparent-btn"
  onClick={() => handleChartIconClick(card.title)}
  style={{ float: language === 'arabic' ? 'right' : 'left' }}
  title={language === 'english' ? 'Chart' : language === 'french' ? 'Diagramme' : 'مخطط'}
>
  <AiOutlineBarChart />
</button>

<button
  className="transparent-btn"
  onClick={() => handleApiIconClick(card.title)}
  style={{ float: language === 'arabic' ? 'right' : 'left' }}
  title={language === 'english' ? 'API Settings' : language === 'french' ? 'Paramètres API' : 'إعدادات الواجهة البرمجية'}
>
  <FaGears />
</button>
                      </div>
                    </div>
                  ))}
                </Slider>
              </div>
            )}
        </Col>
      </Row>

      {/* Conditional rendering for active chart component */}
    </Container>
{activeChartComponent && activeChartComponent}
{activeTableComponent && activeTableComponent}

</div>
  );

};

const NextArrow = (props) => {
  const { onClick } = props;
  return <div className="next-arrow" onClick={onClick}>&#10095;</div>;
};

const PrevArrow = (props) => {
  const { onClick } = props;
  return <div className="prev-arrow" onClick={onClick}>&#10094;</div>;
};

export default CardSlider;