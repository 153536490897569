// roleManager.js
let role = null;

const setRole = (newRole) => {
  role = newRole;
};

const getRole = () => role;

const initializeRole = (initialRole) => {
  if (role === null) { // Ensure role is set only once
    role = initialRole;
  }
};

export { setRole, getRole, initializeRole };