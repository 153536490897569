import React, { useState, useEffect } from "react";
import { Container } from "reactstrap";
import styled from "styled-components";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSearch, faArrowRight } from "@fortawesome/free-solid-svg-icons";
import { useLanguage } from "../../LanguageContext"; // Import the useLanguage hook
import translations from "../../../translations.json"; // Import the translations

const SearchBarContainer = styled.div`
  background-color: white;
  border-radius: 25px;
  display: flex;
  align-items: center;
  padding: 10px 15px;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);

  input {
    border: none;
    outline: none;
    flex-grow: 1;
    font-size: 16px;
    padding-left: 10px;

    &::placeholder {
      color: #999;
    }
  }

  button {
    background-color: #333;
    border: none;
    border-radius: 50%;
    width: 30px;
    height: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;

    svg {
      color: white;
      font-size: 14px;
    }
  }
`;

const BackgroundContainer = styled.div`
  transition: background-image 1s ease-in-out; /* Smooth transition */
`;

function IndexHeader() {
  const [searchTerm, setSearchTerm] = useState("");
  const handleSearchChange = (e) => {
    setSearchTerm(e.target.value);
  };

  const [backgroundImage, setBackgroundImage] = useState(
    require("../../../assets/img/qatar/231202112633-lionel-messi-world-cup-2022-file.jpg")
  );
  const [imageIndex, setImageIndex] = useState(0);
  const images = [
    require("../../../assets/img/qatarv2/1614237161445.jpg"),
    require("../../../assets/img/qatarv2/6rcowI7HQQgE4UJCJzWA.jpg"), // Add your image paths here
    require("../../../assets/img/qatarv2/Doha1_1000x600.jpg"), // Add your image paths here
    require("../../../assets/img/qatarv2/HeroBestDohaHotels-RafflesDoha-ExteriorDay-CreditRafflesDoha.jpg"),
    require("../../../assets/img/qatarv2/Qatar.jpg"), // Add your image paths here
    require("../../../assets/img/qatarv2/doha-facebook.jpg"), // Add your image paths here
    require("../../../assets/img/qatarv2/firestation-exhibition-aljazeera-.2e16d0ba.fill-1480x925.jpg"),
    require("../../../assets/img/qatarv2/h1-doha-corniche.jpg"),
    require("../../../assets/img/qatarv2/medium-1280px.jpeg"), // Add your image paths here
    require("../../../assets/img/qatarv2/np_file_200314.jpeg"),
    require("../../../assets/img/qatarv2/things-to-do-in-Doha-view-14.53.18.jpg")
  ];

  const { language } = useLanguage();
  const currentTranslations = translations[language]; // Get translations for the current language

  useEffect(() => {
    const interval = setInterval(() => {
      setImageIndex((prevIndex) => (prevIndex + 1) % images.length);
    }, 5000); // Change the transition interval here (in milliseconds)

    return () => clearInterval(interval);
  }, []);

  useEffect(() => {
    setBackgroundImage(images[imageIndex]);
  }, [imageIndex]);

  return (
    <>
      <BackgroundContainer
        className="page-header section-dark"
        style={{ backgroundImage: `url(${backgroundImage})` }}
      >
        <div className="filter" />
        <div className="content-center">
          <Container>
            <div className="title-brand">
              <h2
                className="presentation-title"
                style={{ fontSize: "5rem", direction: language === 'arabic' ? 'rtl' : 'ltr' }}
              >
                {currentTranslations.DiscoverQatarOpenDataPortal}
              </h2>
            </div>

            <h2
              className="presentation-subtitle text-center"
              style={{ paddingBottom: "5rem", direction: language === 'arabic' ? 'rtl' : 'ltr' }}
            >
              {currentTranslations.headerDescription}
            </h2>
            <div className="search-bar" style={{ zIndex: 1, position: "relative" }}>
              <SearchBarContainer>
                <FontAwesomeIcon icon={faSearch} />
                <input
                  type="text"
                  placeholder={currentTranslations.searchPlaceholder} // Use the translated placeholder
                  value={searchTerm}
                  onChange={handleSearchChange}
                  style={{ direction: language === 'arabic' ? 'rtl' : 'ltr' }}
                />
                <button>
                  <FontAwesomeIcon icon={faArrowRight} />
                </button>
              </SearchBarContainer>
            </div>
          </Container>
        </div>
      </BackgroundContainer>
    </>
  );
}

export default IndexHeader;