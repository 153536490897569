import React, { useState, useEffect, useMemo } from 'react';
import axios from 'axios';
import ReactEcharts from 'echarts-for-react';
import { useLanguage } from '../../../../../LanguageContext'; // Assuming you have a LanguageContext

const BarChart = () => {
  const { language } = useLanguage();
  const [selectedYear, setSelectedYear] = useState('2020');
  const [key, setKey] = useState(0);
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);

  const municipalityTranslations = useMemo(() => ({
    "Al Daayen": { english: "Al Daayen", french: "Al Daayen", arabic: "الداين" },
    "Al Khor and Al Dakhira": { english: "Al Khor and Al Dakhira", french: "Al Khor et Al Dakhira", arabic: "الخور والذخيرة" },
    "Al Rayyan": { english: "Al Rayyan", french: "Al Rayyan", arabic: "الريان" },
    "Al Shamal": { english: "Al Shamal", french: "Al Shamal", arabic: "الشمال" },
    "Al Sheehaniya": { english: "Al Sheehaniya", french: "Al Sheehaniya", arabic: "الشحانية" },
    "Al Wakrah": { english: "Al Wakrah", french: "Al Wakrah", arabic: "الوكرة" },
    "Doha": { english: "Doha", french: "Doha", arabic: "الدوحة" },
    "Umm Slal": { english: "Umm Slal", french: "Umm Slal", arabic: "أم صلال" }
  }), []);

  const translateMunicipality = (municipality) => municipalityTranslations[municipality][language];

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      try {
        const response = await axios.post(`${process.env.REACT_APP_API}/api/realstate_chart`, { year: selectedYear });
        setData(response.data);
      } catch (error) {
        console.error('Error fetching data:', error);
      }
      setLoading(false);
    };

    fetchData();
  }, [selectedYear]);

  const option = {
    tooltip: {
      trigger: 'axis',
      axisPointer: {
        type: 'shadow'
      }
    },
    grid: {
      left: '3%',
      right: '4%',
      bottom: '3%',
      containLabel: true
    },
    xAxis: [
      {
        type: 'category',
        data: Object.keys(municipalityTranslations).map(translateMunicipality),
        axisTick: {
          alignWithLabel: true
        },
        axisLabel: {
          textStyle: {
            color: 'white'
          },
          interval: 0
        }
      }
    ],
    yAxis: [
      {
        type: 'value',
        axisLabel: {
          textStyle: {
            color: 'white'
          }
        }
      }
    ],
    series: [
      {
        name: language === 'english' ? 'Price per Sq Foot' : language === 'french' ? 'Prix par pied carré' : 'السعر لكل قدم مربعة',
        type: 'bar',
        barWidth: '60%',
        data: data[0]?.map((value, index) => ({
          name: translateMunicipality(Object.keys(municipalityTranslations)[index]),
          value: parseFloat(value),
        })),
        itemStyle: {
          color: '#7EB1B6'
        }
      }
    ]
  };

  const handleChange = (e) => {
    setSelectedYear(e.target.value);
    setKey(prevKey => prevKey + 1);
  };

  return (
    <div key={key} dir={language === 'arabic' ? 'rtl' : 'ltr'}>
      <label htmlFor="year" style={{ fontSize: "1rem", fontWeight: "600", color: "white" }}>
        {language === 'english' ? 'Select Year:' :
          language === 'french' ? 'Sélectionnez une année:' :
            'اختر عامًا:'}
      </label>

      <select className='select' id="year" onChange={handleChange} value={selectedYear}>
        {["2018", "2019", "2020", "2021", "2022", "2023", "2024", "2025", "2026", "2027", "2028", "2029", "2030", "2031", "2032"].map(year => (
          <option key={year} value={year}>{year}</option>
        ))}
      </select>
      <ReactEcharts option={option} style={{ width: "100%", height: "400px" }} />
    </div>
  );
};

export default BarChart;
