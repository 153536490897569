import React, { useState } from 'react';
import { Container, Button, Form, FormGroup, Label, Input, Alert } from 'reactstrap';
import axios from 'axios';
import ReactJson from 'react-json-view';
import './Apis.css';
import DemoFooter from '../Footer/Footer';
import Navbar from '../AboutUs/Navbar';
import { Helmet } from 'react-helmet';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'; // Import FontAwesome icons
import { faTrashCan, faCirclePlus } from '@fortawesome/free-solid-svg-icons'; // Import specific icons
import { useParams } from 'react-router-dom'; // Import useParams
import { useLanguage } from '../../LanguageContext'; // Import LanguageContext

const ApiConsole = () => {
  const [results, setResults] = useState(null);
  const [error, setError] = useState(null);
  const [apiUrl, setApiUrl] = useState('');
  const { dataset } = useParams(); 
  const [formData, setFormData] = useState({
    dataset: dataset,
    select: '',
    where: '',
    group_by: '',
    order_by: '',
    limit: '20',
    offset: '',
    lang: '',
    timezone: 'Africa/Lagos'
  });

  const [refinements, setRefinements] = useState([{ facet: '', value: '' }]);
  const [exclusions, setExclusions] = useState([{ facet: '', value: '' }]);

  const { language } = useLanguage(); // Get current language

  const handleChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value
    });
  };

  const handleRefinementChange = (index, e) => {
    const newRefinements = refinements.slice();
    newRefinements[index][e.target.name] = e.target.value;
    setRefinements(newRefinements);
  };

  const handleExclusionChange = (index, e) => {
    const newExclusions = exclusions.slice();
    newExclusions[index][e.target.name] = e.target.value;
    setExclusions(newExclusions);
  };

  const addRefinement = () => {
    setRefinements([...refinements, { facet: '', value: '' }]);
  };

  const addExclusion = () => {
    setExclusions([...exclusions, { facet: '', value: '' }]);
  };

  const removeRefinement = (index) => {
    const newRefinements = refinements.slice();
    newRefinements.splice(index, 1);
    setRefinements(newRefinements);
  };

  const removeExclusion = (index) => {
    const newExclusions = exclusions.slice();
    newExclusions.splice(index, 1);
    setExclusions(newExclusions);
  };

  const handleFetchData = async () => {
    setError(null);

    const refineParams = refinements.map(ref => `${ref.facet}:${ref.value}`).filter(ref => ref !== ':');
    const excludeParams = exclusions.map(exc => `${exc.facet}:${exc.value}`).filter(exc => exc !== ':');
    
    const params = {
      select: formData.select,
      where: formData.where,
      group_by: formData.group_by,
      order_by: formData.order_by,
      limit: formData.limit,
      offset: formData.offset,
      refine: refineParams.join('&refine='),
      exclude: excludeParams.join('&exclude='),
      lang: formData.lang,
      timezone: formData.timezone
    };

    const queryString = Object.entries(params)
      .filter(([, value]) => value)
      .map(([key, value]) => `${key}=${encodeURIComponent(value)}`)
      .join('&');

    const apiEndpoint = `https://www.data.gov.qa/api/explore/v2.1/catalog/datasets/${formData.dataset}/records?${queryString}`;
    setApiUrl(apiEndpoint);

    try {
      const response = await axios.get(apiEndpoint);
      setResults(response.data);
    } catch (error) {
      setError(error.message);
    }
  };

  const getTranslatedText = (text) => {
    switch (language) {
      case 'french':
        switch (text) {
          case 'API Console': return 'Console API';
          case 'Dataset': return 'Ensemble de données';
          case 'Fields to select (documentation)': return 'Champs à sélectionner (documentation)';
          case 'Filtering condition (documentation)': return 'Condition de filtrage (documentation)';
          case 'Grouping parameter (documentation)': return 'Paramètre de regroupement (documentation)';
          case 'Ordering parameter (documentation)': return 'Paramètre de tri (documentation)';
          case 'Limit number of results': return 'Limite le nombre de résultats';
          case 'Offset for results': return 'Décalage des résultats';
          case 'Refinements': return 'Précisions';
          case 'Facet name': return 'Nom de la facette';
          case 'Value': return 'Valeur';
          case 'Exclusions': return 'Exclusions';
          case '2-letters language code for linguistic text features': return 'Code de langue à 2 lettres pour les fonctionnalités de texte linguistique';
          case 'The timezone used to interpret dates and times in queries and records.': return 'Le fuseau horaire utilisé pour interpréter les dates et les heures dans les requêtes et les enregistrements.';
          case 'Submit': return 'Soumettre';
          case 'URL of the API call': return 'URL de l’appel API';
          case 'Results': return 'Résultats';
          case 'Select Dataset': return 'Sélectionnez l’ensemble de données';
          case 'Close': return 'Fermer';
          case 'This dataset can be consumed via an API that allows searching and downloading records using various parameters. Take a look at the ': 
            return 'Cet ensemble de données peut être consommé via une API qui permet de rechercher et de télécharger des enregistrements à l’aide de divers paramètres. Consultez la ';
          case 'The console below is using the ': 
            return 'La console ci-dessous utilise le ';
          case 'API documentation':
            return 'Documentation de l’API';
          case 'Query dataset records endpoint from Explore API 2.1':
            return 'Point de terminaison des enregistrements de l’ensemble de données de requête d’Explore API 2.1';
          default: return text;
        }
      case 'arabic':
        switch (text) {
          case 'API Console': return 'لوحة تحكم واجهة برمجة التطبيقات';
          case 'Dataset': return 'مجموعة البيانات';
          case 'Fields to select (documentation)': return 'الحقول التي سيتم اختيارها (الوثائق)';
          case 'Filtering condition (documentation)': return 'شرط التصفية (الوثائق)';
          case 'Grouping parameter (documentation)': return 'معامل التجميع (الوثائق)';
          case 'Ordering parameter (documentation)': return 'معامل الترتيب (الوثائق)';
          case 'Limit number of results': return 'حدد عدد النتائج';
          case 'Offset for results': return 'ازاحة النتائج';
          case 'Refinements': return 'التحسينات';
          case 'Facet name': return 'اسم العامل';
          case 'Value': return 'القيمة';
          case 'Exclusions': return 'الاستثناءات';
          case '2-letters language code for linguistic text features': return 'رمز لغة مكون من حرفين للميزات النصية اللغوية';
          case 'The timezone used to interpret dates and times in queries and records.': return 'المنطقة الزمنية المستخدمة لتفسير التواريخ والأوقات في الاستعلامات والسجلات.';
          case 'Submit': return 'إرسال';
          case 'URL of the API call': return 'رابط واجهة برمجة التطبيقات';
          case 'Results': return 'النتائج';
          case 'Select Dataset': return 'اختر مجموعة البيانات';
          case 'Close': return 'أغلق';
          case 'This dataset can be consumed via an API that allows searching and downloading records using various parameters. Take a look at the ': 
            return ' يمكن الوصول إلى هذه مجموعة البيانات من خلال واجهة برمجة التطبيقات التي تسمح بالبحث وتنزيل السجلات باستخدام معايير متنوعة.  اطلع على ';
          case 'The console below is using the ': 
            return 'تستخدم لوحة التحكم أدناه نقطة نهاية استعلام سجلات مجموعة البيانات من ';
          case 'API documentation':
            return 'وثائق واجهة برمجة التطبيقات';
          case 'Query dataset records endpoint from Explore API 2.1':
            return 'نقطة نهاية استعلام سجلات مجموعة البيانات من Explore API 2.1';
          default: return text;
        }
      default: 
        return text;
    }
  };

  return (
    <>
    <Helmet>
    <link rel="stylesheet" href="https://cdnjs.cloudflare.com/ajax/libs/font-awesome/6.2.1/css/all.min.css" integrity="sha512-MV7K8+y+gLIBoVD59lQIYicR65iaqukzvf/nwasF0nqhPay5w/9lJmVM2hMDcnK1OnMGCdVK+iQrJ7lzPJQd1w==" crossorigin="anonymous" referrerpolicy="no-referrer" />

    <title>API — State of Qatar Open Data</title>
    </Helmet>  
            <Navbar />
            <div style={{paddingTop:"100px", direction: language === "arabic" ? "rtl" : "ltr" }}>
    <Container className="container container1">
      <div className="form-section1">
        <h2 style={{ textAlign: language === 'arabic' ? 'right' : 'left' }}>{getTranslatedText('API Console')}</h2>
        <p style={{ textAlign: language === 'arabic' ? 'right' : 'left' }}>
  {getTranslatedText('This dataset can be consumed via an API that allows searching and downloading records using various parameters. Take a look at the ')}<a href="/api/explore/v2.1/documentation" target="_blank" class="ng-scope">
  <i class="icon-book"></i> {getTranslatedText('API documentation')}
</a>.
</p >
<p style={{ textAlign: language === 'arabic' ? 'right' : 'left' }}>
  {getTranslatedText('The console below is using the ')}<a href="https://help.opendatasoft.com/apis/ods-explore-v2/#tag/Dataset/operation/getRecords" target="_blank" class="ng-scope">
  {getTranslatedText('Query dataset records endpoint from Explore API 2.1')}
</a>.
</p>

        <Form style={{paddingTop:"20px"}}  onSubmit={(event) => { 
          event.preventDefault(); // Prevent default submission
          handleFetchData(); 
        }}>
          <div dir={language === 'arabic' ? 'rtl' : 'ltr'}>
            <FormGroup>
              <Label style={{ float: language === 'arabic' ? 'right' :'left' }}for="dataset">{getTranslatedText('Dataset')}</Label>
              <Input type="text" name="dataset" id="dataset" value={formData.dataset} onChange={handleChange} disabled />
            </FormGroup>
          </div>
          <FormGroup>
            <Label style={{ float: language === 'arabic' ? 'right' :'left' }}for="select">{getTranslatedText('Fields to select (documentation)')}</Label>
            <Input type="text" name="select" id="select" value={formData.select} onChange={handleChange} />
          </FormGroup>
          <FormGroup>
            <Label style={{ float: language === 'arabic' ? 'right' :'left' }}for="where">{getTranslatedText('Filtering condition (documentation)')}</Label>
            <Input type="text" name="where" id="where" value={formData.where} onChange={handleChange} />
          </FormGroup>
          <FormGroup>
            <Label style={{ float: language === 'arabic' ? 'right' :'left' }}for="group_by">{getTranslatedText('Grouping parameter (documentation)')}</Label>
            <Input type="text" name="group_by" id="group_by" value={formData.group_by} onChange={handleChange} />
          </FormGroup>
          <FormGroup>
            <Label style={{ float: language === 'arabic' ? 'right' :'left' }}for="order_by">{getTranslatedText('Ordering parameter (documentation)')}</Label>
            <Input type="text" name="order_by" id="order_by" value={formData.order_by} onChange={handleChange} />
          </FormGroup>
          <FormGroup>
            <Label style={{ float: language === 'arabic' ? 'right' :'left' }}for="limit">{getTranslatedText('Limit number of results')}</Label>
            <Input type="number" name="limit" id="limit" value={formData.limit} onChange={handleChange} />
          </FormGroup>
          <FormGroup>
            <Label style={{ float: language === 'arabic' ? 'right' :'left' }}for="offset">{getTranslatedText('Offset for results')}</Label>
            <Input type="number" name="offset" id="offset" value={formData.offset} onChange={handleChange} />
          </FormGroup>

          <h5 style={{ textAlign: language === 'arabic' ? 'right' : 'left' }}>{getTranslatedText('Refinements')}</h5>
          {refinements.map((refinement, index) => (
            <FormGroup key={index} className="d-flex align-items-center">
              <div style={{ flex: 1 }}>
                <Label style={{ float: language === 'arabic' ? 'right' :'left' }}for={`refineFacet${index}`}>{getTranslatedText('Facet name')}</Label>
                <Input type="text" name="facet" id={`refineFacet${index}`} value={refinement.facet} onChange={(e) => handleRefinementChange(index, e)} />
              </div>
              <div style={{ flex: 1 }}>
                <Label style={{ float: language === 'arabic' ? 'right' :'left' }}for={`refineValue${index}`}>{getTranslatedText('Value')}</Label>
                <Input type="text" name="value" id={`refineValue${index}`} value={refinement.value} onChange={(e) => handleRefinementChange(index, e)} />
              </div>
              <button className="btnnn" color="danger" onClick={() => removeRefinement(index)} style={{ marginTop: '30px', marginLeft: '10px' }}><FontAwesomeIcon icon={faTrashCan} /></button >
            </FormGroup>
          ))}
          <button className="btnnn" onClick={addRefinement} style={{ marginBottom: '20px' }}><FontAwesomeIcon icon={faCirclePlus} /></button >

          <h5 style={{ textAlign: language === 'arabic' ? 'right' : 'left' }}>{getTranslatedText('Exclusions')}</h5>
          {exclusions.map((exclusion, index) => (
            <FormGroup key={index} className="d-flex align-items-center">
              <div style={{ flex: 1 }}>
                <Label style={{ float: language === 'arabic' ? 'right' :'left' }}for={`excludeFacet${index}`}>{getTranslatedText('Facet name')}</Label>
                <Input type="text" name="facet" id={`excludeFacet${index}`} value={exclusion.facet} onChange={(e) => handleExclusionChange(index, e)} />
              </div>
              <div style={{ flex: 1 }}>
                <Label style={{ float: language === 'arabic' ? 'right' :'left' }}for={`excludeValue${index}`}>{getTranslatedText('Value')}</Label>
                <Input type="text" name="value" id={`excludeValue${index}`} value={exclusion.value} onChange={(e) => handleExclusionChange(index, e)} />
              </div>
              <button className="btnnn" color="danger" onClick={() => removeExclusion(index)} style={{ marginTop: '30px', marginLeft: '10px' }}><FontAwesomeIcon icon={faTrashCan} /></button >
            </FormGroup>
          ))}
          <button className="btnnn" onClick={addExclusion} style={{ marginBottom: '20px' }}><FontAwesomeIcon icon={faCirclePlus} /></button >

          <FormGroup>
            <Label style={{ float: language === 'arabic' ? 'right' :'left' }}for="lang">{getTranslatedText('2-letters language code for linguistic text features')}</Label>
            <Input type="text" name="lang" id="lang" value={formData.lang} onChange={handleChange} />
          </FormGroup>
          <FormGroup>
            <Label style={{ float: language === 'arabic' ? 'right' :'left' }}for="timezone">{getTranslatedText('The timezone used to interpret dates and times in queries and records.')}</Label>
            <Input type="text" name="timezone" id="timezone" value={formData.timezone} onChange={handleChange} />
          </FormGroup>
          <button className='btnnn' >{getTranslatedText('Submit')}</button>
          {apiUrl && (
            <div className="api-url-section" style={{paddingTop:"10px"}}>
              <h5>{getTranslatedText('URL of the API call')}</h5>
              <a href={apiUrl}>
                <code style={{fontSize:"0.875rem",color:"#76102B"}}>{apiUrl}</code>
              </a>        
            </div>
          )}
        </Form>
        
        {error && <Alert color="danger">{error}</Alert>}
      </div>

      <div className="result-section1">
        <h3 style={{ textAlign: language === 'arabic' ? 'right' : 'left' }}>{getTranslatedText('Results')}</h3>
        {results && (
          <div>
            <ReactJson src={results} theme="monokai" className="react-json-view" />
          </div>
        )}
      </div>

    </Container>
    </div>
    <div style={{paddingTop:"100px"}}>
      <DemoFooter />
    </div>
    </>
  );
};

export default ApiConsole;