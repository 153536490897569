import axios from 'axios';
import * as echarts from 'echarts';
import React, { useEffect, useRef, useState } from 'react';
import { useLanguage } from '../../../../../LanguageContext'; // Assuming you have LanguageContext set up

const VesselMovementsChart = () => {
  const { language } = useLanguage(); // Get the current language from context
  const [option, setOption] = useState(null);
  const [selectedYear, setSelectedYear] = useState("2021");
  const chartRef = useRef(null);
  const [key, setKey] = useState(0);

  const monthTranslations = {
    January: { english: 'January', french: 'Janvier', arabic: 'يناير' },
    February: { english: 'February', french: 'Février', arabic: 'فبراير' },
    March: { english: 'March', french: 'Mars', arabic: 'مارس' },
    April: { english: 'April', french: 'Avril', arabic: 'أبريل' },
    May: { english: 'May', french: 'Mai', arabic: 'مايو' },
    June: { english: 'June', french: 'Juin', arabic: 'يونيو' },
    July: { english: 'July', french: 'Juillet', arabic: 'يوليو' },
    August: { english: 'August', french: 'Août', arabic: 'أغسطس' },
    September: { english: 'September', french: 'Septembre', arabic: 'سبتمبر' },
    October: { english: 'October', french: 'Octobre', arabic: 'أكتوبر' },
    November: { english: 'November', french: 'Novembre', arabic: 'نوفمبر' },
    December: { english: 'December', french: 'Décembre', arabic: 'ديسمبر' },
  };

  const categoryTranslations = {
    'total_deposits': {
      english: 'Total Deposits',
      french: 'Dépôts totaux',
      arabic: 'إجمالي الودائع'
    },
    'public_sector_deposits': {
      english: 'Public Sector Deposits',
      french: 'Dépôts du secteur public',
      arabic: 'ودائع القطاع العام'
    },
    'private_sector_deposits': {
      english: 'Private Sector Deposits',
      french: 'Dépôts du secteur privé',
      arabic: 'ودائع القطاع الخاص'
    },
    'non_resident_deposits': {
      english: 'Non-Resident Deposits',
      french: 'Dépôts non-résidents',
      arabic: 'ودائع غير مقيمين'
    },
  };

  const translate = (text, translations) => translations[text][language];

  const fetchData = async () => {
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API}/api/monthly_qatar_commercial_banks_deposits`,
        { year: selectedYear }
      );
      const rawData = response.data;
      processData(rawData);
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };

  const processData = (rawData) => {
    const categories = Object.keys(categoryTranslations); // Get categories from translations

    const seriesList = categories.map(category => {
      const data = rawData.filter(item => item.Category === category)
        .map(item => ({ name: translate(item.Month, monthTranslations), value: parseFloat(item.Value) }));
      return {
        type: 'line',
        name: translate(category, categoryTranslations),
        data: data,
      };
    });

    const newOption = {
      tooltip: {
        trigger: 'axis',
        axisPointer: {
          type: 'cross'
        },

      },
      legend: {
        data: categories.map(category => translate(category, categoryTranslations)),
        textStyle: {
          color: "white"
        }
      },
      xAxis: {
        type: 'category',
        boundaryGap: false,
        data: rawData.filter((v, i, a) => a.findIndex(t => (t.Month === v.Month)) === i).map(item => translate(item.Month, monthTranslations)),
        axisLabel: {
          textStyle: {
            color: "white"
          }
        }
      },
      yAxis: {
        type: 'value',
        axisLabel: {
          textStyle: {
            color: "white"
          }
        }
      },
      series: seriesList
    };

    setOption(newOption);
  };

  useEffect(() => {
    fetchData();
  }, [selectedYear, language]); // Fetch data on year or language change

  useEffect(() => {
    if (option && chartRef.current) {
      const myChart = echarts.init(chartRef.current);
      myChart.setOption(option);
    }
  }, [option, language]); // Update chart on option or language change

  return (
    <div key={key} dir={language === 'arabic' ? 'rtl' : 'ltr'}>
      <div>
        <label htmlFor="year" style={{ fontSize: "1rem", fontWeight: "600", color: "white" }}>
          {language === 'english' ? 'Select a year:' : language === 'french' ? 'Sélectionnez une année:' : 'اختر عامًا:'}
        </label>
        <select className='select' id="year" onChange={(e) => { setSelectedYear(e.target.value); setKey(prevKey => prevKey + 1); }} value={selectedYear}>
          {["2018", "2019", "2020", "2021", "2022", "2023", "2024", "2025", "2026", "2027", "2028", "2029", "2030", "2031", "2032"].map(year => (
            <option key={year} value={year}>{year}</option>
          ))}
        </select>
      </div>
      <div ref={chartRef} style={{ width: "100%", height: "450px" }} />
    </div>
  );
};

export default VesselMovementsChart;