import React, { useState, useEffect } from "react";
import classnames from "classnames";
import axios from "axios";
import {
  Button,
  Collapse,
  NavbarBrand,
  Navbar,
  NavItem,
  NavLink,
  Nav,
  Container,
  UncontrolledPopover,
  PopoverBody,
} from "reactstrap";
import "./Navbar.css"; // Import the new CSS file
import { setRole, getRole } from "../../AuthContext";
import { useNavigate } from "react-router-dom";
import LanguageSwitcher from "Components/LangSwitch";
import { useLanguage } from "../../LanguageContext"; // Import the useLanguage hook
import translations from '../../../translations.json'; // Import the translations

function IndexNavbar() {
  const [navbarColor, setNavbarColor] = useState("navbar-transparent");
  const [navbarCollapse, setNavbarCollapse] = useState(false);
  const [notifications, setNotifications] = useState([]);
  const navigate = useNavigate();
  const role = getRole();
  const [token, setToken] = useState(localStorage.getItem("token"));
  const { language } = useLanguage();
  const currentTranslations = translations[language]; // Get translations for the current language

  const toggleNavbarCollapse = () => {
    setNavbarCollapse(!navbarCollapse);
    document.documentElement.classList.toggle("nav-open");
  };

  useEffect(() => {
    const updateNavbarColor = () => {
      if (
        document.documentElement.scrollTop > 299 ||
        document.body.scrollTop > 299
      ) {
        setNavbarColor("E4E4E5");
      } else if (
        document.documentElement.scrollTop < 300 ||
        document.body.scrollTop < 300
      ) {
        setNavbarColor("navbar-transparent");
      }
    };

    window.addEventListener("scroll", updateNavbarColor);

    return function cleanup() {
      window.removeEventListener("scroll", updateNavbarColor);
    };
  }, []);

  // Fetch notifications from the API using axios
  useEffect(() => {
    async function fetchNotifications() {
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_API}/api/pending_errors`
        );
        setNotifications(response.data || []);
      } catch (error) {
        console.error("Error fetching notifications:", error);
      }
    }

    fetchNotifications();
  }, []);
  const handleLogout = () => {
    setRole(false); // Reset the role
    localStorage.removeItem("token");
    setToken("");
    navigate("/Home"); // Redirect to the login page
  };

  return (
    <Navbar 
      className={classnames("fixed-top", navbarColor)}
      expand="lg"
      style={{ backgroundColor: "#C0C0C0" }}
    >
      <Container fluid>
        <div className="navbar-translate">
          <NavbarBrand
            data-placement="bottom"
            href="/index"
            target="_blank"
            title="Coded by Creative Tim"
          >
            <img
              src={require("../../../assets/img/logo-AR.svg").default}
              alt="Logo"
              height="70"
            />
          </NavbarBrand>
          <button
            aria-expanded={navbarCollapse}
            className={classnames("navbar-toggler navbar-toggler", {
              toggled: navbarCollapse,
            })}
            onClick={toggleNavbarCollapse}
          >
            <span className="navbar-toggler-bar bar1" />
            <span className="navbar-toggler-bar bar2" />
            <span className="navbar-toggler-bar bar3" />
          </button>
        </div>
        <Collapse className="justify-content-center" navbar isOpen={navbarCollapse}>
          <Nav navbar>
            <NavItem>
              <NavLink
                href="/home"
                style={{
                  fontSize: "1rem",
                  direction: language === "arabic" ? "rtl" : "ltr",
                }}
              >
                {language === 'arabic' ? <span style={{ fontSize: "1.2rem" }}>{currentTranslations.Home}</span> : currentTranslations.Home}
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink
                href="/AboutUs"
                style={{
                  fontSize: "1rem",
                  direction: language === "arabic" ? "rtl" : "ltr",
                }}
              >
                {language === 'arabic' ? <span style={{ fontSize: "1.2rem" }}>{currentTranslations.AboutUs}</span> : currentTranslations.AboutUs}
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink
                href="/catalog"
                style={{
                  fontSize: "1rem",
                  direction: language === "arabic" ? "rtl" : "ltr",
                }}
              >
                {language === 'arabic' ? <span style={{ fontSize: "1.2rem" }}>{currentTranslations.Catalog}</span> : currentTranslations.Catalog}
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink
                href="/Archive/Sports"
                style={{
                  fontSize: "1rem",
                  direction: language === "arabic" ? "rtl" : "ltr",
                }}
              >
                {language === 'arabic' ? <span style={{ fontSize: "1.2rem" }}>{currentTranslations.Archive}</span> : currentTranslations.Archive}
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink
                href="/Apis"
                style={{
                  fontSize: "1rem",
                  direction: language === "arabic" ? "rtl" : "ltr",
                }}
              >
                {language === 'arabic' ? <span style={{ fontSize: "1.2rem" }}>{currentTranslations.APIs}</span> : currentTranslations.APIs}
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink
                href="/ContactUs"
                style={{
                  fontSize: "1rem",
                  direction: language === "arabic" ? "rtl" : "ltr",
                }}
              >
                {language === 'arabic' ? <span style={{ fontSize: "1.2rem" }}>{currentTranslations.ContactUs}</span> : currentTranslations.ContactUs}
              </NavLink>
            </NavItem>
            {role && (
              <NavItem>
                <Button
                  className="btn-round"
                  style={{
                    backgroundColor: "#76102B",
                    border: "#76102B",
                    direction: language === "arabic" ? "rtl" : "ltr",
                  }}
                  onClick={() => window.location.href = "/Admin"}
                >
                  <i className="fas fa-cog"></i>
                   {currentTranslations.Settings}
                </Button>
              </NavItem>
            )}
            {!token && (
              <NavItem>
                <Button
                  className="btn-round"
                  style={{
                    backgroundColor: "#76102B",
                    border: "#76102B",
                    direction: language === "arabic" ? "rtl" : "ltr",
                  }}
                  onClick={() => window.location.href = "/login"}
                >
                  <i className="fa fa-sign-in"></i>
                   {currentTranslations.Login}
                </Button>
              </NavItem>
            )}
            {!token && (
              <NavItem>
                <Button
                  className="btn-round"
                  style={{
                    backgroundColor: "#76102B",
                    border: "#76102B",
                    direction: language === "arabic" ? "rtl" : "ltr",
                  }}
                  onClick={() => window.location.href = "/signup"}
                >
                  <i className="fa fa-user-plus"></i>
                   {currentTranslations.SignUp}
                </Button>
              </NavItem>
            )}
            {token && (
              <NavItem>
                <Button
                  className="btn-round"
                  style={{
                    backgroundColor: "#76102B",
                    border: "#76102B",
                    direction: language === "arabic" ? "rtl" : "ltr",
                  }}
                  onClick={handleLogout} // Use the handleLogout function
                >
                  <i className="fas fa-sign-out-alt"></i>
                  {currentTranslations.Logout}
                </Button>
              </NavItem>
            )}
            <NavItem >
              <LanguageSwitcher  />
            </NavItem>
          </Nav>

          <Nav navbar>
            {token && (
              <NavItem className="navbar-notification">
                <div id="notificationIcon" className="notification-icon">
                  <i className="fa fa-bell"></i>
                  <span className="notification-count">
                    {notifications.length}
                  </span>
                </div>
                <UncontrolledPopover
                  trigger="legacy"
                  placement="bottom"
                  target="notificationIcon"
                >
                  <PopoverBody className="notification-popover-body">
                    {notifications.length > 0 ? (
                      notifications.map((notification) => (
                        <div key={notification.id} className="notification-item">
                          <h6 style={{ color: "#76102B" }}>
                            {notification.name}
                          </h6>
                          <p>{notification.description}</p>
                          <small>
                            {new Date(notification.insert_date).toLocaleString()}
                          </small>
                          <hr />
                        </div>
                      ))
                    ) : (
                      <p
                        style={{
                          direction: language === "arabic" ? "rtl" : "ltr",
                        }}
                      >
                        {currentTranslations["No notifications"]}
                      </p>
                    )}
                  </PopoverBody>
                </UncontrolledPopover>
              </NavItem>
            )}
          </Nav>
        </Collapse>
      </Container>
    </Navbar>
  );
}

export default IndexNavbar;