import React, { useEffect, useState } from 'react';
import { MaterialReactTable, createMRTColumnHelper, useMaterialReactTable } from 'material-react-table';
import { Box, Button } from '@mui/material';
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import { mkConfig, generateCsv, download } from 'export-to-csv';
import { useLanguage } from '../../../../LanguageContext';

const columnHelper = createMRTColumnHelper();

const csvConfig = mkConfig({
  fieldSeparator: ',',
  decimalSeparator: '.',
  useKeysAsHeaders: true,
});

const translations = {
  lsn_year: {
    english: 'Year',
    arabic: 'السنة',
    french: 'Année'
  },
  staff_teaching_administrative_pick_one: {
    english: 'Staff Type (Teaching/Administrative)',
    arabic: 'نوع الموظفين (تعليمية/إدارية)',
    french: 'Type de personnel (Enseignement/Administration)' 
  },
  lwzyq: {
    english: 'Staff Type (Teaching/Administrative)',
    arabic: 'نوع الموظفين (تعليمية/إدارية)',
    french: 'Type de personnel (Enseignement/Administration)' 
  },
  gender: {
    english: 'Gender',
    arabic: 'الجنس',
    french: 'Genre'
  },
  lnw: {
    english: 'Gender',
    arabic: 'الجنس',
    french: 'Genre'
  },
  age: {
    english: 'Age',
    arabic: 'العمر',
    french: 'Âge'
  },
  ljnsy: {
    english: 'Nationality',
    arabic: 'الجنسية',
    french: 'Nationalité'
  },
  nationality: {
    english: 'Nationality',
    arabic: 'الجنسية',
    french: 'Nationalité'
  },
  municipality: {
    english: 'Municipality',
    arabic: 'البلدية',
    french: 'Municipalité'
  },
  lbldy: {
    english: 'Municipality',
    arabic: 'البلدية',
    french: 'Municipalité'
  },
  mjmw_l_mlyn_sub_total_staff: {
    english: 'Total Staff',
    arabic: 'إجمالي الموظفين',
    french: 'Total du personnel'
  },
  exportAllData: {
    english: 'Export All Data',
    arabic: 'تصدير جميع البيانات',
    french: 'Exporter toutes les données'
  },
  exportAllRows: {
    english: 'Export All Rows',
    arabic: 'تصدير جميع الصفوف',
    french: 'Exporter toutes les lignes' 
  },
  exportPageRows: {
    english: 'Export Page Rows',
    arabic: 'تصدير صفوف الصفحة',
    french: 'Exporter les lignes de la page' 
  },
  exportSelectedRows: {
    english: 'Export Selected Rows',
    arabic: 'تصدير الصفوف المحددة',
    french: 'Exporter les lignes sélectionnées' 
  }
};

const Example = () => {
  const { language } = useLanguage();
  const [columns, setColumns] = useState([]);
  const [data, setData] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch(`${process.env.REACT_APP_API}/api/school_staff`); // Replace with your actual API call
        const result = await response.json();

        if (result.columns && result.data) {
          const fetchedColumns = result.columns.map((col) => {
            const header = translations[col.key] ? translations[col.key][language] : col.header;

            // Hide Arabic-specific columns for English or French
            if (language === 'english' || language === 'french') {
              if (
                col.key === 'lwzyq' ||
                col.key === 'lnw' ||
                col.key === 'ljnsy' ||
                col.key === 'lbldy' 
              ) {
                return null; 
              }
              return columnHelper.accessor(col.key, { header });
            } 
            // Hide English-specific columns for Arabic
            else if (language === 'arabic') {
              if (
                col.key === 'Year' || 
                col.key === 'staff_teaching_administrative_pick_one' || 
                col.key === 'gender' || 
                col.key === 'Age' || 
                col.key === 'nationality' ||
                col.key === 'municipality' || 
                col.key === 'Total Staff' 
              ) { 
                return null; 
              }
              return columnHelper.accessor(col.key, { header }); 
            }

            return null; 
          }).filter(Boolean);

          setColumns(fetchedColumns);
          setData(result.data);
        }
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };

    fetchData();
  }, [language]);

  const handleExportRows = (rows) => {
    const rowData = rows.map((row) => row.original);
    const csv = generateCsv(csvConfig)(rowData);
    download(csvConfig)(csv);
  };

  const handleExportData = () => {
    const csv = generateCsv(csvConfig)(data);
    download(csvConfig)(csv);
  };

  const table = useMaterialReactTable({
    columns,
    data,
    enableFullScreenToggle: false,
    enableRowSelection: true,
    columnFilterDisplayMode: 'popover',
    paginationDisplayMode: 'pages',
    positionToolbarAlertBanner: 'bottom',
    renderTopToolbarCustomActions: ({ table }) => (
      <Box
        sx={{
          display: 'flex',
          gap: '16px',
          padding: '8px',
          flexWrap: 'wrap',
        }}
      >
        <Button onClick={handleExportData} startIcon={<FileDownloadIcon />}>
          {translations.exportAllData[language]}
        </Button>
        <Button
          disabled={table.getPrePaginationRowModel().rows.length === 0}
          onClick={() => handleExportRows(table.getPrePaginationRowModel().rows)}
          startIcon={<FileDownloadIcon />}
        >
          {translations.exportAllRows[language]}
        </Button>
        <Button
          disabled={table.getRowModel().rows.length === 0}
          onClick={() => handleExportRows(table.getRowModel().rows)}
          startIcon={<FileDownloadIcon />}
        >
          {translations.exportPageRows[language]}
        </Button>
        <Button
          disabled={!table.getIsSomeRowsSelected() && !table.getIsAllRowsSelected()}
          onClick={() => handleExportRows(table.getSelectedRowModel().rows)}
          startIcon={<FileDownloadIcon />}
        >
          {translations.exportSelectedRows[language]}
        </Button>
      </Box>
    ),
  });

  return (
    <Box>
      <MaterialReactTable table={table} />
    </Box>
  );
};

export default Example;