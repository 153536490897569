import React from 'react';

const Pagination = ({ currentPage, totalPages, onPageChange }) => {
    const pageNumbers = Array.from({ length: totalPages }, (_, i) => i + 1);

    return (
        <nav style={{ display: 'flex', justifyContent: 'center' }}>
            <ul className="pagination" style={{ borderRadius: '40px', boxShadow: '0 0 10px rgba(0, 0, 0, .1)', display: 'flex', listStyleType: 'none', padding: 0 }}>
                <li className={`page-item ${currentPage === 1 ? 'disabled' : ''}`}>
                    <button
                        className="page-link"
                        onClick={() => onPageChange(currentPage - 1)}
                        disabled={currentPage === 1}
                        style={{
                            background: 'transparent',
                            cursor: currentPage === 1 ? 'not-allowed' : 'pointer',
                            color: '#76102B',
                            border: "2px solid #76102B",
                        }}
                    >
                        {'<'}
                    </button>
                </li>
                {pageNumbers.map(page => (
                    <li key={page} className={`page-item ${currentPage === page ? 'active' : ''}`}>
                        <button
                            className="page-link"
                            onClick={() => onPageChange(page)}
                            style={{
                                border: "2px solid #76102B",
                                background: 'transparent',
                                cursor: 'pointer',
                                color: '#76102B'
                            }}
                        >
                            {page}
                        </button>
                    </li>
                ))}
                <li className={`page-item ${currentPage === totalPages ? 'disabled' : ''}`}>
                    <button
                        className="page-link"
                        onClick={() => onPageChange(currentPage + 1)}
                        disabled={currentPage === totalPages}
                        style={{
                            background: 'transparent',
                            backdropFilter: 'blur(30px)',
                            cursor: currentPage === totalPages ? 'not-allowed' : 'pointer',
                            color: '#76102B',
                            border: "2px solid #76102B",
                        }}
                    >
                        {'>'}
                    </button>
                </li>
            </ul>
        </nav>
    );
};

export default Pagination;
