import React from 'react';
import { useLanguage } from '../../../../../../LanguageContext';
import BarChart from './qatar_monthly_statistics_divorcesChart'; 
import '../PieChart.css';

const PieComponent = ({ onClose }) => {
  const { language } = useLanguage();

  return (
    <div id="select-modal1" dir={language === 'arabic' ? 'rtl' : 'ltr'}>
      <div className="modal-container1">
        <div className="modal-header1">
          <h3 className="modal-title1">
            {language === 'english'
              ? 'Qatar Monthly Statistics Divorces'
              : language === 'french'
                ? 'Statistiques mensuelles des divorces au Qatar'
                : 'إحصائيات قطر الشهرية للطلاق'}
          </h3>
          <button className="close-button1" onClick={onClose}>
            {language === 'english'
              ? 'Close'
              : language === 'french'
                ? 'Fermer'
                : 'أغلق'}
          </button>
        </div>

        <div className="modal-body1">
          <BarChart />
        </div>
      </div>
    </div>
  );
};

export default PieComponent;