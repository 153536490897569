


import React,{useState} from "react";
import { Container, Row, Col } from 'reactstrap';
import IndexNavbar from "./NavBar/Navbar";
import IndexHeader from "./Header/Header";
import DemoFooter from "./Footer/Footer";
import CardSlider from "./MonthlyStatistics/CardSlider";
import Chatbot from './ChatWindow/ChatbotComponent';
import "slick-carousel/slick/slick.css"; 
import "slick-carousel/slick/slick-theme.css";
import LandscapeCards from "./Archive/LandscapeCards";
import { Helmet } from 'react-helmet';

function Index() {


  return (
    <>            <Helmet>
    <link rel="stylesheet" href="https://fonts.googleapis.com/icon?family=Material+Icons" />
    <title>Home Page — State of Qatar Open Data</title>

</Helmet>
        <link rel="stylesheet" href="https://cdnjs.cloudflare.com/ajax/libs/font-awesome/6.2.1/css/all.min.css" integrity="sha512-MV7K8+y+gLIBoVD59lQIYicR65iaqukzvf/nwasF0nqhPay5w/9lJmVM2hMDcnK1OnMGCdVK+iQrJ7lzPJQd1w==" crossorigin="anonymous" referrerpolicy="no-referrer" />

      <IndexNavbar />
      <IndexHeader />
      <CardSlider />
      <div className="App">
      <LandscapeCards />
    </div>
    <div style={{paddingTop:"100px"}}>
    <DemoFooter />
    </div>
    <Chatbot />

    </>
  );
}

export default Index;
