// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.language-switcher .dropdown-toggle {
    background-color: white !important;
    padding: 5px 10px !important;
    display: flex;
    align-items: center;
    color: black;
    border-radius: 30px;
  }
  
  .language-switcher .dropdown-menu {
    min-width: 25px;
    color: black;
  }
  
  .language-switcher .dropdown-item {
    display: flex;
    align-items: center;
    color: black;

  }
  `, "",{"version":3,"sources":["webpack://./src/Components/LanguageSwitcher.css"],"names":[],"mappings":"AAAA;IACI,kCAAkC;IAClC,4BAA4B;IAC5B,aAAa;IACb,mBAAmB;IACnB,YAAY;IACZ,mBAAmB;EACrB;;EAEA;IACE,eAAe;IACf,YAAY;EACd;;EAEA;IACE,aAAa;IACb,mBAAmB;IACnB,YAAY;;EAEd","sourcesContent":[".language-switcher .dropdown-toggle {\n    background-color: white !important;\n    padding: 5px 10px !important;\n    display: flex;\n    align-items: center;\n    color: black;\n    border-radius: 30px;\n  }\n  \n  .language-switcher .dropdown-menu {\n    min-width: 25px;\n    color: black;\n  }\n  \n  .language-switcher .dropdown-item {\n    display: flex;\n    align-items: center;\n    color: black;\n\n  }\n  "],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
