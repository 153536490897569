// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.contact-us-container {
    margin-top: 50px;
    padding: 30px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    background-color: #EAEAEA;
    border-radius: 8px;
  }
  
  .contact-us-row {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    text-align: center;
  }
  
  .contact-us-title {
    font-size: 2.5em;
    color: #fff;
    background-color: #76102B;
    padding: 15px;
    border-radius: 8px;
  }
  
  .contact-us-description {
    font-size: 1.2em;
    color: #666;
    margin-top: 20px;
    line-height: 1.6;
  }
  
  .contact-us-form {
    text-align: left;
    margin-top: 20px;
  }
  
  .contact-us-form .form-group {
    margin-bottom: 15px;
  }

  .btnnn {
    background-color: white;
    border: 2px solid #76102B;
    color: #76102B;
    padding: 10px 20px;
    margin: 5px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 16px;
    border-radius: 12px;
    cursor: pointer;
    transition: background-color 0.3s;
    align-items: center;
    display: flex;
    
  }
  .btnnn:hover{
    background-color: #76102B;
    color: white;
    
  }

  `, "",{"version":3,"sources":["webpack://./src/Components/HomePage/ContactUs/ContactUs.css"],"names":[],"mappings":"AAAA;IACI,gBAAgB;IAChB,aAAa;IACb,wCAAwC;IACxC,yBAAyB;IACzB,kBAAkB;EACpB;;EAEA;IACE,aAAa;IACb,uBAAuB;IACvB,mBAAmB;IACnB,sBAAsB;IACtB,kBAAkB;EACpB;;EAEA;IACE,gBAAgB;IAChB,WAAW;IACX,yBAAyB;IACzB,aAAa;IACb,kBAAkB;EACpB;;EAEA;IACE,gBAAgB;IAChB,WAAW;IACX,gBAAgB;IAChB,gBAAgB;EAClB;;EAEA;IACE,gBAAgB;IAChB,gBAAgB;EAClB;;EAEA;IACE,mBAAmB;EACrB;;EAEA;IACE,uBAAuB;IACvB,yBAAyB;IACzB,cAAc;IACd,kBAAkB;IAClB,WAAW;IACX,kBAAkB;IAClB,qBAAqB;IACrB,qBAAqB;IACrB,eAAe;IACf,mBAAmB;IACnB,eAAe;IACf,iCAAiC;IACjC,mBAAmB;IACnB,aAAa;;EAEf;EACA;IACE,yBAAyB;IACzB,YAAY;;EAEd","sourcesContent":[".contact-us-container {\n    margin-top: 50px;\n    padding: 30px;\n    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);\n    background-color: #EAEAEA;\n    border-radius: 8px;\n  }\n  \n  .contact-us-row {\n    display: flex;\n    justify-content: center;\n    align-items: center;\n    flex-direction: column;\n    text-align: center;\n  }\n  \n  .contact-us-title {\n    font-size: 2.5em;\n    color: #fff;\n    background-color: #76102B;\n    padding: 15px;\n    border-radius: 8px;\n  }\n  \n  .contact-us-description {\n    font-size: 1.2em;\n    color: #666;\n    margin-top: 20px;\n    line-height: 1.6;\n  }\n  \n  .contact-us-form {\n    text-align: left;\n    margin-top: 20px;\n  }\n  \n  .contact-us-form .form-group {\n    margin-bottom: 15px;\n  }\n\n  .btnnn {\n    background-color: white;\n    border: 2px solid #76102B;\n    color: #76102B;\n    padding: 10px 20px;\n    margin: 5px;\n    text-align: center;\n    text-decoration: none;\n    display: inline-block;\n    font-size: 16px;\n    border-radius: 12px;\n    cursor: pointer;\n    transition: background-color 0.3s;\n    align-items: center;\n    display: flex;\n    \n  }\n  .btnnn:hover{\n    background-color: #76102B;\n    color: white;\n    \n  }\n\n  "],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
