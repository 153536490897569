import React from 'react';
import { useLanguage } from '../../../../LanguageContext';
import PieChart from './transport_and_communications_sector_projects_2021_Table';
import '../../../MonthlyStatistics/Tables/PopulationModal/Charts/PieChart.css';

const PieComponent = ({ onClose }) => {
  const { language } = useLanguage();

  return (
    <div id="select-modal1" > {/* Set direction */}
      <div className="modal-container1">
        <div className="modal-header1" dir={language === 'arabic' ? 'rtl' : 'ltr'}>
          <h3 className="modal-title1">
            {language === 'english'
              ? 'Budget for Transport and Communications Sector Projects 2021'
              : language === 'french'
                ? 'Budget des projets du secteur des transports et des communications 2021'
                : 'ميزانية مشاريع قطاع النقل والاتصالات 2021'}
          </h3>
          <button className="close-button1" onClick={onClose}>
            {language === 'english'
              ? 'Close'
              : language === 'french'
                ? 'Fermer'
                : 'أغلق'}
          </button>
        </div>

        <div className="modal-body2">
          <PieChart />
        </div>
      </div>
    </div>
  );
};

export default PieComponent;