import React from 'react';
import { useLanguage } from '../../../../LanguageContext';
import PieChart from './specialized_training_courses_implemented_by_legal_and_judicial_studies_center_ja_Table';
import '../../../MonthlyStatistics/Tables/PopulationModal/Charts/PieChart.css';

const PieComponent = ({ onClose }) => {
  const { language } = useLanguage();

  return (
    <div id="select-modal1" > {/* Set direction */}
      <div className="modal-container1">
        <div className="modal-header1" dir={language === 'arabic' ? 'rtl' : 'ltr'}>
          <h3 className="modal-title1">
            {language === 'english'
              ? 'Specialized Training Courses Implemented by Legal and Judicial Studies Center 2019'
              : language === 'french'
                ? 'Cours de formation spécialisés mis en œuvre par le Centre d’études juridiques et judiciaires 2019'
                : 'دورات تدريبية متخصصة نفذها مركز الدراسات القانونية والقضائية 2019'}
          </h3>
          <button className="close-button1" onClick={onClose}>
            {language === 'english'
              ? 'Close'
              : language === 'french'
                ? 'Fermer'
                : 'أغلق'}
          </button>
        </div>

        <div className="modal-body2">
          <PieChart />
        </div>
      </div>
    </div>
  );
};

export default PieComponent;