import React, { useEffect, useState } from 'react';
import { MaterialReactTable, createMRTColumnHelper, useMaterialReactTable } from 'material-react-table';
import { Box, Button } from '@mui/material';
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import { mkConfig, generateCsv, download } from 'export-to-csv';
import { useLanguage } from '../../../../LanguageContext'; 

const columnHelper = createMRTColumnHelper();

const csvConfig = mkConfig({
  fieldSeparator: ',',
  decimalSeparator: '.',
  useKeysAsHeaders: true,
});

const translations = {
  year: {
    english: 'Year',
    arabic: 'السنة',
    french: 'Année'
  },
  type_of_health_facilities: {
    english: 'Type of Health Facilities',
    arabic: 'نوع المرافق الصحية',
    french: 'Type d\'établissements de santé'
  },
  nw_lmrfq_lshy: {
    english: 'Type of Health Facilities',
    arabic: 'نوع المرافق الصحية',
    french: 'Type d\'établissements de santé'
  },
  total: {
    english: 'Total',
    arabic: 'المجموع',
    french: 'Total'
  },
  exportAllData: {
    english: 'Export All Data',
    arabic: 'تصدير جميع البيانات',
    french: 'Exporter toutes les données'
  },
  exportAllRows: {
    english: 'Export All Rows',
    arabic: 'تصدير جميع الصفوف',
    french: 'Exporter toutes les lignes' 
  },
  exportPageRows: {
    english: 'Export Page Rows',
    arabic: 'تصدير صفوف الصفحة',
    french: 'Exporter les lignes de la page' 
  },
  exportSelectedRows: {
    english: 'Export Selected Rows',
    arabic: 'تصدير الصفوف المحددة',
    french: 'Exporter les lignes sélectionnées' 
  }
};
const Example = () => {
  const { language } = useLanguage();
  const [columns, setColumns] = useState([]);
  const [data, setData] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch(`${process.env.REACT_APP_API}/api/health_facilities`); // Replace with your actual API endpoint
        const result = await response.json();

        if (result.columns && result.data) {
          const fetchedColumns = result.columns.map((col) => {
            const header = translations[col.key] ? translations[col.key][language] : col.header;

            // Hide Arabic-specific columns for English
            if (language === 'english' || language === 'french') {
              if (col.key === 'nw_lmrfq_lshy') { 
                return null;
              }
              return columnHelper.accessor(col.key, { header }); 
            } 
            // Hide English-specific columns for Arabic
            else if (language === 'arabic') {
              if (col.key === 'type_of_health_facilities') { 
                return null;
              }
              return columnHelper.accessor(col.key, { header }); 
            }

            return null; 
          }).filter(Boolean);

          setColumns(fetchedColumns);
          setData(result.data);
        }
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };

    fetchData();
  }, [language]);

  const handleExportRows = (rows) => {
    const rowData = rows.map((row) => row.original);
    const csv = generateCsv(csvConfig)(rowData);
    download(csvConfig)(csv);
  };

  const handleExportData = () => {
    const csv = generateCsv(csvConfig)(data);
    download(csvConfig)(csv);
  };

  const table = useMaterialReactTable({
    columns,
    data,
    enableFullScreenToggle: false,
    enableRowSelection: true,
    columnFilterDisplayMode: 'popover',
    paginationDisplayMode: 'pages',
    positionToolbarAlertBanner: 'bottom',
    renderTopToolbarCustomActions: ({ table }) => (
      <Box
        sx={{
          display: 'flex',
          gap: '16px',
          padding: '8px',
          flexWrap: 'wrap',
        }}
      >
        <Button onClick={handleExportData} startIcon={<FileDownloadIcon />}>
          {translations.exportAllData[language]}
        </Button>
        <Button
          disabled={table.getPrePaginationRowModel().rows.length === 0}
          onClick={() => handleExportRows(table.getPrePaginationRowModel().rows)}
          startIcon={<FileDownloadIcon />}
        >
          {translations.exportAllRows[language]}
        </Button>
        <Button
          disabled={table.getRowModel().rows.length === 0}
          onClick={() => handleExportRows(table.getRowModel().rows)}
          startIcon={<FileDownloadIcon />}
        >
          {translations.exportPageRows[language]}
        </Button>
        <Button
          disabled={!table.getIsSomeRowsSelected() && !table.getIsAllRowsSelected()}
          onClick={() => handleExportRows(table.getSelectedRowModel().rows)}
          startIcon={<FileDownloadIcon />}
        >
          {translations.exportSelectedRows[language]}
        </Button>
      </Box>
    ),
  });

  return (
    <Box>
      <MaterialReactTable table={table} />
    </Box>
  );
};

export default Example;