import React, { useState, useEffect } from 'react';
import { Container, Row, Col } from 'reactstrap';
import { AiOutlineTable, AiOutlineBarChart } from 'react-icons/ai';
import { FaHeartbeat, FaFutbol, FaBalanceScale, FaHome, FaUsers, FaCamera, FaMoneyBill, FaTree, FaBriefcase, FaGraduationCap } from 'react-icons/fa';

import './CardSlider.css';

import { useLanguage } from "../../LanguageContext"; 
import translations from '../../../translations.json'; 
import { Helmet } from 'react-helmet';
import Navbar from '../AboutUs/Navbar'
import DemoFooter from '../Footer/Footer'
import { useParams } from 'react-router-dom'; 
import sport from './tables/sports/table_component'
import youth_sports_activities from './tables/sports/those_who_practice_activities_in_youth_and_sports_institutions_by_activity_age_g'
import school_students from './tables/education/school_results'
import school_staff from './tables/education/d2_school_staff'
  import marriages_by_wife_location from './tables/social/marriages_by_nationality_and_place_of_wife_2015'
  import marriages_by_husband_location from './tables/social/marriages_by_nationality_and_place_of_husband_2015'
  import legal_studies_courses from './tables/security/specialized_training_courses_implemented_by_legal_and_judicial_studies_center_ja'
  import population_estimates from './tables/population/population_estimate_as_of_mid_1986_2015'
  import marriage_rate from './tables/population/general_marriage_rate_by_nationality_and_gender_per_1000_population_15_year_and_'
  import hotel_statistics from './tables/media/hotels_rooms_beds_occupants_and_touristic_nights_'
  import heritage_sites_in_the_state_of_qatar from './tables/media/heritage_sites_in_the_state_of_qatar'
  import budget_for_health_sector_projects_2021 from './tables/health/health_sector_projects_2021'
  import health_facilities from './tables/health/g1_health_facilities'
  import state_budget_2021 from './tables/finance/budget_of_the_state_of_qatar_for_2021_fiscal_year'
  import transport_comm_budget from './tables/finance/transport_and_communications_sector_projects_2021'
  import doha_air_quality from './tables/environment/annual_average_of_air_quality_doha_city_2015'
  import temperature_humidity from './tables/environment/maximum_minimum_temperature_and_monthly_relative_humidity_2011_2018'
  import female_labor_force from './tables/employment/female_population_15_years_and_above_by_relation_to_labor_force_and_age_groups'
  import inactive_pop_edu from './tables/employment/economically_inactive_population_15_years_and_above_by_nationality_gender_and_ed'

const CardSlider = () => {
  const [activeCategory, setActiveCategory] = useState('Sports'); // Initial category
  const [activeChartComponent, setActiveChartComponent] = useState(null);
  const [activeTableComponent, setActiveTableComponent] = useState(null);
  const { language } = useLanguage(); 
  const currentTranslations = translations[language]; 
  const { category } = useParams(); // Get the category from the URL

  // Removed Slider settings and components

  // Dummy data example for Sports category
  const sportsData = [
    {
      title: 'Budget for Culture and Sports Sector Projects 2021',
      title_ar: 'ميزانية مشاريع قطاع الثقافة والرياضة 2021',
      title_fr: 'Budget des projets du secteur de la culture et des sports 2021',
      body: 'Breakdown of the budget allocated to culture and sports sector projects in 2021.',
      body_ar: 'تفصيل الميزانية المخصصة لمشاريع قطاع الثقافة والرياضة في عام 2021.',
      body_fr: 'Ventilation du budget alloué aux projets du secteur de la culture et des sports en 2021.',
    },
    {
      title: 'Those Who Practice Activities in Youth and Sports Institutions by Activity, Age Group, Nationality and Gender',
      title_ar: 'الذين يمارسون الأنشطة في مؤسسات الشباب والرياضة حسب النشاط والفئة العمرية والجنسية والجنس',
      title_fr: 'Les personnes qui pratiquent des activités dans les institutions de la jeunesse et des sports par activité, groupe d’âge, nationalité et sexe',
      body: 'Data on participants in youth and sports activities, categorized by activity, age group, nationality, and gender.',
      body_ar: 'بيانات عن المشاركين في أنشطة الشباب والرياضة، مصنفة حسب النشاط والفئة العمرية والجنسية والجنس.',
      body_fr: 'Données sur les participants aux activités de la jeunesse et des sports.',
    },
    {
      title: 'Sports Facilities by Type and Agency',
      title_ar: 'المرافق الرياضية حسب النوع والوكالة',
      title_fr: 'Installations sportives par type et agence',
      body: 'Information on sports facilities in Qatar, categorized by type and the agency responsible for them.',
      body_ar: 'معلومات عن المرافق الرياضية في قطر، مصنفة حسب النوع والوكالة المسؤولة عنها.',
      body_fr: 'Informations sur les installations sportives au Qatar, classées par type et l’agence responsable de celles-ci.',
    },
    {
      title: 'Sports Facilities at Schools by Type of Schools and Type of Playground',
      title_ar: 'المرافق الرياضية في المدارس حسب نوع المدارس ونوع ملعب اللعب',
      title_fr: 'Installations sportives dans les écoles par type d’écoles et type de terrain de jeu',
      body: 'Data on sports facilities available at schools in Qatar, categorized by school type and playground type.',
      body_ar: 'بيانات عن المرافق الرياضية المتاحة في المدارس في قطر، مصنفة حسب نوع المدرسة ونوع ملعب اللعب.',
      body_fr: 'Données sur les installations sportives disponibles dans les écoles au Qatar, classées par type d’école et type de terrain de jeu.',
    },
    {
      title: 'Monthly Average of Sports Practitioners in Ferjan Playgrounds',
      title_ar: 'المتوسط ​​الشهري لممارسي الرياضة في ساحات الفرجان',
      title_fr: 'Moyenne mensuelle des pratiquants de sport dans les terrains de jeu Ferjan',
      body: 'Average number of people practicing sports in Ferjan playgrounds each month.',
      body_ar: 'متوسط ​​عدد الأشخاص الذين يمارسون الرياضة في ساحات الفرجان كل شهر.',
      body_fr: 'Nombre moyen de personnes pratiquant des sports dans les terrains de jeu Ferjan chaque mois.',
    },
    {
      title: 'Sport Institutions by Type',
      title_ar: 'المؤسسات الرياضية حسب النوع',
      title_fr: 'Institutions sportives par type',
      body: 'List of sports institutions in Qatar categorized by type.',
      body_ar: 'قائمة بالمؤسسات الرياضية في قطر مصنفة حسب النوع.',
      body_fr: 'Liste des institutions sportives au Qatar classées par type.',
    },
    {
      title: 'Sports Facilities by Type',
      title_ar: 'المرافق الرياضية حسب النوع',
      title_fr: 'Installations sportives par type',
      body: 'Detailed information about various types of sports facilities in Qatar.',
      body_ar: 'معلومات مفصلة عن أنواع مختلفة من المرافق الرياضية في قطر.',
      body_fr: 'Informations détaillées sur les différents types d’installations sportives au Qatar.',
    },
    {
      title: 'Schools, Sports Facilities by Municipality and Type of Playground',
      title_ar: 'مرافق المدارس الرياضية حسب البلدية ونوع ملعب اللعب',
      title_fr: 'Installations sportives des écoles par municipalité et type de terrain de jeu',
      body: 'Data on sports facilities in schools, categorized by municipality and playground type.',
      body_ar: 'بيانات عن المرافق الرياضية في المدارس، مصنفة حسب البلدية ونوع ملعب اللعب.',
      body_fr: 'Données sur les installations sportives dans les écoles, classées par municipalité et type de terrain de jeu.',
    },
    {
      title: 'Most Important of Qatar Imports From Sports Goods',
      title_ar: 'أهم واردات قطر من السلع الرياضية',
      title_fr: 'Les plus importantes importations du Qatar en biens sportifs',
      body: 'Information on the most significant sports goods imported by Qatar.',
      body_ar: 'معلومات عن أهم السلع الرياضية المستوردة من قبل قطر.',
      body_fr: 'Informations sur les biens sportifs les plus importants importés par le Qatar.',
    },
    {
      title: 'Participants in Locally Executed Youth and Sports Activities by Events, Nationality and Gender',
      title_ar: 'المشاركين في الأنشطة الشبابية والرياضية المنفذة محليا حسب الفعاليات والجنسية والجنس',
      title_fr: 'Participants aux activités de la jeunesse et des sports exécutées localement par événements, nationalité et sexe',
      body: 'Data on participants in locally executed youth and sports activities, categorized by event, nationality, and gender.',
      body_ar: 'بيانات عن المشاركين في أنشطة الشباب والرياضة المنفذة محليا، مصنفة حسب الفعالية والجنسية والجنس.',
      body_fr: 'Données sur les participants aux activités de la jeunesse et des sports exécutées localement.',
    },
    {
      title: 'Employees in Youth Institutions by Occupation, Type of Work, Nationality and Gender',
      title_ar: 'الموظفون في مؤسسات الشباب حسب المهنة ونوع العمل والجنسية والجنس',
      title_fr: 'Employés dans les institutions de la jeunesse par profession, type de travail, nationalité et sexe',
      body: 'Data on employees in youth institutions, categorized by occupation, type of work, nationality, and gender.',
      body_ar: 'بيانات عن الموظفين في مؤسسات الشباب، مصنفة حسب المهنة ونوع العمل والجنسية والجنس.',
      body_fr: 'Données sur les employés des institutions de la jeunesse, classées par profession, type de travail, nationalité et sexe.',
    }
  ];
// Repeat the data structure for other categories
const socialAffairsData = [
  {
    title: 'Marriages by Nationality and Place of Wife',
    title_ar: 'الزواج حسب الجنسية ومكان الزوجة',
    title_fr: 'Mariages par nationalité et lieu de résidence de l’épouse',
    body: 'Data on marriages in Qatar, categorized by the nationality of the wife and her place of residence.',
    body_ar: 'بيانات عن الزواج في قطر، مصنفة حسب جنسية الزوجة ومكان إقامتها.',
    body_fr: 'Données sur les mariages au Qatar, classées par nationalité de l’épouse et son lieu de résidence.',
  },
  {
    title: 'Marriages by Nationality and Place of Husband',
    title_ar: 'الزواج حسب الجنسية ومكان الزوج',
    title_fr: 'Mariages par nationalité et lieu de résidence du mari',
    body: 'Data on marriages in Qatar, categorized by the nationality of the husband and his place of residence.',
    body_ar: 'بيانات عن الزواج في قطر، مصنفة حسب جنسية الزوج ومكان إقامته.',
    body_fr: 'Données sur les mariages au Qatar, classées par nationalité du mari et son lieu de résidence.',
  },
  {
    title: 'Registered Marriages and Divorces by Husband’s Nationality',
    title_ar: 'الزواج والطلاق المسجل حسب جنسية الزوج',
    title_fr: 'Mariages et divorces enregistrés par nationalité du mari',
    body: 'Statistics on marriages and divorces in Qatar, categorized by the nationality of the husband.',
    body_ar: 'إحصاءات عن الزواج والطلاق في قطر، مصنفة حسب جنسية الزوج.',
    body_fr: 'Statistiques sur les mariages et les divorces au Qatar, classées par nationalité du mari.',
  },

  {
    title: 'Marriages And Divorces Crude Rate per 1000 Population',
    title_ar: 'معدل الزواج والطلاق الخام لكل 1000 نسمة',
    title_fr: 'Taux brut de mariages et de divorces pour 1 000 habitants',
    body: 'Crude rates of marriages and divorces per 1000 people in Qatar.',
    body_ar: 'معدلات الزواج والطلاق الخام لكل 1000 شخص في قطر.',
    body_fr: 'Taux bruts de mariages et de divorces pour 1 000 personnes au Qatar.',
  },
  {
    title: 'Divorces of Qataris with Relation of Second Class According to Age Group of Wife and Husband',
    title_ar: 'طلاق القطريين مع علاقة من الدرجة الثانية حسب الفئة العمرية للزوجة والزوج',
    title_fr: 'Divorces de Qatariens avec une relation de deuxième classe selon le groupe d’âge de l’épouse et du mari',
    body: 'Statistics on divorces between Qatari couples with a second-degree relationship, categorized by the age groups of the wife and husband.',
    body_ar: 'إحصاءات عن الطلاق بين الأزواج القطريين مع علاقة من الدرجة الثانية، مصنفة حسب الفئات العمرية للزوجة والزوج.',
    body_fr: 'Statistiques sur les divorces entre couples qataris avec une relation de deuxième classe',
  },
  {
    title: 'Divorces of Qataris with Relation of First Class According to Age Group of Wife and Husband',
    title_ar: 'طلاق القطريين مع علاقة من الدرجة الأولى بينهم حسب الفئة العمرية للزوجة والزوج',
    title_fr: 'Divorces de Qatariens avec une relation de première classe entre eux selon le groupe d’âge de l’épouse et du mari',
    body: 'Statistics on divorces between Qatari couples with a first-degree relationship, categorized by the age groups of the wife and husband.',
    body_ar: 'إحصاءات عن الطلاق بين الأزواج القطريين مع علاقة من الدرجة الأولى، مصنفة حسب الفئات العمرية للزوجة والزوج.',
    body_fr: 'Statistiques sur les divorces entre couples qataris avec une relation de première classe',
  },
  {
    title: 'Divorces of Qataris with No Relation Between Them According to Age Group of Wife and Husband',
    title_ar: 'طلاق القطريين بدون علاقة بينهم حسب الفئة العمرية للزوجة والزوج',
    title_fr: 'Divorces de Qatariens sans relation entre eux selon le groupe d’âge de l’épouse et du mari',
    body: 'Statistics on divorces between Qatari couples with no relationship, categorized by the age groups of the wife and husband.',
    body_ar: 'إحصاءات عن الطلاق بين الأزواج القطريين بدون علاقة، مصنفة حسب الفئات العمرية للزوجة والزوج.',
    body_fr: 'Statistiques sur les divorces entre couples qataris sans relation',
  },
  // Add more data for this category
];

const securityJurisprudenceData = [

  {
    title: 'Specialized Training Courses Implemented by Legal and Judicial Studies Center 2019',
    title_ar: 'دورات تدريبية متخصصة نفذها مركز الدراسات القانونية والقضائية 2019',
    title_fr: 'Cours de formation spécialisés mis en œuvre par le Centre d’études juridiques et judiciaires 2019',
    body: 'List of specialized training courses implemented by the Legal and Judicial Studies Center in 2019.',
    body_ar: 'قائمة بالدورات التدريبية المتخصصة التي نفذها مركز الدراسات القانونية والقضائية في عام 2019.',
    body_fr: 'Liste des cours de formation spécialisés mis en œuvre par le Centre d’études juridiques et judiciaires en 2019.',
  }
  // Add more data for this category
];


const populationVitalStatisticsData = [
  {
    title: 'Population Estimate',
    title_ar: 'تقدير عدد السكان',
    title_fr: 'Estimation de la population',
    body: 'Estimated population figures for Qatar.',
    body_ar: 'أرقام تقديرية لعدد السكان في قطر.',
    body_fr: 'Chiffres d’estimation de la population au Qatar.',
  },
  {
    title: 'General Marriage Rate By Nationality and Gender per 1000 Population (15 Year and Above)',
    title_ar: 'معدل الزواج العام حسب الجنسية والجنس لكل 1000 نسمة (15 سنة فما فوق)',
    title_fr: 'Taux général de mariage par nationalité et sexe pour 1 000 habitants (15 ans et plus)',
    body: 'General marriage rate for different nationalities and genders in Qatar, for individuals aged 15 years and above.',
    body_ar: 'معدل الزواج العام لل جنسيات المختلفة والأجناس في قطر، للأفراد الذين تبلغ أعمارهم 15 عامًا فما فوق.',
    body_fr: 'Taux général de mariage pour les différentes nationalités et sexes au Qatar, pour les personnes âgées de 15 ans et plus.',
  },
  {
    title: 'Registered Marriages and Divorces by Husband’s Nationality',
    title_ar: 'الزواج والطلاق المسجل حسب جنسية الزوج',
    title_fr: 'Mariages et divorces enregistrés par nationalité du mari',
    body: 'Statistics on marriages and divorces in Qatar, categorized by the nationality of the husband.',
    body_ar: 'إحصاءات عن الزواج والطلاق في قطر، مصنفة حسب جنسية الزوج.',
    body_fr: 'Statistiques sur les mariages et les divorces au Qatar, classées par nationalité du mari.',
  },
  {
    title: 'Marriages by Nationality and Place of Wife',
    title_ar: 'الزواج حسب الجنسية ومكان الزوجة',
    title_fr: 'Mariages par nationalité et lieu de résidence de l’épouse',
    body: 'Data on marriages in Qatar, categorized by the nationality of the wife and her place of residence.',
    body_ar: 'بيانات عن الزواج في قطر، مصنفة حسب جنسية الزوجة ومكان إقامتها.',
    body_fr: 'Données sur les mariages au Qatar, classées par nationalité de l’épouse et son lieu de résidence.',
  },
  {
    title: 'Marriages by Nationality and Place of Husband',
    title_ar: 'الزواج حسب الجنسية ومكان الزوج',
    title_fr: 'Mariages par nationalité et lieu de résidence du mari',
    body: 'Data on marriages in Qatar, categorized by the nationality of the husband and his place of residence.',
    body_ar: 'بيانات عن الزواج في قطر، مصنفة حسب جنسية الزوج ومكان إقامته.',
    body_fr: 'Données sur les mariages au Qatar, classées par nationalité du mari et son lieu de résidence.',
  },
  {
    title: 'Marriages And Divorces Crude Rate per 1000 Population',
    title_ar: 'معدل الزواج والطلاق الخام لكل 1000 نسمة',
    title_fr: 'Taux brut de mariages et de divorces pour 1 000 habitants',
    body: 'Crude rates of marriages and divorces per 1000 people in Qatar.',
    body_ar: 'معدلات الزواج والطلاق الخام لكل 1000 شخص في قطر.',
    body_fr: 'Taux bruts de mariages et de divorces pour 1 000 personnes au Qatar.',
  },

  {
    title: 'Registered Live Births By Nationality, Gender and Birth Weight',
    title_ar: 'المواليد الأحياء المسجلون حسب الجنسية والجنس ووزن الولادة',
    title_fr: 'Naissances vivantes enregistrées par nationalité, sexe et poids à la naissance',
    body: 'Statistics on live births registered in Qatar, categorized by nationality, gender, and birth weight.',
    body_ar: 'إحصاءات عن المواليد الأحياء المسجلين في قطر، مصنفة حسب الجنسية والجنس ووزن الولادة.',
    body_fr: 'Statistiques sur les naissances vivantes enregistrées au Qatar, classées par nationalité, sexe et poids à la naissance.',
  },
  {
    title: 'Registered Deaths by Nationality, Gender and Single Year of Age',
    title_ar: 'الوفيات المسجلة حسب الجنسية والجنس وعام العمر الواحد',
    title_fr: 'Décès enregistrés par nationalité, sexe et année d’âge unique',
    body: 'Statistics on deaths registered in Qatar, categorized by nationality, gender, and single year of age.',
    body_ar: 'إحصاءات عن الوفيات المسجلة في قطر، مصنفة حسب الجنسية والجنس وعام العمر الواحد.',
    body_fr: 'Statistiques sur les décès enregistrés au Qatar, classées par nationalité, sexe et année d’âge unique.',
  },
  {
    title: 'Registered Deaths (15 Years Old and Over) By Marital Status, Age Group and Gender (Total)',
    title_ar: 'الوفيات المسجلة (15 عامًا فما فوق) حسب الحالة الاجتماعية والفئة العمرية والجنس (المجموع)',
    title_fr: 'Décès enregistrés (15 ans et plus) par état civil, groupe d’âge et sexe (total)',
    body: 'Statistics on registered deaths for individuals aged 15 years and over in Qatar, categorized by marital status, age group, and gender.',
    body_ar: 'إحصاءات عن الوفيات المسجلة للأفراد الذين تبلغ أعمارهم 15 عامًا فما فوق في قطر، مصنفة حسب الحالة الاجتماعية والفئة العمرية والجنس.',
    body_fr: 'Statistiques sur les décès enregistrés pour les personnes âgées de 15 ans et plus au Qatar, classées par état civil, groupe d’âge et sexe.',
  },
  {
    title: 'Registered Live Births By Mother’s Age Group, Occupation and Nationality',
    title_ar: 'المواليد الأحياء المسجلون حسب الفئة العمرية للأم والمهنة والجنسية',
    title_fr: 'Naissances vivantes enregistrées par groupe d’âge de la mère, profession et nationalité',
    body: 'Statistics on live births registered in Qatar, categorized by the mother’s age group, occupation, and nationality.',
    body_ar: 'إحصاءات عن المواليد الأحياء المسجلين في قطر، مصنفة حسب الفئة العمرية للأم والمهنة والجنسية.',
    body_fr: 'Statistiques sur les naissances vivantes enregistrées au Qatar, classées par groupe d’âge de la mère, profession et nationalité.',
  },
  {
    title: 'Registered Live Births by Father’s Age Group, Occupation and Nationality',
    title_ar: 'المواليد الأحياء المسجلون حسب الفئة العمرية للأب والمهنة والجنسية',
    title_fr: 'Naissances vivantes enregistrées par groupe d’âge du père, profession et nationalité',
    body: 'Statistics on live births registered in Qatar, categorized by the father’s age group, occupation, and nationality.',
    body_ar: 'إحصاءات عن المواليد الأحياء المسجلين في قطر، مصنفة حسب الفئة العمرية للأب والمهنة والجنسية.',
    body_fr: 'Statistiques sur les naissances vivantes enregistrées au Qatar, classées par groupe d’âge du père, profession et nationalité.',
  },
  {
    title: 'Registered Live Births by Educational Status of Father and Mother (Total)',
    title_ar: 'المواليد الأحياء المسجلون حسب المستوى التعليمي للأب والأم (المجموع)',
    title_fr: 'Naissances vivantes enregistrées par niveau d’éducation du père et de la mère (total)',
    body: 'Statistics on live births registered in Qatar, categorized by the educational status of the father and mother.',
    body_ar: 'إحصاءات عن المواليد الأحياء المسجلين في قطر، مصنفة حسب المستوى التعليمي للأب والأم.',
    body_fr: 'Statistiques sur les naissances vivantes enregistrées au Qatar, classées par niveau d’éducation du père et de la mère.',
  },
  {
    title: 'Registered Deaths by Nationality and Gender',
    title_ar: 'الوفيات المسجلة حسب الجنسية والجنس',
    title_fr: 'Décès enregistrés par nationalité et sexe',
    body: 'Statistics on deaths registered in Qatar, categorized by nationality and gender.',
    body_ar: 'إحصاءات عن الوفيات المسجلة في قطر، مصنفة حسب الجنسية والجنس.',
    body_fr: 'Statistiques sur les décès enregistrés au Qatar, classées par nationalité et sexe.',
  },
  {
    title: 'Registered Deaths (15 Years Old and Over) by Marital Status, Age Group and Gender (Qataris)',
    title_ar: 'الوفيات المسجلة (15 عامًا فما فوق) حسب الحالة الاجتماعية والفئة العمرية والجنس (القطريون)',
    title_fr: 'Décès enregistrés (15 ans et plus) par état civil, groupe d’âge et sexe (Qataris)',
    body: 'Statistics on registered deaths for Qatari individuals aged 15 years and over in Qatar, categorized by marital status, age group, and gender.',
    body_ar: 'إحصاءات عن الوفيات المسجلة للأفراد القطريين الذين تبلغ أعمارهم 15 عامًا فما فوق في قطر، مصنفة حسب الحالة الاجتماعية والفئة العمرية والجنس.',
    body_fr: 'Statistiques sur les décès enregistrés pour les personnes qataries âgées de 15 ans et plus au Qatar, classées par état civil, groupe d’âge et sexe.',
  },
  {
    title: 'Registered Deaths (15 Years Old and Over) by Marital Status, Age Group and Gender (Non-Qataris)',
    title_ar: 'الوفيات المسجلة (15 عامًا فما فوق) حسب الحالة الاجتماعية والفئة العمرية والجنس (غير القطريين)',
    title_fr: 'Décès enregistrés (15 ans et plus) par état civil, groupe d’âge et sexe (non-Qataris)',
    body: 'Statistics on registered deaths for non-Qatari individuals aged 15 years and over in Qatar, categorized by marital status, age group, and gender.',
    body_ar: 'إحصاءات عن الوفيات المسجلة للأفراد غير القطريين الذين تبلغ أعمارهم 15 عامًا فما فوق في قطر، مصنفة حسب الحالة الاجتماعية والفئة العمرية والجنس.',
    body_fr: 'Statistiques sur les décès enregistrés pour les personnes non-qataries âgées de 15 ans et plus au Qatar, classées par état civil, groupe d’âge et sexe.',
  },
  {
    title: 'Qatari Deaths Overseas by Age Groups and Gender',
    title_ar: 'الوفيات القطرية في الخارج حسب الفئات العمرية والجنس',
    title_fr: 'Décès de Qatariens à l’étranger par groupes d’âge et sexe',
    body: 'Statistics on deaths of Qatari individuals that occurred overseas, categorized by age group and gender.',
    body_ar: 'إحصاءات عن وفيات الأفراد القطريين التي وقعت في الخارج، مصنفة حسب الفئة العمرية والجنس.',
    body_fr: 'Statistiques sur les décès de personnes qataries survenus à l’étranger, classées par groupe d’âge et sexe.',
  }
  // Add more data for this category
];

const mediaCultureTourismData = [
  {
    title: 'HOTELS, ROOMS, BEDS, OCCUPANTS AND TOURISTIC NIGHTS',
    title_ar: 'الفنادق، الغرف، الأسرة، المقيمون وليالي السياحة',
    title_fr: 'HÔTELS, CHAMBRES, LITS, OCCUPANTS ET NUITS TOURISTIQUES',
    body: 'Statistics related to hotels, rooms, beds, occupants, and touristic nights in Qatar.',
    body_ar: 'إحصاءات تتعلق بالفنادق والغرف والأسرة والمقيمون وليالي السياحة في قطر.',
    body_fr: 'Statistiques relatives aux hôtels, chambres, lits, occupants et nuits touristiques au Qatar.',
  },
  {
    title: 'Heritage Sites in the State of Qatar',
    title_ar: 'مواقع التراث في دولة قطر',
    title_fr: 'Sites du patrimoine dans l’État du Qatar',
    body: 'Information on heritage sites located in Qatar.',
    body_ar: 'معلومات عن مواقع التراث الموجودة في قطر.',
    body_fr: 'Informations sur les sites du patrimoine situés au Qatar.',
  },
  {
    title: 'Facilities of the Cultural Village Foundation (Katara)',
    title_ar: 'مرافق مؤسسة كتارا للقرية الثقافية',
    title_fr: 'Installations de la Fondation du village culturel (Katara)',
    body: 'Information about the facilities available at the Cultural Village Foundation (Katara).',
    body_ar: 'معلومات عن المرافق المتاحة في مؤسسة كتارا للقرية الثقافية.',
    body_fr: 'Informations sur les installations disponibles à la Fondation du village culturel (Katara).',
  },
  {
    title: 'Events at Qatar National Theater by Type of Event',
    title_ar: 'الفعاليات في المسرح الوطني القطري حسب نوع الفعالية',
    title_fr: 'Événements au Théâtre national du Qatar par type d’événement',
    body: 'Data on events held at the Qatar National Theater, categorized by event type.',
    body_ar: 'بيانات عن الفعاليات التي أقيمت في المسرح الوطني القطري، مصنفة حسب نوع الفعالية.',
    body_fr: 'Données sur les événements organisés au Théâtre national du Qatar, classés par type d’événement.',
  },
  {
    title: 'Events at Qatar National Theater By Sector',
    title_ar: 'الفعاليات في المسرح الوطني القطري حسب القطاع',
    title_fr: 'Événements au Théâtre national du Qatar par secteur',
    body: 'Data on events held at the Qatar National Theater, categorized by sector.',
    body_ar: 'بيانات عن الفعاليات التي أقيمت في المسرح الوطني القطري، مصنفة حسب القطاع.',
    body_fr: 'Données sur les événements organisés au Théâtre national du Qatar, classés par secteur.',
  },
  {
    title: 'Cultural Events in the Cultural Village Foundation (Katara) By Month',
    title_ar: 'الفعاليات الثقافية في مؤسسة كتارا للقرية الثقافية حسب الشهر',
    title_fr: 'Événements culturels à la Fondation du village culturel (Katara) par mois',
    body: 'Data on cultural events held at the Cultural Village Foundation (Katara), categorized by month.',
    body_ar: 'بيانات عن الفعاليات الثقافية التي أقيمت في مؤسسة كتارا للقرية الثقافية، مصنفة حسب الشهر.',
    body_fr: 'Données sur les événements culturels organisés à la Fondation du village culturel (Katara), classés par mois.',
  }
  // Add more data for this category
];

const healthData = [
  {
    title: 'Budget for Health Sector Projects 2021',
    title_ar: 'ميزانية مشاريع قطاع الصحة 2021',
    title_fr: 'Budget des projets du secteur de la santé 2021',
    body: 'Breakdown of the budget allocated to health sector projects in 2021.',
    body_ar: 'تفصيل الميزانية المخصصة لمشاريع قطاع الصحة في عام 2021.',
    body_fr: 'Ventilation du budget alloué aux projets du secteur de la santé en 2021.',
  },
  {
    title: 'Health Facilities',
    title_ar: 'مرافق الصحة',
    title_fr: 'Établissements de santé',
    body: 'Information on health facilities in Qatar, including hospitals, clinics, and other healthcare centers.',
    body_ar: 'معلومات عن مرافق الصحة في قطر، بما في ذلك المستشفيات والعيادات والمراكز الصحية الأخرى.',
    body_fr: 'Informations sur les établissements de santé au Qatar, y compris les hôpitaux, les cliniques et les autres centres de soins de santé.',
  },
  {
    title: 'Vaccinations',
    title_ar: 'التطعيمات',
    title_fr: 'Vaccinations',
    body: 'Statistics on vaccination coverage and programs in Qatar.',
    body_ar: 'إحصاءات عن تغطية التطعيم والبرامج في قطر.',
    body_fr: 'Statistiques sur la couverture vaccinale et les programmes au Qatar.',
  },
  {
    title: 'Communicable Disease Reports',
    title_ar: 'تقارير الأمراض المعدية',
    title_fr: 'Rapports sur les maladies transmissibles',
    body: 'Reports on the incidence and trends of communicable diseases in Qatar.',
    body_ar: 'تقارير عن انتشار واتجاهات الأمراض المعدية في قطر.',
    body_fr: 'Rapports sur l’incidence et les tendances des maladies transmissibles au Qatar.',
  },
  {
    title: 'Infectious Disease Reports',
    title_ar: 'تقارير الأمراض المعدية',
    title_fr: 'Rapports sur les maladies infectieuses',
    body: 'Reports on the incidence and trends of infectious diseases in Qatar.',
    body_ar: 'تقارير عن انتشار واتجاهات الأمراض المعدية في قطر.',
    body_fr: 'Rapports sur l’incidence et les tendances des maladies infectieuses au Qatar.',
  },
  {
    title: 'Health Visitors',
    title_ar: 'زوار الصحة',
    title_fr: 'Visiteurs de santé',
    body: 'Information on health visitor services and programs in Qatar.',
    body_ar: 'معلومات عن خدمات وبرامج زوار الصحة في قطر.',
    body_fr: 'Informations sur les services et programmes de visiteurs de santé au Qatar.',
  },
  {
    title: 'Coronavirus Disease 2019 (COVID-19) Statistics',
    title_ar: 'إحصاءات مرض فيروس كورونا 2019 (COVID-19)',
    title_fr: 'Statistiques sur la maladie à coronavirus 2019 (COVID-19)',
    body: 'Statistics related to COVID-19 cases, testing, and vaccination in Qatar.',
    body_ar: 'إحصاءات تتعلق بحالات COVID-19 والاختبارات والتطعيم في قطر.',
    body_fr: 'Statistiques relatives aux cas de COVID-19, aux tests et à la vaccination au Qatar.',
  },
  {
    title: 'Hotline “16000” Statistics of COVID-19',
    title_ar: 'إحصاءات الخط الساخن "16000" لـ COVID-19',
    title_fr: 'Statistiques de la ligne d’assistance “16000” pour la COVID-19',
    body: 'Statistics on calls received by the COVID-19 hotline "16000" in Qatar.',
    body_ar: 'إحصاءات عن المكالمات التي تم تلقيها على الخط الساخن لـ COVID-19 "16000" في قطر.',
    body_fr: 'Statistiques sur les appels reçus par la ligne d’assistance COVID-19 "16000" au Qatar.',
  }
  // Add more data for this category
];

const financeEconomyData = [
  {
    title: 'Budget of the State of Qatar for 2021 Fiscal Year',
    title_ar: 'ميزانية دولة قطر للسنة المالية 2021',
    title_fr: 'Budget de l’État du Qatar pour l’exercice fiscal 2021',
    body: 'Overview of the Qatar state budget for the 2021 fiscal year.',
    body_ar: 'نظرة عامة على ميزانية دولة قطر للسنة المالية 2021.',
    body_fr: 'Aperçu du budget de l’État du Qatar pour l’exercice fiscal 2021.',
  },
  {
    title: 'Budget for Transport and Communications Sector Projects 2021',
    title_ar: 'ميزانية مشاريع قطاع النقل والاتصالات 2021',
    title_fr: 'Budget des projets du secteur des transports et des communications 2021',
    body: 'Breakdown of the budget allocated to transport and communications sector projects in 2021.',
    body_ar: 'تفصيل الميزانية المخصصة لمشاريع قطاع النقل والاتصالات في عام 2021.',
    body_fr: 'Ventilation du budget alloué aux projets du secteur des transports et des communications en 2021.',
  },
  {
    title: 'Distribution of Oil and Non-Oil Revenues in 2021 Budget',
    title_ar: 'توزيع إيرادات النفط وغير النفط في ميزانية 2021',
    title_fr: 'Répartition des revenus pétroliers et non pétroliers dans le budget 2021',
    body: 'Information on the distribution of oil and non-oil revenues in the 2021 budget.',
    body_ar: 'معلومات عن توزيع إيرادات النفط وغير النفط في ميزانية 2021.',
    body_fr: 'Informations sur la répartition des revenus pétroliers et non pétroliers dans le budget 2021.',
  },
  {
    title: 'Percentage of Allocations for Most Important Sectors in Budget for Fiscal Year 2021',
    title_ar: 'نسبة التخصيصات لأهم القطاعات في ميزانية السنة المالية 2021',
    title_fr: 'Pourcentage des allocations pour les secteurs les plus importants dans le budget pour l’exercice fiscal 2021',
    body: 'Percentage breakdown of budget allocations for key sectors in the 2021 fiscal year.',
    body_ar: 'تفصيل النسبة المئوية لتخصيصات الميزانية للقطاعات الرئيسية في السنة المالية 2021.',
    body_fr: 'Ventilation en pourcentage des allocations budgétaires pour les principaux secteurs de l’exercice fiscal 2021.',
  },
  {
    title: 'Cost of Major Project Allocations in Main Sectors 2021',
    title_ar: 'تكلفة تخصيصات المشاريع الرئيسية في القطاعات الرئيسية 2021',
    title_fr: 'Coût des allocations de projets majeurs dans les principaux secteurs 2021',
    body: 'Information on the cost of major project allocations in key sectors in 2021.',
    body_ar: 'معلومات عن تكلفة تخصيصات المشاريع الرئيسية في القطاعات الرئيسية في عام 2021.',
    body_fr: 'Informations sur le coût des allocations de projets majeurs dans les principaux secteurs en 2021.',
  },
  {
    title: 'Budget for Municipal and Environmental Sector Projects 2021',
    title_ar: 'ميزانية مشاريع قطاع البلديات والبيئة 2021',
    title_fr: 'Budget des projets du secteur municipal et environnemental 2021',
    body: 'Breakdown of the budget allocated to municipal and environmental sector projects in 2021.',
    body_ar: 'تفصيل الميزانية المخصصة لمشاريع قطاع البلديات والبيئة في عام 2021.',
    body_fr: 'Ventilation du budget alloué aux projets du secteur municipal et environnemental en 2021.',
  },
  {
    title: 'Budget for Health Sector Projects 2021',
    title_ar: 'ميزانية مشاريع قطاع الصحة 2021',
    title_fr: 'Budget des projets du secteur de la santé 2021',
    body: 'Breakdown of the budget allocated to health sector projects in 2021.',
    body_ar: 'تفصيل الميزانية المخصصة لمشاريع قطاع الصحة في عام 2021.',
    body_fr: 'Ventilation du budget alloué aux projets du secteur de la santé en 2021.',
  },
  {
    title: 'Expenses in State Budget 2021',
    title_ar: 'النفقات في ميزانية الدولة 2021',
    title_fr: 'Dépenses dans le budget de l’État 2021',
    body: 'Overview of expenses included in the 2021 state budget.',
    body_ar: 'نظرة عامة على النفقات المدرجة في ميزانية الدولة لعام 2021.',
    body_fr: 'Aperçu des dépenses incluses dans le budget de l’État en 2021.',
  },
  {
    title: 'Budget for Education Sector Projects 2021',
    title_ar: 'ميزانية مشاريع قطاع التعليم 2021',
    title_fr: 'Budget des projets du secteur de l’éducation 2021',
    body: 'Breakdown of the budget allocated to education sector projects in 2021.',
    body_ar: 'تفصيل الميزانية المخصصة لمشاريع قطاع التعليم في عام 2021.',
    body_fr: 'Ventilation du budget alloué aux projets du secteur de l’éducation en 2021.',
  },
  {
    title: 'Economic Indicators 2018-2021',
    title_ar: 'المؤشرات الاقتصادية 2018-2021',
    title_fr: 'Indicateurs économiques 2018-2021',
    body: 'Key economic indicators for Qatar from 2018 to 2021.',
    body_ar: 'المؤشرات الاقتصادية الرئيسية لقطر من 2018 إلى 2021.',
    body_fr: 'Principaux indicateurs économiques du Qatar de 2018 à 2021.',
  },
  {
    title: 'Budget for Culture and Sports Sector Projects 2021',
    title_ar: 'ميزانية مشاريع قطاع الثقافة والرياضة 2021',
    title_fr: 'Budget des projets du secteur de la culture et des sports 2021',
    body: 'Breakdown of the budget allocated to culture and sports sector projects in 2021.',
    body_ar: 'تفصيل الميزانية المخصصة لمشاريع قطاع الثقافة والرياضة في عام 2021.',
    body_fr: 'Ventilation du budget alloué aux projets du secteur de la culture et des sports en 2021.',
  },
  {
    title: 'Financial Performance of the Public Budget 2020',
    title_ar: 'الأداء المالي للميزانية العامة 2020',
    title_fr: 'Performance financière du budget public 2020',
    body: 'Analysis of the financial performance of the public budget in 2020.',
    body_ar: 'تحليل الأداء المالي للميزانية العامة في عام 2020.',
    body_fr: 'Analyse de la performance financière du budget public en 2020.',
  },
  {
    title: 'Qatar Imports 2012-2018',
    title_ar: 'واردات قطر 2012-2018',
    title_fr: 'Importations du Qatar 2012-2018',
    body: 'Statistics on Qatar’s imports from 2012 to 2018.',
    body_ar: 'إحصاءات عن واردات قطر من 2012 إلى 2018.',
    body_fr: 'Statistiques sur les importations du Qatar de 2012 à 2018.',
  },
  {
    title: 'Qatar Export Statistics 2012-2018',
    title_ar: 'إحصاءات صادرات قطر 2012-2018',
    title_fr: 'Statistiques d’exportation du Qatar 2012-2018',
    body: 'Statistics on Qatar’s exports from 2012 to 2018.',
    body_ar: 'إحصاءات عن صادرات قطر من 2012 إلى 2018.',
    body_fr: 'Statistiques sur les exportations du Qatar de 2012 à 2018.',
  },
  {
    title: 'Most Important of Qatar Imports From Sports Goods',
    title_ar: 'أهم واردات قطر من السلع الرياضية',
    title_fr: 'Les plus importantes importations du Qatar en biens sportifs',
    body: 'Information on the most significant sports goods imported by Qatar.',
    body_ar: 'معلومات عن أهم السلع الرياضية المستوردة من قبل قطر.',
    body_fr: 'Informations sur les biens sportifs les plus importants importés par le Qatar.',
  },
  {
    title: 'Gross Domestic Product by Economic Activity',
    title_ar: 'الناتج المحلي الإجمالي حسب النشاط الاقتصادي',
    title_fr: 'Produit intérieur brut par activité économique',
    body: 'Breakdown of Qatar’s Gross Domestic Product (GDP) by economic activity.',
    body_ar: 'تفصيل الناتج المحلي الإجمالي (GDP) لقطر حسب النشاط الاقتصادي.',
    body_fr: 'Ventilation du produit intérieur brut (PIB) du Qatar par activité économique.',
  }
  // Add more data for this category
];

const environmentWeatherData = [
  {
    title: 'Annual Average of Air Quality - Doha City 2015',
    title_ar: 'المتوسط السنوي لجودة الهواء - مدينة الدوحة 2015',
    title_fr: 'Moyenne annuelle de la qualité de l’air - Ville de Doha 2015',
    body: 'Average air quality data for Doha City in 2015.',
    body_ar: 'بيانات جودة الهواء المتوسطة لمدينة الدوحة في عام 2015.',
    body_fr: 'Données sur la qualité de l’air moyenne pour la ville de Doha en 2015.',
  },
  {
    title: 'Maximum, Minimum Temperature and Monthly Relative Humidity',
    title_ar: 'الحد الأقصى والحد الأدنى لدرجة الحرارة والرطوبة النسبية الشهرية',
    title_fr: 'Température maximale, minimale et humidité relative mensuelle',
    body: 'Data on maximum and minimum temperatures and monthly relative humidity in Qatar.',
    body_ar: 'بيانات عن درجات الحرارة القصوى والدنيا والرطوبة النسبية الشهرية في قطر.',
    body_fr: 'Données sur les températures maximales et minimales et l’humidité relative mensuelle au Qatar.',
  },
  {
    title: 'Fog, Dust Storm and Haze',
    title_ar: 'الضباب وعاصفة الغبار والضباب الدخاني',
    title_fr: 'Brouillard, tempête de poussière et brume',
    body: 'Data on occurrences of fog, dust storms, and haze in Qatar.',
    body_ar: 'بيانات عن حدوث الضباب وعواصف الغبار والضباب الدخاني في قطر.',
    body_fr: 'Données sur les occurrences de brouillard, de tempêtes de poussière et de brume au Qatar.',
  },
  {
    title: 'Average Daily Sunshine Hours by Months',
    title_ar: 'متوسط ​​ساعات سطوع الشمس اليومية حسب الأشهر',
    title_fr: 'Heures moyennes d’ensoleillement quotidien par mois',
    body: 'Average daily sunshine hours for each month in Qatar.',
    body_ar: 'متوسط ​​ساعات سطوع الشمس اليومية لكل شهر في قطر.',
    body_fr: 'Heures moyennes d’ensoleillement quotidien pour chaque mois au Qatar.',
  },
  {
    title: 'Annual Relative Humidity and Rainfall',
    title_ar: 'الرطوبة النسبية السنوية وهطول الأمطار',
    title_fr: 'Humidité relative et précipitations annuelles',
    body: 'Data on annual relative humidity and rainfall in Qatar.',
    body_ar: 'بيانات عن الرطوبة النسبية السنوية وهطول الأمطار في قطر.',
    body_fr: 'Données sur l’humidité relative et les précipitations annuelles au Qatar.',
  },
  {
    title: 'Quantities of Fertilizers Used',
    title_ar: 'كميات الأسمدة المستخدمة',
    title_fr: 'Quantités d’engrais utilisées',
    body: 'Information on the quantities of fertilizers used in Qatar.',
    body_ar: 'معلومات عن كميات الأسمدة المستخدمة في قطر.',
    body_fr: 'Informations sur les quantités d’engrais utilisées au Qatar.',
  },
  {
    title: 'Number of Recorded Terrestrial Violations',
    title_ar: 'عدد المخالفات الأرضية المسجلة',
    title_fr: 'Nombre de violations terrestres enregistrées',
    body: 'Data on the number of terrestrial violations recorded in Qatar.',
    body_ar: 'بيانات عن عدد المخالفات الأرضية المسجلة في قطر.',
    body_fr: 'Données sur le nombre de violations terrestres enregistrées au Qatar.',
  },
  {
    title: 'Number of Boats, Fishermen and Quantity of Local Catch',
    title_ar: 'عدد القوارب والصيادين وكمية الصيد المحلي',
    title_fr: 'Nombre de bateaux, de pêcheurs et quantité de prises locales',
    body: 'Data on the number of boats, fishermen, and the quantity of local catch in Qatar.',
    body_ar: 'بيانات عن عدد القوارب والصيادين وكمية الصيد المحلي في قطر.',
    body_fr: 'Données sur le nombre de bateaux, de pêcheurs et la quantité de prises locales au Qatar.',
  },
  {
    title: 'fishermenboats2001local catch quantity',
    title_ar: 'صيادون، قوارب، كمية صيد محلية لعام 2001',
    title_fr: 'Pêcheurs, bateaux, quantité de prises locales pour 2001',
    body: 'Data on the number of fishermen, boats, and the quantity of local catch in 2001.',
    body_ar: 'بيانات عن عدد الصيادين والقوارب وكمية الصيد المحلي في عام 2001.',
    body_fr: 'Données sur le nombre de pêcheurs, de bateaux et la quantité de prises locales en 2001.',
  },
  {
    title: 'Numbers of Arabian Oryx in Different Protected Areas',
    title_ar: 'أعداد المها العربي في مناطق محمية مختلفة',
    title_fr: 'Nombres d’oryx d’Arabie dans différentes zones protégées',
    body: 'Data on the number of Arabian Oryx in various protected areas in Qatar.',
    body_ar: 'بيانات عن عدد المها العربي في مناطق محمية مختلفة في قطر.',
    body_fr: 'Données sur le nombre d’oryx d’Arabie dans différentes zones protégées au Qatar.',
  },
  {
    title: 'Number and Risk of Extinction (IUCN 1) of Recorded Species in Qatar',
    title_ar: 'عدد وخطر الانقراض (IUCN 1) للأنواع المسجلة في قطر',
    title_fr: 'Nombre et risque d’extinction (UICN 1) des espèces enregistrées au Qatar',
    body: 'Information on the number of recorded species in Qatar and their risk of extinction according to the IUCN Red List.',
    body_ar: 'معلومات عن عدد الأنواع المسجلة في قطر وخطر انقراضها وفقًا للقائمة الحمراء للاتحاد الدولي للحفاظ على الطبيعة.',
    body_fr: 'Informations sur le nombre d’espèces enregistrées au Qatar et leur risque d’extinction selon la Liste rouge de l’UICN.',
  }
  // Add more data for this category
];

const employmentLaborData = [
  {
    title: 'Female Population (15 Years and Above) by Relation to Labor Force and Age Groups',
    title_ar: 'السكان الإناث (15 عامًا فما فوق) حسب العلاقة بقوة العمل والفئات العمرية',
    title_fr: 'Population féminine (15 ans et plus) par rapport à la population active et les groupes d’âge',
    body: 'Data on female population aged 15 years and above, categorized by their relationship to the labor force and age groups.',
    body_ar: 'بيانات عن السكان الإناث الذين تبلغ أعمارهم 15 عامًا فما فوق، مصنفة حسب علاقتهم بقوة العمل والفئات العمرية.',
    body_fr: 'Données sur la population féminine âgée de 15 ans et plus, classées par rapport à la population active et les groupes d’âge.',
  },
  {
    title: 'Economically Inactive Population (15 Years and Above) by Nationality, Gender and Educational Attainment',
    title_ar: 'السكان غير النشطين اقتصاديًا (15 عامًا فما فوق) حسب الجنسية والجنس والمستوى التعليمي',
    title_fr: 'Population économiquement inactive (15 ans et plus) par nationalité, sexe et niveau d’éducation',
    body: 'Data on economically inactive population aged 15 years and above, categorized by nationality, gender, and educational attainment.',
    body_ar: 'بيانات عن السكان غير النشطين اقتصاديًا الذين تبلغ أعمارهم 15 عامًا فما فوق، مصنفة حسب الجنسية والجنس والمستوى التعليمي.',
    body_fr: 'Données sur la population économiquement inactive âgée de 15 ans et plus',
  },
  {
    title: 'Economically Inactive Population (15 Years and Above) by Nationality, Gender and Age Groups',
    title_ar: 'السكان غير النشطين اقتصاديًا (15 عامًا فما فوق) حسب الجنسية والجنس والفئات العمرية',
    title_fr: 'Population économiquement inactive (15 ans et plus) par nationalité, sexe et groupes d’âge',
    body: 'Data on economically inactive population aged 15 years and above, categorized by nationality, gender, and age groups.',
    body_ar: 'بيانات عن السكان غير النشطين اقتصاديًا الذين تبلغ أعمارهم 15 عامًا فما فوق، مصنفة حسب الجنسية والجنس والفئات العمرية.',
    body_fr: 'Données sur la population économiquement inactive âgée de 15 ans et plus',
  },
  {
    title: 'Economically Active Population (15 Years and Above) by Nationality, Gender and Age Groups',
    title_ar: 'السكان النشطين اقتصاديًا (15 عامًا فما فوق) حسب الجنسية والجنس والفئات العمرية',
    title_fr: 'Population économiquement active (15 ans et plus) par nationalité, sexe et groupes d’âge',
    body: 'Data on economically active population aged 15 years and above, categorized by nationality, gender, and age groups.',
    body_ar: 'بيانات عن السكان النشطين اقتصاديًا الذين تبلغ أعمارهم 15 عامًا فما فوق، مصنفة حسب الجنسية والجنس والفئات العمرية.',
    body_fr: 'Données sur la population économiquement active âgée de 15 ans et plus',
  },
  {
    title: 'Economically Active Population (15 Years and Above) by Nationality, Gender and Age Groups',
    title_ar: 'السكان النشطين اقتصاديًا (15 عامًا فما فوق) حسب الجنسية والجنس والفئات العمرية',
    title_fr: 'Population économiquement active (15 ans et plus) par nationalité, sexe et groupes d’âge',
    body: 'Data on economically active population aged 15 years and above, categorized by nationality, gender, and age groups.',
    body_ar: 'بيانات عن السكان النشطين اقتصاديًا الذين تبلغ أعمارهم 15 عامًا فما فوق، مصنفة حسب الجنسية والجنس والفئات العمرية.',
    body_fr: 'Données sur la population économiquement active âgée de 15 ans et plus',
  },
  {
    title: 'Male Population (15 Years and Above) by Relation to Labor Force and Age Groups',
    title_ar: 'السكان الذكور (15 عامًا فما فوق) حسب العلاقة بقوة العمل والفئات العمرية',
    title_fr: 'Population masculine (15 ans et plus) par rapport à la population active et les groupes d’âge',
    body: 'Data on male population aged 15 years and above, categorized by their relationship to the labor force and age groups.',
    body_ar: 'بيانات عن السكان الذكور الذين تبلغ أعمارهم 15 عامًا فما فوق، مصنفة حسب علاقتهم بقوة العمل والفئات العمرية.',
    body_fr: 'Données sur la population masculine âgée de 15 ans et plus, classées par rapport à la population active et les groupes d’âge.',
  },
  {
    title: 'Employees in Youth Institutions by Occupation, Type of Work, Nationality and Gender',
    title_ar: 'الموظفون في مؤسسات الشباب حسب المهنة ونوع العمل والجنسية والجنس',
    title_fr: 'Employés dans les institutions de la jeunesse par profession, type de travail, nationalité et sexe',
    body: 'Data on employees in youth institutions, categorized by occupation, type of work, nationality, and gender.',
    body_ar: 'بيانات عن الموظفين في مؤسسات الشباب، مصنفة حسب المهنة ونوع العمل والجنسية والجنس.',
    body_fr: 'Données sur les employés des institutions de la jeunesse, classées par profession, type de travail, nationalité et sexe.',
  }
  // Add more data for this category
];

const educationTrainingData = [
  {
    title: 'Schools Staff',
    title_ar: 'موظفو المدارس',
    title_fr: 'Personnel des écoles',
    body: 'Data on the number and types of staff working in schools in Qatar.',
    body_ar: 'بيانات عن عدد وأنواع الموظفين العاملين في المدارس في قطر.',
    body_fr: 'Données sur le nombre et les types de personnel travaillant dans les écoles au Qatar.',
  },
  {
    title: 'School Results',
    title_ar: 'نتائج المدارس',
    title_fr: 'Résultats scolaires',
    body: 'Information on academic performance and achievement in schools in Qatar.',
    body_ar: 'معلومات عن الأداء الأكاديمي والإنجاز في المدارس في قطر.',
    body_fr: 'Informations sur les performances académiques et les réalisations dans les écoles au Qatar.',
  },
  {
    title: 'Students in Adult Learning',
    title_ar: 'الطلاب في التعليم للكبار',
    title_fr: 'Étudiants en apprentissage pour adultes',
    body: 'Data on the number of students participating in adult learning programs in Qatar.',
    body_ar: 'بيانات عن عدد الطلاب المشاركين في برامج التعليم للكبار في قطر.',
    body_fr: 'Données sur le nombre d’étudiants participant aux programmes d’apprentissage pour adultes au Qatar.',
  },
  {
    title: 'High Education Facilities',
    title_ar: 'مرافق التعليم العالي',
    title_fr: 'Établissements d’enseignement supérieur',
    body: 'Information on higher education institutions in Qatar.',
    body_ar: 'معلومات عن مؤسسات التعليم العالي في قطر.',
    body_fr: 'Informations sur les établissements d’enseignement supérieur au Qatar.',
  },
  {
    title: 'School Facilities',
    title_ar: 'مرافق المدارس',
    title_fr: 'Installations scolaires',
    body: 'Information on the types and availability of school facilities in Qatar.',
    body_ar: 'معلومات عن أنواع وتوافر مرافق المدارس في قطر.',
    body_fr: 'Informations sur les types et la disponibilité des installations scolaires au Qatar.',
  },
  {
    title: 'Students in Schools',
    title_ar: 'الطلاب في المدارس',
    title_fr: 'Étudiants dans les écoles',
    body: 'Data on the number of students enrolled in schools in Qatar.',
    body_ar: 'بيانات عن عدد الطلاب المسجلين في المدارس في قطر.',
    body_fr: 'Données sur le nombre d’étudiants inscrits dans les écoles au Qatar.',
  },
  {
    title: 'Students in Higher Education',
    title_ar: 'الطلاب في التعليم العالي',
    title_fr: 'Étudiants dans l’enseignement supérieur',
    body: 'Data on the number of students enrolled in higher education institutions in Qatar.',
    body_ar: 'بيانات عن عدد الطلاب المسجلين في مؤسسات التعليم العالي في قطر.',
    body_fr: 'Données sur le nombre d’étudiants inscrits dans les établissements d’enseignement supérieur au Qatar.',
  },
  {
    title: 'Graduation Details',
    title_ar: 'تفاصيل التخرج',
    title_fr: 'Détails de la graduation',
    body: 'Information on graduation rates and degree programs in higher education institutions in Qatar.',
    body_ar: 'معلومات عن معدلات التخرج وبرامج الشهادات في مؤسسات التعليم العالي في قطر.',
    body_fr: 'Informations sur les taux de graduation et les programmes de diplômes dans les établissements d’enseignement supérieur au Qatar.',
  },
  {
    title: 'High Education Staff',
    title_ar: 'موظفو التعليم العالي',
    title_fr: 'Personnel de l’enseignement supérieur',
    body: 'Data on the number and types of staff working in higher education institutions in Qatar.',
    body_ar: 'بيانات عن عدد وأنواع الموظفين العاملين في مؤسسات التعليم العالي في قطر.',
    body_fr: 'Données sur le nombre et les types de personnel travaillant dans les établissements d’enseignement supérieur au Qatar.',
  }
  // Add more data for this category
];



  const categories = [
    { name: 'Sports', data: sportsData },
    { name: 'Social Affairs', data: socialAffairsData },
    { name: 'Security and Jurisprudence', data: securityJurisprudenceData },
    { name: 'Population and Vital Statistics', data: populationVitalStatisticsData },
    { name: 'Media, Culture and Tourism', data: mediaCultureTourismData },
    { name: 'Health', data: healthData },
    { name: 'Finance and Economy', data: financeEconomyData },
    { name: 'Environment and Weather', data: environmentWeatherData },
    { name: 'Employment and Labor', data: employmentLaborData },
    { name: 'Education and Training', data: educationTrainingData },
  ];  
  
  const categoryIcons = {
    "Sports": <FaFutbol />, // Use the icons you prefer
    "Social Affairs": <FaHome />,
    "Security and Jurisprudence": <FaBalanceScale />,
    "Population and Vital Statistics": <FaUsers />,
    "Media, Culture and Tourism": <FaCamera />,
    "Health": <FaHeartbeat />,
    "Finance and Economy": <FaMoneyBill />,
    "Environment and Weather": <FaTree />,
    "Employment and Labor": <FaBriefcase />,
    "Education and Training": <FaGraduationCap />
  };
  
 
  useEffect(() => {
    const categoryName = category; // Default to 'Sports' if empty
    setActiveCategory(categoryName);
  }, [category]);
  const cards = categories.find(category => category.name === activeCategory)?.data || [];
  const chartComponents = {


  };


  const handleChartIconClick = (title) => {
    const Component = chartComponents[title];
    if (Component) {
      setActiveChartComponent(<Component onClose={handleCloseChartComponent} />);
    }
  };

  const handleCloseChartComponent = () => {
    setActiveChartComponent(null);
  };


  const tableComponents = {
    "Budget for Culture and Sports Sector Projects 2021": sport,
    "Those Who Practice Activities in Youth and Sports Institutions by Activity, Age Group, Nationality and Gender":youth_sports_activities,
    "School Results":school_students,
    "Schools Staff":school_staff,
    "Marriages by Nationality and Place of Wife":marriages_by_wife_location,
    "Marriages by Nationality and Place of Husband":marriages_by_husband_location,
    "Specialized Training Courses Implemented by Legal and Judicial Studies Center 2019":legal_studies_courses,
    "Population Estimate":population_estimates,
    "General Marriage Rate By Nationality and Gender per 1000 Population (15 Year and Above)":marriage_rate,
    "HOTELS, ROOMS, BEDS, OCCUPANTS AND TOURISTIC NIGHTS":hotel_statistics,
    "Heritage Sites in the State of Qatar":heritage_sites_in_the_state_of_qatar,
    "Budget for Health Sector Projects 2021":budget_for_health_sector_projects_2021,
    "Health Facilities":health_facilities,
    "Budget of the State of Qatar for 2021 Fiscal Year":state_budget_2021,
    "Budget for Transport and Communications Sector Projects 2021":transport_comm_budget,
    "Annual Average of Air Quality - Doha City 2015":doha_air_quality,
    "Maximum, Minimum Temperature and Monthly Relative Humidity":temperature_humidity,
    "Female Population (15 Years and Above) by Relation to Labor Force and Age Groups":female_labor_force,
    "Economically Inactive Population (15 Years and Above) by Nationality, Gender and Educational Attainment":inactive_pop_edu
  };

  const handleTableIconClick = (title) => {
    const ComponentTable = tableComponents[title];
    if (ComponentTable) {
      setActiveTableComponent(<ComponentTable onClose={handleCloseTableComponent} />);
    }
  };

  const handleCloseTableComponent = () => {
    setActiveTableComponent(null);
  };
  return (
    <>
      <Helmet>
        <title>Archive — State of Qatar Open Data</title>
      </Helmet>
      <link rel="stylesheet" href="https://cdnjs.cloudflare.com/ajax/libs/font-awesome/6.2.1/css/all.min.css" integrity="sha512-MV7K8+y+gLIBoVD59lQIYicR65iaqukzvf/nwasF0nqhPay5w/9lJmVM2hMDcnK1OnMGCdVK+iQrJ7lzPJQd1w==" crossorigin="anonymous" referrerpolicy="no-referrer" />

      <Navbar />
      <div style={{ paddingTop: "100px" }}>
        <Container style={{ direction: language === 'arabic' ? 'rtl' : 'ltr' }} className="card-slider-container">
          <h1>Archive</h1>

          <Row>
            <Col style={{ paddingTop: "50px" }}>
              <div className="button-group">
                {categories.map((category, index) => (
                  <button
                    key={index}
                    className={`category-button ${activeCategory === category.name ? 'btnn' : ''}`}
                    onClick={() => setActiveCategory(category.name)}
                  >
                    {currentTranslations[category.name]}
                  </button>
                ))}
              </div>
            </Col>
          </Row>
          <Row>
            <Col>
              <h2 className="population-header">
                <span className="icon-title-gap" style={{ marginRight: language === 'arabic' ? '0' : '10px', marginLeft: language === 'arabic' ? '10px' : '0' }}>{categoryIcons[activeCategory]}</span>{currentTranslations[activeCategory]}
              </h2>
              <div className="card-grid">
                {cards.map((card, index) => (
                  <div className="card" key={index}>
                    <div className="card-title">{language === 'arabic' ? card.title_ar : language === 'french' ? card.title_fr : card.title}</div>
                    <div className="card-body">{language === 'arabic' ? card.body_ar : language === 'french' ? card.body_fr : card.body}</div>
                    <div className="card-footer">
                    <button
  className="transparent-btn"
  onClick={() => handleTableIconClick(card.title)}
  style={{ float: language === 'arabic' ? 'right' : 'left' }}
  title={language === 'english' ? 'Table' : language === 'french' ? 'Tableau' : 'الجدول'}
>
  <AiOutlineTable />
</button>
                    </div>
                  </div>
                ))}
              </div>
            </Col>
          </Row>

          {/* Conditional rendering for active chart component */}
        </Container>
        {activeChartComponent && activeChartComponent}
        {activeTableComponent && activeTableComponent}

      </div>
      <div style={{ paddingTop: "100px" }}>
        <DemoFooter />
      </div>
    </>
  );
};

export default CardSlider;