import React from 'react';
import { useLanguage } from '../../../../LanguageContext';
import PieChart from './female_population_15_years_and_above_by_relation_to_labor_force_and_age_groups_Table';
import '../../../MonthlyStatistics/Tables/PopulationModal/Charts/PieChart.css';

const PieComponent = ({ onClose }) => {
  const { language } = useLanguage();

  return (
    <div id="select-modal1" > {/* Set direction */}
      <div className="modal-container1">
        <div className="modal-header1" dir={language === 'arabic' ? 'rtl' : 'ltr'}>
          <h3 className="modal-title1">
            {language === 'english'
              ? 'Female Population (15 Years and Above) by Relation to Labor Force and Age Groups'
              : language === 'french'
                ? 'Population féminine (15 ans et plus) par rapport à la population active et les groupes d’âge'
                : 'السكان الإناث (15 عامًا فما فوق) حسب العلاقة بقوة العمل والفئات العمرية'}
          </h3>
          <button className="close-button1" onClick={onClose}>
            {language === 'english'
              ? 'Close'
              : language === 'french'
                ? 'Fermer'
                : 'أغلق'}
          </button>
        </div>

        <div className="modal-body2">
          <PieChart />
        </div>
      </div>
    </div>
  );
};

export default PieComponent;