// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.about-us-container {
    margin-top: 50px;
    padding: 30px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    background-color: #EAEAEA;
    border-radius: 8px;
  }
  
  .about-us-row {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    text-align: center;
  }
  
  .about-us-title {
    font-size: 2.5em;
    color: #fff;
    background-color: #76102B;
    padding: 15px;
    border-radius: 8px;
  }
  
  .about-us-description {
    font-size: 1.2em;
    color: #666;
    margin-top: 20px;
    line-height: 1.6;
    white-space: pre-line; /* Ensure line breaks are rendered */
  }`, "",{"version":3,"sources":["webpack://./src/Components/HomePage/AboutUs/AboutUs.css"],"names":[],"mappings":"AAAA;IACI,gBAAgB;IAChB,aAAa;IACb,wCAAwC;IACxC,yBAAyB;IACzB,kBAAkB;EACpB;;EAEA;IACE,aAAa;IACb,uBAAuB;IACvB,mBAAmB;IACnB,sBAAsB;IACtB,kBAAkB;EACpB;;EAEA;IACE,gBAAgB;IAChB,WAAW;IACX,yBAAyB;IACzB,aAAa;IACb,kBAAkB;EACpB;;EAEA;IACE,gBAAgB;IAChB,WAAW;IACX,gBAAgB;IAChB,gBAAgB;IAChB,qBAAqB,EAAE,oCAAoC;EAC7D","sourcesContent":[".about-us-container {\n    margin-top: 50px;\n    padding: 30px;\n    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);\n    background-color: #EAEAEA;\n    border-radius: 8px;\n  }\n  \n  .about-us-row {\n    display: flex;\n    justify-content: center;\n    align-items: center;\n    flex-direction: column;\n    text-align: center;\n  }\n  \n  .about-us-title {\n    font-size: 2.5em;\n    color: #fff;\n    background-color: #76102B;\n    padding: 15px;\n    border-radius: 8px;\n  }\n  \n  .about-us-description {\n    font-size: 1.2em;\n    color: #666;\n    margin-top: 20px;\n    line-height: 1.6;\n    white-space: pre-line; /* Ensure line breaks are rendered */\n  }"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
