import React from 'react';
import { useLanguage } from '../../../../../LanguageContext';
import PieChart from './qatar_monthly_statistics_average_room_rateChart'; 
import '../../PopulationModal/Charts/PieChart.css';

const PieComponent = ({ onClose }) => {
  const { language } = useLanguage();

  return (
    <div id="select-modal1" >
      <div className="modal-container1">
        <div dir={language === 'arabic' ? 'rtl' : 'ltr'} className="modal-header1">
          <h3 className="modal-title1">
            {language === 'english'
              ? 'Qatar Monthly Statistics Average Room Rate'
              : language === 'french'
                ? 'Statistiques mensuelles du prix moyen des chambres d\'hôtel au Qatar'
                : 'إحصائيات قطر الشهرية لِمُعَدّل سعر الغرفة في الفنادق'}
          </h3>
          <button className="close-button1" onClick={onClose}>
            {language === 'english'
              ? 'Close'
              : language === 'french'
                ? 'Fermer'
                : 'أغلق'}
          </button>
        </div>

        <div className="modal-body2">
          <PieChart />
        </div>
      </div>
    </div>
  );
};

export default PieComponent;