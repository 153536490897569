import React, { useEffect, useState } from 'react';
import { MaterialReactTable, createMRTColumnHelper, useMaterialReactTable } from 'material-react-table';
import { Box, Button } from '@mui/material';
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import { mkConfig, generateCsv, download } from 'export-to-csv';
import { useLanguage } from '../../../../LanguageContext';

const columnHelper = createMRTColumnHelper();

const csvConfig = mkConfig({
  fieldSeparator: ',',
  decimalSeparator: '.',
  useKeysAsHeaders: true,
});

const translations = {
  lsn_year: {
    english: 'Year',
    arabic: 'السنة' 
  },
  level_of_education: {
    english: 'Level of Education',
    arabic: 'مستوى التعليم'
  },
  lmrhl_lt_lymy: {
    english: 'Level of Education',
    arabic: 'مستوى التعليم'
  },
  type_of_school_public_private: {
    english: 'Type of School (Public/Private)',
    arabic: 'نوع المدرسة (عام/خاص)'
  },
  nw_lmdrs_hkwmy_khs: {
    english: 'Type of School (Public/Private)',
    arabic: 'نوع المدرسة (عام/خاص)'
  },
  grade: {
    english: 'Grade',
    arabic: 'الصف'
  },
  lsf: {
    english: 'Grade',
    arabic: 'الصف'
  },
  gender: {
    english: 'Gender',
    arabic: 'الجنس'
  },
  lnw: {
    english: 'Gender',
    arabic: 'الجنس'
  },
  l_mr_age: {
    english: 'Age',
    arabic: 'العمر'
  },
  nationality: {
    english: 'Nationality',
    arabic: 'الجنسية'
  },
  ljnsy: {
    english: 'Nationality',
    arabic: 'الجنسية'
  },
  mjmw_ltlb_subtotal_students: {
    english: 'Total Students',
    arabic: 'إجمالي الطلاب'
  },
  exportAllData: {
    english: 'Export All Data',
    arabic: 'تصدير جميع البيانات'
  },
  exportAllRows: {
    english: 'Export All Rows',
    arabic: 'تصدير جميع الصفوف'
  },
  exportPageRows: {
    english: 'Export Page Rows',
    arabic: 'تصدير صفوف الصفحة'
  },
  exportSelectedRows: {
    english: 'Export Selected Rows',
    arabic: 'تصدير الصفوف المحددة'
  }
};

const Example = () => {
  const { language } = useLanguage();
  const [columns, setColumns] = useState([]);
  const [data, setData] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch(`${process.env.REACT_APP_API}/api/school_students`); // Replace with your actual API call
        const result = await response.json();

        if (result.columns && result.data) {
          const fetchedColumns = result.columns.map((col) => {
            const header = translations[col.key] ? translations[col.key][language] : col.header;

            // Hide Arabic-specific columns for English or French
            if (language === 'english' || language === 'french') {
              if (
                col.key === 'lmrhl_lt_lymy' ||
                col.key === 'nw_lmdrs_hkwmy_khs' ||
                col.key === 'lsf' ||
                col.key === 'lnw' ||
                col.key === 'ljnsy' 
              ) { 
                return null; 
              }
              return columnHelper.accessor(col.key, { header }); 
            } 
            // Hide English-specific columns for Arabic
            else if (language === 'arabic') {
              if (
                col.key === 'year' || 
                col.key === 'grade' ||
                col.key === 'gender' ||
                col.key === 'age' ||
                col.key === 'nationality' ||
                col.key === 'subtotal_students' ||
                col.key === 'level_of_education' || 
                col.key === 'type_of_school_public_private' 

              ) { 
                return null; 
              }
              return columnHelper.accessor(col.key, { header }); 
            }

            return null; 
          }).filter(Boolean);

          setColumns(fetchedColumns);
          setData(result.data);
        }
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };

    fetchData();
  }, [language]);

  const handleExportRows = (rows) => {
    const rowData = rows.map((row) => row.original);
    const csv = generateCsv(csvConfig)(rowData);
    download(csvConfig)(csv);
  };

  const handleExportData = () => {
    const csv = generateCsv(csvConfig)(data);
    download(csvConfig)(csv);
  };

  const table = useMaterialReactTable({
    columns,
    data,
    enableFullScreenToggle: false,
    enableRowSelection: true,
    columnFilterDisplayMode: 'popover',
    paginationDisplayMode: 'pages',
    positionToolbarAlertBanner: 'bottom',
    renderTopToolbarCustomActions: ({ table }) => (
      <Box
        sx={{
          display: 'flex',
          gap: '16px',
          padding: '8px',
          flexWrap: 'wrap',
        }}
      >
        <Button onClick={handleExportData} startIcon={<FileDownloadIcon />}>
          {translations.exportAllData[language]}
        </Button>
        <Button
          disabled={table.getPrePaginationRowModel().rows.length === 0}
          onClick={() => handleExportRows(table.getPrePaginationRowModel().rows)}
          startIcon={<FileDownloadIcon />}
        >
          {translations.exportAllRows[language]}
        </Button>
        <Button
          disabled={table.getRowModel().rows.length === 0}
          onClick={() => handleExportRows(table.getRowModel().rows)}
          startIcon={<FileDownloadIcon />}
        >
          {translations.exportPageRows[language]}
        </Button>
        <Button
          disabled={!table.getIsSomeRowsSelected() && !table.getIsAllRowsSelected()}
          onClick={() => handleExportRows(table.getSelectedRowModel().rows)}
          startIcon={<FileDownloadIcon />}
        >
          {translations.exportSelectedRows[language]}
        </Button>
      </Box>
    ),
  });

  return (
    <Box>
      <MaterialReactTable table={table} />
    </Box>
  );
};

export default Example;