import React, { useEffect, useState } from 'react';
import { MaterialReactTable, createMRTColumnHelper, useMaterialReactTable } from 'material-react-table';
import { Box, Button } from '@mui/material';
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import { mkConfig, generateCsv, download } from 'export-to-csv';
import { useLanguage } from '../../../../../LanguageContext';

const columnHelper = createMRTColumnHelper();

const csvConfig = mkConfig({
  fieldSeparator: ',',
  decimalSeparator: '.',
  useKeysAsHeaders: true,
});

const translations = {
  date_of_contract: {
    english: 'Date of Contract',
    french: 'Date du Contrat',
    arabic: 'تاريخ العقد',
  },
  municipality_name: {
    english: 'Municipality Name',
    french: 'Nom de la Municipalité',
    arabic: 'اسم البلدية',
  },
  zone_name: {
    english: 'Zone Name',
    french: 'Nom de la Zone',
    arabic: 'اسم المنطقة',
  },
  real_estate_type: {
    english: 'Real Estate Type',
    french: 'Type d\'Immeuble',
    arabic: 'نوع العقار',
  },
  area_in_square_meters: {
    english: 'Area (Sq. Meters)',
    french: 'Superficie (Mètres Carrés)',
    arabic: 'المساحة (متر مربع)',
  },
  price_per_square_foot: {
    english: 'Price per Sq. Foot',
    french: 'Prix ​​par Pied Carré',
    arabic: 'السعر لكل قدم مربع',
  },
  real_estate_value: {
    english: 'Real Estate Value',
    french: 'Valeur de l\'Immeuble',
    arabic: 'قيمة العقار',
  },
  January: {
    english: 'January',
    french: 'Janvier',
    arabic: 'يناير',
  },
  February: {
    english: 'February',
    french: 'Février',
    arabic: 'فبراير',
  },
  March: {
    english: 'March',
    french: 'Mars',
    arabic: 'مارس',
  },
  April: {
    english: 'April',
    french: 'Avril',
    arabic: 'أبريل',
  },
  May: {
    english: 'May',
    french: 'Mai',
    arabic: 'مايو',
  },
  June: {
    english: 'June',
    french: 'Juin',
    arabic: 'يونيو',
  },
  July: {
    english: 'July',
    french: 'Juillet',
    arabic: 'يوليو',
  },
  August: {
    english: 'August',
    french: 'Août',
    arabic: 'أغسطس',
  },
  September: {
    english: 'September',
    french: 'Septembre',
    arabic: 'سبتمبر',
  },
  October: {
    english: 'October',
    french: 'Octobre',
    arabic: 'أكتوبر',
  },
  November: {
    english: 'November',
    french: 'Novembre',
    arabic: 'نوفمبر',
  },
  December: {
    english: 'December',
    french: 'Décembre',
    arabic: 'ديسمبر',
  },
  exportAllData: {
    english: 'Export All Data',
    french: 'Exporter toutes les données',
    arabic: 'تصدير جميع البيانات',
  },
  exportAllRows: {
    english: 'Export All Rows',
    french: 'Exporter toutes les lignes',
    arabic: 'تصدير جميع الصفوف',
  },
  exportPageRows: {
    english: 'Export Page Rows',
    french: 'Exporter les lignes de la page',
    arabic: 'تصدير صفوف الصفحة',
  },
  exportSelectedRows: {
    english: 'Export Selected Rows',
    french: 'Exporter les lignes sélectionnées',
    arabic: 'تصدير الصفوف المحددة',
  },
};

const Example = () => {
  const { language } = useLanguage();
  const [columns, setColumns] = useState([]);
  const [data, setData] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch(`${process.env.REACT_APP_API}/api/table_real_state`);
        const result = await response.json();

        if (result.columns && result.data) {
          const fetchedColumns = result.columns.map((col) => {
            // Dynamically set column headers based on language
            const header = translations[col.key] ? translations[col.key][language] : col.header;

            // Hide Arabic-specific columns for English and French
            if (language === 'english' || language === 'french') {
              if (
                col.key === 'sm_lbldy' ||
                col.key === 'sm_lmntq' ||
                col.key === 'nw_l_qr'
              ) {
                return null;
              }
              return columnHelper.accessor(col.key, { header, size: col.size || 150 });
            }
            // Hide English-specific columns for Arabic
            else if (language === 'arabic') {
              if (
                col.key === 'municipality_name' ||
                col.key === 'zone_name' ||
                col.key === 'real_estate_type' 
              ) {
                return null;
              }
              return columnHelper.accessor(col.key, { header, size: col.size || 150 });
            }
            return null; // This should never happen, but it's a safety check
          }).filter(Boolean);

          // Format date_of_contract
          const formattedData = result.data.map(row => ({
            ...row,
            date_of_contract: formatDate(row.date_of_contract, language)
          }));

          setColumns(fetchedColumns);
          setData(formattedData);
        }
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };

    fetchData();
  }, [language]);

  const handleExportRows = (rows) => {
    const rowData = rows.map((row) => row.original);
    const csv = generateCsv(csvConfig)(rowData);
    download(csvConfig)(csv);
  };

  const handleExportData = () => {
    const csv = generateCsv(csvConfig)(data);
    download(csvConfig)(csv);
  };

  const table = useMaterialReactTable({
    columns,
    data,
    enableFullScreenToggle: false,
    enableRowSelection: true,
    columnFilterDisplayMode: 'popover',
    paginationDisplayMode: 'pages',
    positionToolbarAlertBanner: 'bottom',
    renderTopToolbarCustomActions: ({ table }) => (
      <Box
        sx={{
          display: 'flex',
          gap: '16px',
          padding: '8px',
          flexWrap: 'wrap',
        }}
      >
        <Button onClick={handleExportData} startIcon={<FileDownloadIcon />}>
          {translations.exportAllData[language]}
        </Button>
        <Button
          disabled={table.getPrePaginationRowModel().rows.length === 0}
          onClick={() => handleExportRows(table.getPrePaginationRowModel().rows)}
          startIcon={<FileDownloadIcon />}
        >
          {translations.exportAllRows[language]}
        </Button>
        <Button
          disabled={table.getRowModel().rows.length === 0}
          onClick={() => handleExportRows(table.getRowModel().rows)}
          startIcon={<FileDownloadIcon />}
        >
          {translations.exportPageRows[language]}
        </Button>
        <Button
          disabled={!table.getIsSomeRowsSelected() && !table.getIsAllRowsSelected()}
          onClick={() => handleExportRows(table.getSelectedRowModel().rows)}
          startIcon={<FileDownloadIcon />}
        >
          {translations.exportSelectedRows[language]}
        </Button>
      </Box>
    ),
  });

  // helper function for formatting date string
  const formatDate = (dateString, language) => {
    const [day, month, year] = dateString.split(" ");
    const translatedMonth = translations[month][language]; // Get translated month
    return `${day} ${translatedMonth} ${year}`; // Return formatted date
  }

  return (
    <Box>
      <MaterialReactTable table={table} />
    </Box>
  );
};

export default Example;