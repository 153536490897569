import React, { useState, useEffect } from 'react';
import axios from 'axios';
import ReactEcharts from 'echarts-for-react';
import { useLanguage } from '../../../../../../LanguageContext'; // Assuming you have a LanguageContext

const BarChart = () => {
  const { language } = useLanguage();
  const [selectedYear, setSelectedYear] = useState('2021');
  const [key, setKey] = useState(0);
  const [data, setData] = useState([]);

  const monthTranslations = {
    January: { english: 'January', french: 'Janvier', arabic: 'يناير' },
    February: { english: 'February', french: 'Février', arabic: 'فبراير' },
    March: { english: 'March', french: 'Mars', arabic: 'مارس' },
    April: { english: 'April', french: 'Avril', arabic: 'أبريل' },
    May: { english: 'May', french: 'Mai', arabic: 'مايو' },
    June: { english: 'June', french: 'Juin', arabic: 'يونيو' },
    July: { english: 'July', french: 'Juillet', arabic: 'يوليو' },
    August: { english: 'August', french: 'Août', arabic: 'أغسطس' },
    September: { english: 'September', french: 'Septembre', arabic: 'سبتمبر' },
    October: { english: 'October', french: 'Octobre', arabic: 'أكتوبر' },
    November: { english: 'November', french: 'Novembre', arabic: 'نوفمبر' },
    December: { english: 'December', french: 'Décembre', arabic: 'ديسمبر' },
  };

  const translateMonth = (month) => monthTranslations[month][language];

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.post(`${process.env.REACT_APP_API}/api/monthly_statistics_births`, { year: selectedYear });
        setData(response.data);
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };

    fetchData();
  }, [selectedYear]);

  const option = {
    tooltip: {
      trigger: 'axis',
      axisPointer: {
        type: 'shadow'
      }
    },
    grid: {
      left: '3%',
      right: '4%',
      bottom: '3%',
      containLabel: true
    },
    xAxis: [
      {
        type: 'category',
        data: Object.keys(monthTranslations).map(translateMonth),
        axisTick: {
          alignWithLabel: true
        },
        axisLabel: {
          textStyle: {
            color: 'white'
          },
          interval: 0
        }
      }
    ],
    yAxis: [
      {
        type: 'value',
        axisLabel: {
          textStyle: {
            color: 'white'
          }
        }
      }
    ],
    series: [
      {
        name: language === 'english' ? 'Total Live Births' : language === 'french' ? 'Total des naissances vivantes' : 'إجمالي المواليد الأحياء',
        type: 'bar',
        barWidth: '60%',
        data: data[0]?.map((value, index) => ({
          name: translateMonth(Object.keys(monthTranslations)[index]),
          value: parseFloat(value), 
        })),
        itemStyle: {
          color: '#7EB1B6'
        }
      }
    ]
  };

  const handleChange = (e) => {
    setSelectedYear(e.target.value);
    setKey(prevKey => prevKey + 1);
  };

  return (
    <div key={key} dir={language === 'arabic' ? 'rtl' : 'ltr'}>
      <label htmlFor="year"  style={{fontSize:"1rem", fontWeight:"600", color:"white"}}>
        {language === 'english' ? 'Select Year:' : 
         language === 'french' ? 'Sélectionnez une année:' : 
         'اختر عامًا:'}
      </label>

      <select className='select' id="year" onChange={handleChange} value={selectedYear}>
        {["2018", "2019", "2020", "2021", "2022", "2023", "2024", "2025", "2026", "2027", "2028", "2029", "2030", "2031", "2032"].map(year => (
          <option key={year} value={year}>{year}</option>
        ))}
      </select>
      <ReactEcharts option={option} style={{ width: "100%", height: "400px" }} />
    </div>
  );
};

export default BarChart;