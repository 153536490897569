import React, { useEffect, useState } from 'react';
import { MaterialReactTable, createMRTColumnHelper, useMaterialReactTable } from 'material-react-table';
import { Box, Button } from '@mui/material';
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import { mkConfig, generateCsv, download } from 'export-to-csv';
import { useLanguage } from '../../../../../LanguageContext';

const columnHelper = createMRTColumnHelper();

const csvConfig = mkConfig({
  fieldSeparator: ',',
  decimalSeparator: '.',
  useKeysAsHeaders: true,
});

const translations = {
  month: {
    english: 'Month',
    french: 'Mois',
    arabic: 'شهر',
  },
  dd_l_shm_lmtdwl_bl_lf_number_of_shares_traded_thousands_qr: {
    english: 'Number of Shares Traded (Thousands QR)',
    french: 'Nombre d\'actions échangées (Milliers QR)',
    arabic: 'عدد الأسهم المتداولة (آلاف الريالات)',
  },
  exportAllData: {
    english: 'Export All Data',
    french: 'Exporter toutes les données',
    arabic: 'تصدير جميع البيانات',
  },
  exportAllRows: {
    english: 'Export All Rows',
    french: 'Exporter toutes les lignes',
    arabic: 'تصدير جميع الصفوف',
  },
  exportPageRows: {
    english: 'Export Page Rows',
    french: 'Exporter les lignes de la page',
    arabic: 'تصدير صفوف الصفحة',
  },
  exportSelectedRows: {
    english: 'Export Selected Rows',
    french: 'Exporter les lignes sélectionnées',
    arabic: 'تصدير الصفوف المحددة',
  },
};

const monthTranslations = {
  January: { english: 'January', french: 'Janvier', arabic: 'يناير' },
  February: { english: 'February', french: 'Février', arabic: 'فبراير' },
  March: { english: 'March', french: 'Mars', arabic: 'مارس' },
  April: { english: 'April', french: 'Avril', arabic: 'أبريل' },
  May: { english: 'May', french: 'Mai', arabic: 'مايو' },
  June: { english: 'June', french: 'Juin', arabic: 'يونيو' },
  July: { english: 'July', french: 'Juillet', arabic: 'يوليو' },
  August: { english: 'August', french: 'Août', arabic: 'أغسطس' },
  September: { english: 'September', french: 'Septembre', arabic: 'سبتمبر' },
  October: { english: 'October', french: 'Octobre', arabic: 'أكتوبر' },
  November: { english: 'November', french: 'Novembre', arabic: 'نوفمبر' },
  December: { english: 'December', french: 'Décembre', arabic: 'ديسمبر' },
};

const translateMonth = (month, language) => {
  const [monthName, year] = month.split(' ');
  return `${monthTranslations[monthName][language]} ${year}`;
};

const Example = () => {
  const { language } = useLanguage();
  const [columns, setColumns] = useState([]);
  const [data, setData] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch(`${process.env.REACT_APP_API}/api/table_qatar_exchange_number_of_shares`);
        const result = await response.json();

        if (result.columns && result.data) {
          const fetchedColumns = result.columns.map((col) =>
            columnHelper.accessor(col.key, {
              header: translations[col.key][language],
              size: col.size || 150,
            })
          );

          const translatedData = result.data.map((row) => ({
            ...row,
            month: translateMonth(row.month, language),
          }));

          setColumns(fetchedColumns);
          setData(translatedData);
        }
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };

    fetchData();
  }, [language]);

  const handleExportRows = (rows) => {
    const rowData = rows.map((row) => row.original);
    const csv = generateCsv(csvConfig)(rowData);
    download(csvConfig)(csv);
  };

  const handleExportData = () => {
    const csv = generateCsv(csvConfig)(data);
    download(csvConfig)(csv);
  };

  const table = useMaterialReactTable({
    columns,
    data,
    enableFullScreenToggle: false,
    enableRowSelection: true,
    columnFilterDisplayMode: 'popover',
    paginationDisplayMode: 'pages',
    positionToolbarAlertBanner: 'bottom',
    renderTopToolbarCustomActions: ({ table }) => (
      <Box
        sx={{
          display: 'flex',
          gap: '16px',
          padding: '8px',
          flexWrap: 'wrap',
        }}
      >
        <Button onClick={handleExportData} startIcon={<FileDownloadIcon />}>
          {translations.exportAllData[language]}
        </Button>
        <Button
          disabled={table.getPrePaginationRowModel().rows.length === 0}
          onClick={() => handleExportRows(table.getPrePaginationRowModel().rows)}
          startIcon={<FileDownloadIcon />}
        >
          {translations.exportAllRows[language]}
        </Button>
        <Button
          disabled={table.getRowModel().rows.length === 0}
          onClick={() => handleExportRows(table.getRowModel().rows)}
          startIcon={<FileDownloadIcon />}
        >
          {translations.exportPageRows[language]}
        </Button>
        <Button
          disabled={!table.getIsSomeRowsSelected() && !table.getIsAllRowsSelected()}
          onClick={() => handleExportRows(table.getSelectedRowModel().rows)}
          startIcon={<FileDownloadIcon />}
        >
          {translations.exportSelectedRows[language]}
        </Button>
      </Box>
    ),
  });

  return (
    <Box>
      <MaterialReactTable table={table} />
    </Box>
  );
};

export default Example;
