import React from 'react';
import './404.css'
import logo1 from './astronaut.svg'
import logo2 from './planet.svg'
import { useNavigate } from 'react-router-dom';

const Page404 = () => {
  // useEffect is no longer needed as there are no side effects
  // useEffect(() => {
  //   // ... tsParticles code was here
  // }, []);
  const navigate = useNavigate();

  const handleLogout = () => {


      navigate('/Home');
  };

  return (
    <>
    
      <head>
        <title> State of Qatar Open Data | 404</title> 
        <link type="text/css" rel="stylesheet" href="css/404.css" />
        <link rel="icon" type="image/x-icon" href="../images/tsParticles-64.png" /> 
      </head>
      <body className="permission_denied">
        {/* Remove the div for tsParticles */}
        {/* <div id="tsparticles"></div> */} 
        <div className="denied__wrapper">
          <h1>404</h1>
          <h3>
            LOST IN <span>SPACE</span> ? Hmm, looks like that page doesn't exist.
          </h3>
          <img id="astronaut" src={logo1} alt="Astronaut" />
          <img id="planet" src={logo2} alt="Planet" />
            <button onClick={handleLogout} className="denied__link">Go Home</button>
        </div>
      </body>
    </>
  );
};

export default Page404;