import React, { useState } from 'react';
import './AddAdmin.css';
import axios from 'axios';
import './ModalComponent.css';
import Snackbar from '@mui/material/Snackbar';
import Alert from '@mui/material/Alert';
import { useLanguage } from "../LanguageContext"; // Import the useLanguage hook

const AddAdmin = ({ onClose }) => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [retryPassword, setRetryPassword] = useState('');
  const [name, setFullName] = useState('');
  const [error, setError] = useState('');
  const [loading, setLoading] = useState(false);
  const [showSuccessNotification, setShowSuccessNotification] = useState(false);
  const { language } = useLanguage(); 

  const handleNotificationClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setShowSuccessNotification(false);
  };

  const handleRegister = async (e) => {
    e.preventDefault();

    if (password !== retryPassword) {
      setError(
        language === 'english'
          ? 'Passwords do not match'
          : language === 'french'
          ? 'Les mots de passe ne correspondent pas'
          : 'كلمات المرور لا تتطابق'
      );
      return;
    }

    setLoading(true);
    setError('');

    try {
      await axios.post(`${process.env.REACT_APP_API}/api/add_admin`, {
        email,
        password,
        name,
      });

      setShowSuccessNotification(true); // Show success snackbar
      setError(
        language === 'english'
          ? 'Admin added successfully'
          : language === 'french'
          ? 'Administrateur ajouté avec succès'
          : 'تمت إضافة المسؤول بنجاح'
      );

      // Close the component after 3 seconds
      setTimeout(() => {
        setShowSuccessNotification(false);
        onClose();
      }, 3000);
    } catch (error) {
      setError(
        language === 'english'
          ? 'Email already exists'
          : language === 'french'
          ? 'L\'adresse e-mail existe déjà'
          : 'البريد الإلكتروني موجود مسبقاً'
      );
    } finally {
      setLoading(false);
    }
  };

  return (
    <div>
      <link
        rel="stylesheet"
        href="https://cdnjs.cloudflare.com/ajax/libs/font-awesome/6.2.1/css/all.min.css"
        integrity="sha512-MV7K8+y+gLIBoVD59lQIYicR65iaqukzvf/nwasF0nqhPay5w/9lJmVM2hMDcnK1OnMGCdVK+iQrJ7lzPJQd1w=="
        crossorigin="anonymous"
        referrerpolicy="no-referrer"
      />

      <div id="select-modal">
        <div className="modal-container">
          <div className="modal-body">
            <div className="form_wrapper">
              <div className="form_container">
                <div className="title_container">
                  <h2>
                    {language === 'english'
                      ? 'Add Admin'
                      : language === 'french'
                      ? 'Ajouter un administrateur'
                      : 'إضافة مسؤول'}
                  </h2>
                </div>
                {error && (
                  <div
                    style={{
                      color:
                        error ===
                        (language === 'english'
                          ? 'Admin added successfully'
                          : language === 'french'
                          ? 'Administrateur ajouté avec succès'
                          : 'تمت إضافة المسؤول بنجاح')
                          ? 'green'
                          : '#721c24',
                      backgroundColor:
                        error ===
                        (language === 'english'
                          ? 'Admin added successfully'
                          : language === 'french'
                          ? 'Administrateur ajouté avec succès'
                          : 'تمت إضافة المسؤول بنجاح')
                          ? '#d4edda'
                          : '#f8d7da',
                      borderColor:
                        error ===
                        (language === 'english'
                          ? 'Admin added successfully'
                          : language === 'french'
                          ? 'Administrateur ajouté avec succès'
                          : 'تمت إضافة المسؤول بنجاح')
                          ? '#c3e6cb'
                          : '#f5c6cb',
                      padding: '0.75rem 1.25rem',
                      marginBottom: '1rem',
                      border: '1px solid transparent',
                      borderRadius: '0.25rem',
                      textAlign: 'center',
                    }}
                    className="alert"
                  >
                    {error}
                  </div>
                )}
                <div className="row clearfix">
                  <div className="form_container1">
                    <form onSubmit={handleRegister}>
                      <div className="input_field">
                        <span>
                          <i className="fa fa-user" aria-hidden="true"></i>
                        </span>
                        <input
                          type="text"
                          name="name"
                          placeholder={
                            language === 'english'
                              ? 'Full Name'
                              : language === 'french'
                              ? 'Nom complet'
                              : 'الاسم الكامل'
                          }
                          required
                          value={name}
                          onChange={(e) => setFullName(e.target.value)}
                        />
                      </div>

                      <div className="input_field">
                        <span>
                          <i className="fa fa-envelope" aria-hidden="true"></i>
                        </span>
                        <input
                          type="email"
                          name="email"
                          placeholder={
                            language === 'english'
                              ? 'Email'
                              : language === 'french'
                              ? 'Email'
                              : 'البريد الإلكتروني'
                          }
                          required
                          value={email}
                          onChange={(e) => setEmail(e.target.value)}
                        />
                      </div>

                      <div className="input_field">
                        <span>
                          <i className="fa fa-lock" aria-hidden="true"></i>
                        </span>
                        <input
                          type="password"
                          name="password"
                          placeholder={
                            language === 'english'
                              ? 'Password'
                              : language === 'french'
                              ? 'Mot de passe'
                              : 'كلمة المرور'
                          }
                          required
                          value={password}
                          onChange={(e) => setPassword(e.target.value)}
                        />
                      </div>

                      <div className="input_field">
                        <span>
                          <i className="fa fa-lock" aria-hidden="true"></i>
                        </span>
                        <input
                          type="password"
                          name="password"
                          placeholder={
                            language === 'english'
                              ? 'Re-type Password'
                              : language === 'french'
                              ? 'Retapez le mot de passe'
                              : 'أعد كتابة كلمة المرور'
                          }
                          required
                          value={retryPassword}
                          onChange={(e) => setRetryPassword(e.target.value)}
                        />
                      </div>

                      <input
                        className="button"
                        type="submit"
                        disabled={loading}
                        value={
                          loading
                            ? language === 'english'
                              ? 'Adding...'
                              : language === 'french'
                              ? 'Ajout en cours...'
                              : 'جارٍ الإضافة...'
                            : language === 'english'
                            ? 'Add'
                            : language === 'french'
                            ? 'Ajouter'
                            : 'إضافة'
                        }
                      />
                      <input
                        className="button"
                        type="submit"
                        onClick={onClose}
                        value={
                          language === 'english'
                            ? 'Cancel'
                            : language === 'french'
                            ? 'Annuler'
                            : 'إلغاء'
                        }
                      />
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <Snackbar
        open={showSuccessNotification}
        autoHideDuration={3000}
        onClose={handleNotificationClose}
      >
        <Alert onClose={handleNotificationClose} severity="success" sx={{ width: '100%' }}>
          {language === 'english'
            ? 'Admin added successfully!'
            : language === 'french'
            ? 'Administrateur ajouté avec succès !'
            : '! تمت إضافة المسؤول بنجاح'}
        </Alert>
      </Snackbar>
    </div>
  );
};

export default AddAdmin;