import React from 'react';
import { Container } from 'reactstrap';
import Slider from 'react-slick';
import { FaHeartbeat, FaFutbol, FaBalanceScale, FaHome, FaUsers, FaCamera, FaMoneyBill, FaTree, FaBriefcase, FaGraduationCap } from 'react-icons/fa';

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import styles from './LandscapeCards.module.css';
import '../MonthlyStatistics/CardSlider.css'
import { useLanguage } from "../../LanguageContext"; 
import translations from '../../../translations.json'; 
import { Link } from 'react-router-dom';


const cardData = [
  { title: "Health", description: "Number of healthcare centers, reported diseases, vaccinations, patient statistics", icon: <FaHeartbeat />,
    title_ar: "الصحة",
    description_ar: "عدد مراكز الرعاية الصحية، والأمراض المبلغ عنها، والتطعيمات، وإحصائيات المرضى",
    title_fr: "Santé",
    description_fr: "Nombre de centres de santé, de maladies déclarées, de vaccinations, de statistiques sur les patients"
  },
  { title: "Sports", description: "Sports facilities, events, athlete statistics", icon: <FaFutbol />,
    title_ar: "الرياضة",
    description_ar: "منشآت رياضية، وفعاليات، وإحصائيات الرياضيين",
    title_fr: "Sports",
    description_fr: "Installations sportives, événements, statistiques sur les athlètes"
  },
  { title: "Education and Training", description: "Schools, universities, training centers, student statistics", icon: <FaGraduationCap />,
    title_ar: "التعليم والتدريب",
    description_ar: "المدارس، والجامعات، ومراكز التدريب، وإحصائيات الطلاب",
    title_fr: "Éducation et formation",
    description_fr: "Écoles, universités, centres de formation, statistiques sur les étudiants"
  },
  { title: "Security and Jurisprudence", description: "Police stations, crime rates, legal cases", icon: <FaBalanceScale />,
    title_ar: "الأمن والقضاء",
    description_ar: "مراكز الشرطة، ومعدلات الجريمة، والقضايا القانونية",
    title_fr: "Sécurité et jurisprudence",
    description_fr: "Commissariats de police, taux de criminalité, affaires judiciaires" 
  },
  { title: "Real Estate and Infrastructure", description: "Housing projects, infrastructure developments, property statistics", icon: <FaHome />,
    title_ar: "العقارات والبنية التحتية",
    description_ar: "مشاريع الإسكان، وتطوير البنية التحتية، وإحصائيات العقارات",
    title_fr: "Immobilier et infrastructure",
    description_fr: "Projets de logement, développements d'infrastructures, statistiques sur les biens immobiliers"
  },
  { title: "Population and Vital Statistics", description: "Census data, birth and death rates, population demographics", icon: <FaUsers />,
    title_ar: "السكان والإحصاءات الحيوية",
    description_ar: "بيانات التعداد، ومعدلات المواليد والوفيات، والديموغرافية السكانية",
    title_fr: "Population et statistiques vitales",
    description_fr: "Données du recensement, taux de natalité et de mortalité, données démographiques"
  },
  { title: "Media, Culture and Tourism", description: "Cultural events, tourism statistics, media coverage", icon: <FaCamera />,
    title_ar: "الإعلام والثقافة والسياحة",
    description_ar: "الفعاليات الثقافية، وإحصائيات السياحة، والتغطية الإعلامية",
    title_fr: "Médias, culture et tourisme",
    description_fr: "Événements culturels, statistiques touristiques, couverture médiatique"
  },
  { title: "Finance and Economy", description: "Economic indicators, financial institutions, market statistics", icon: <FaMoneyBill />,
    title_ar: "التمويل والاقتصاد",
    description_ar: "المؤشرات الاقتصادية، والمؤسسات المالية، وإحصائيات السوق",
    title_fr: "Finance et économie",
    description_fr: "Indicateurs économiques, institutions financières, statistiques du marché"
  },
  { title: "Environment and Weather", description: "Climate data, environmental policies, weather forecasts", icon: <FaTree />,
    title_ar: "البيئة والطقس",
    description_ar: "بيانات المناخ، والسياسات البيئية، وتوقعات الطقس",
    title_fr: "Environnement et météo",
    description_fr: "Données climatiques, politiques environnementales, prévisions météorologiques" 
  },
  { title: "Employment and Labor", description: "Job market trends, employment statistics, labor laws", icon: <FaBriefcase />,
    title_ar: "العمالة والعمل",
    description_ar: "اتجاهات سوق العمل، وإحصائيات العمالة، وقوانين العمل",
    title_fr: "Emploi et travail",
    description_fr: "Tendances du marché du travail, statistiques sur l'emploi, lois du travail"
  },
  { title: "Education and Training", description: "Schools, universities, training centers, student statistics", icon: <FaGraduationCap />,
    title_ar: "التعليم والتدريب",
    description_ar: "المدارس، والجامعات، ومراكز التدريب، وإحصائيات الطلاب",
    title_fr: "Éducation et formation",
    description_fr: "Écoles, universités, centres de formation, statistiques sur les étudiants" 
  }
];
const LandscapeCards = () => {
  const { language } = useLanguage(); 
  const currentTranslations = translations[language]; 

  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 1350, 
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
    nextArrow: <NextArrow />,
    prevArrow: <PrevArrow />,
  };

    return (
      <div className={styles.container}>
        <Container>
          <h1 className={styles.title}>{currentTranslations["ArchiveHome"]}</h1>
          <Slider {...settings}>
            {cardData.map((card, index) => (
              <div key={index}>
                <article className={styles.card__article}>
                  <div className={styles.card__overlay}>
                    <div className={styles.card__icon}>{card.icon}</div>
                    <h2 className={styles.card__title} style={{ direction: language === 'arabic' ? 'rtl' : 'ltr' }}>{language === 'arabic' ? card.title_ar : language === 'french' ? card.title_fr : card.title}</h2>
                    <p className={styles.card__description} style={{ direction: language === 'arabic' ? 'rtl' : 'ltr' }}>{language === 'arabic' ? card.description_ar : language === 'french' ? card.description_fr : card.description}</p>
                  </div>
                  <div className={styles.card__data} style={{marginBottom:"20px"}}>
                    <Link 
                      to={`/archive/${card.title}`} // Use the English title for the URL
                      className={styles.card__button}
                    >
                      {currentTranslations["Discover"]}
                    </Link>
                  </div>
                </article>
              </div>
            ))}
          </Slider>
        </Container>
      </div>
    );
  };


const NextArrow = (props) => {
  const { onClick } = props;
  return <div className="next-arrow" onClick={onClick}>❯</div>;
};

const PrevArrow = (props) => {
  const { onClick } = props;
  return <div className="prev-arrow" onClick={onClick}>❮</div>;
};

export default LandscapeCards;