import React, { useEffect, useState } from 'react';
import { MaterialReactTable, createMRTColumnHelper, useMaterialReactTable } from 'material-react-table';
import { Box, Button } from '@mui/material';
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import { mkConfig, generateCsv, download } from 'export-to-csv';
import { useLanguage } from '../../../../LanguageContext'; 

const columnHelper = createMRTColumnHelper();

const csvConfig = mkConfig({
  fieldSeparator: ',',
  decimalSeparator: '.',
  useKeysAsHeaders: true,
});

const translations = {
  qr_bn: {
    english: 'QR Billion',
    arabic: 'مليار ريال قطري',
    french: 'Milliards de Riyals Qataris'
  },
  '2021_budget': {
    english: '2021 Budget',
    arabic: 'ميزانية 2021',
    french: 'Budget 2021'
  },
  exportAllData: {
    english: 'Export All Data',
    arabic: 'تصدير جميع البيانات',
    french: 'Exporter toutes les données'
  },
  exportAllRows: {
    english: 'Export All Rows',
    arabic: 'تصدير جميع الصفوف',
    french: 'Exporter toutes les lignes' 
  },
  exportPageRows: {
    english: 'Export Page Rows',
    arabic: 'تصدير صفوف الصفحة',
    french: 'Exporter les lignes de la page' 
  },
  exportSelectedRows: {
    english: 'Export Selected Rows',
    arabic: 'تصدير الصفوف المحددة',
    french: 'Exporter les lignes sélectionnées' 
  }
};
const Example = () => {
  const { language } = useLanguage();
  const [columns, setColumns] = useState([]);
  const [data, setData] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch(`${process.env.REACT_APP_API}/api/state_budget_2021`); // Replace with your actual API endpoint
        const result = await response.json();

        if (result.columns && result.data) {
          setColumns(result.columns.map((col) => {
            const header = translations[col.key] ? translations[col.key][language] : col.header;
            return columnHelper.accessor(col.key, { header }); 
          }));
          setData(result.data);
        }
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };

    fetchData();
  }, [language]);

  const handleExportRows = (rows) => {
    const rowData = rows.map((row) => row.original);
    const csv = generateCsv(csvConfig)(rowData);
    download(csvConfig)(csv);
  };

  const handleExportData = () => {
    const csv = generateCsv(csvConfig)(data);
    download(csvConfig)(csv);
  };

  const table = useMaterialReactTable({
    columns,
    data,
    enableFullScreenToggle: false,
    enableRowSelection: true,
    columnFilterDisplayMode: 'popover',
    paginationDisplayMode: 'pages',
    positionToolbarAlertBanner: 'bottom',
    renderTopToolbarCustomActions: ({ table }) => (
      <Box
        sx={{
          display: 'flex',
          gap: '16px',
          padding: '8px',
          flexWrap: 'wrap',
        }}
      >
        <Button onClick={handleExportData} startIcon={<FileDownloadIcon />}>
          {translations.exportAllData[language]}
        </Button>
        <Button
          disabled={table.getPrePaginationRowModel().rows.length === 0}
          onClick={() => handleExportRows(table.getPrePaginationRowModel().rows)}
          startIcon={<FileDownloadIcon />}
        >
          {translations.exportAllRows[language]}
        </Button>
        <Button
          disabled={table.getRowModel().rows.length === 0}
          onClick={() => handleExportRows(table.getRowModel().rows)}
          startIcon={<FileDownloadIcon />}
        >
          {translations.exportPageRows[language]}
        </Button>
        <Button
          disabled={!table.getIsSomeRowsSelected() && !table.getIsAllRowsSelected()}
          onClick={() => handleExportRows(table.getSelectedRowModel().rows)}
          startIcon={<FileDownloadIcon />}
        >
          {translations.exportSelectedRows[language]}
        </Button>
      </Box>
    ),
  });

  return (
    <Box>
      <MaterialReactTable table={table} />
    </Box>
  );
};

export default Example;