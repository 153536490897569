import React, { useState } from 'react';
import './NewLogin.css'; 
import axios from 'axios'; 
import DemoFooter from "../HomePage/Footer/Footer";
import Navbar from "../HomePage/AboutUs/Navbar";
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';
import { useLanguage } from "../LanguageContext"; // Import the useLanguage hook
import { Helmet } from 'react-helmet';
const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const Login = () => {
  const { language } = useLanguage(); 

  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState('');
  const [loading, setLoading] = useState(false);
  const [name, setFullName] = useState('');
  const [retryPassword, setRetryPassword] = useState('');
  const [openSnackbar, setOpenSnackbar] = useState(false);

  const handleRegister = async (e) => {
    e.preventDefault();

    if (password !== retryPassword) {
      setError(
        language === 'english'
          ? 'Passwords do not match'
          : language === 'french'
          ? 'Les mots de passe ne correspondent pas'
          : 'كلمات المرور لا تتطابق'
      );
      return;
    }

    setLoading(true);
    setError('');

    try {
      await axios.post(`${process.env.REACT_APP_API}/api/register`, {
        email,
        password,
        name
      });

      setOpenSnackbar(true);
      setTimeout(() => {
        window.location.href = "/login"      
      }, 2000);
    } catch (error) {
      if (error.response && error.response.status === 409) {
        setError(
          language === 'english'
            ? 'Email already exists'
            : language === 'french'
            ? 'L\'adresse e-mail existe déjà'
            : 'البريد الإلكتروني موجود مسبقاً'
        );
      } else {
        setError(
          language === 'english'
            ? 'An error occurred. Please try again later.'
            : language === 'french'
            ? 'Une erreur s\'est produite. Veuillez réessayer plus tard.'
            : 'حدث خطأ. يرجى المحاولة مرة أخرى لاحقًا.'
        ); 
      }
    } finally {
      setLoading(false);
    }
  };

  const handleCloseSnackbar = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpenSnackbar(false);
  };

  return (
    <>  <Helmet>
    <title>Sign Up — State of Qatar Open Data</title>
    </Helmet>
      <Navbar />
      <div style={{ paddingTop: "100px" }}>
        <link rel="stylesheet" href="https://cdnjs.cloudflare.com/ajax/libs/font-awesome/6.2.1/css/all.min.css" integrity="sha512-MV7K8+y+gLIBoVD59lQIYicR65iaqukzvf/nwasF0nqhPay5w/9lJmVM2hMDcnK1OnMGCdVK+iQrJ7lzPJQd1w==" crossorigin="anonymous" referrerpolicy="no-referrer" />
        <div style={{ paddingTop: '5.5rem' }}>
          <div className="form_wrapper">
            <div className="form_container">
              <div className="title_container">
                <h2>
                  {language === 'english'
                    ? 'Register'
                    : language === 'french'
                    ? 'S\'inscrire'
                    : 'التسجيل'}
                </h2>
              </div>
              {error && (
                <div
                  style={{
                    color: '#721c24',
                    backgroundColor: '#f8d7da',
                    borderColor: '#f5c6cb',
                    padding: '0.75rem 1.25rem',
                    marginBottom: '1rem',
                    border: '1px solid transparent',
                    borderRadius: '0.25rem',
                    textAlign: 'center'
                  }}
                  className="alert"
                >
                  {error}
                </div>
              )}
              <div className="row clearfix">
                <div>
                  <form onSubmit={handleRegister}>
                    <div className="input_field">
                      <span><i className="fa fa-user" aria-hidden="true"></i></span>
                      <input
                        type="text"
                        name="name"
                        placeholder={
                          language === 'english'
                            ? 'Full Name'
                            : language === 'french'
                            ? 'Nom complet'
                            : 'الاسم الكامل'
                        }
                        required
                        value={name}
                        onChange={(e) => setFullName(e.target.value)}
                      />
                    </div>
                    <div className="input_field">
                      <span><i className="fa fa-envelope" aria-hidden="true"></i></span>
                      <input
                        type="email"
                        name="email"
                        placeholder={
                          language === 'english'
                            ? 'Email'
                            : language === 'french'
                            ? 'Email'
                            : 'البريد الإلكتروني'
                        }
                        required
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                      />
                    </div>
                    <div className="input_field">
                      <span><i className="fa fa-lock" aria-hidden="true"></i></span>
                      <input
                        type="password"
                        name="password"
                        placeholder={
                          language === 'english'
                            ? 'Password'
                            : language === 'french'
                            ? 'Mot de passe'
                            : 'كلمة المرور'
                        }
                        required
                        value={password}
                        onChange={(e) => setPassword(e.target.value)}
                      />
                    </div>
                    <div className="input_field">
                      <span><i className="fa fa-lock" aria-hidden="true"></i></span>
                      <input
                        type="password"
                        name="password"
                        placeholder={
                          language === 'english'
                            ? 'Re-type Password'
                            : language === 'french'
                            ? 'Retapez le mot de passe'
                            : 'أعد كتابة كلمة المرور'
                        }
                        required
                        value={retryPassword}
                        onChange={(e) => setRetryPassword(e.target.value)}
                      />
                    </div>
                    <input
                      className="button"
                      type="submit"
                      disabled={loading}
                      value={
                        loading
                          ? language === 'english'
                            ? 'Registering...'
                            : language === 'french'
                            ? 'Inscription en cours...'
                            : 'جارٍ التسجيل...'
                          : language === 'english'
                          ? 'Register'
                          : language === 'french'
                          ? 'S\'inscrire'
                          : 'التسجيل'
                      }
                    />
                    <div className="extraLinks">
                      <p className="customLink">
                        {language === 'english'
                          ? 'Have an Account?'
                          : language === 'french'
                          ? 'Vous avez déjà un compte ?'
                          : 'هل لديك حساب؟'}
                        {' '}
                        <a href="/login">
                          {language === 'english'
                            ? 'Login'
                            : language === 'french'
                            ? 'Connexion'
                            : 'تسجيل الدخول'}
                        </a>
                      </p>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
        <Snackbar open={openSnackbar} autoHideDuration={6000} onClose={handleCloseSnackbar}>
          <Alert onClose={handleCloseSnackbar} severity="success" sx={{ width: '100%' }}>
            {language === 'english'
              ? 'Registered successfully'
              : language === 'french'
              ? 'Inscription réussie'
              : 'تم التسجيل بنجاح'}
          </Alert>
        </Snackbar>
        <div style={{paddingTop:"100px"}}>
        <DemoFooter />
        </div>
      </div>
    </>
  );
}

export default Login;