import React, { useState } from 'react';
import './NewLogin.css'; 
import axios from 'axios'; 
import { useNavigate } from 'react-router-dom';
import { jwtDecode } from 'jwt-decode';
import { setRole, setToken } from '../AuthContext'; 
import DemoFooter from "../HomePage/Footer/Footer";
import Navbar from "../HomePage/AboutUs/Navbar";
import { useLanguage } from "../LanguageContext"; // Import the useLanguage hook
import { Helmet } from 'react-helmet';

const Login = ({ setToken }) => { // Add language prop
  const { language } = useLanguage(); 

  const navigate = useNavigate();
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState('');
  const [loading, setLoading] = useState(false);
  const errorM = language === 'english' ? 'Invalid email or password' : language === 'french' ? 'Email ou mot de passe invalide' : 'البريد الإلكتروني أو كلمة المرور غير صحيحة';
  const errorB = language === 'english' ? 'Email not active yet' : language === 'french' ? 'L\'adresse e-mail n\'est pas encore active' : 'البريد الإلكتروني غير نشط بعد';

  const handleLogin = async (e) => {
    e.preventDefault();
    setLoading(true);

    try {
      const response = await axios.post(`${process.env.REACT_APP_API}/api/login`, {
        email,
        password,
      });

      const token = response.data.token;
      localStorage.setItem('token', token);
      const decodedToken = jwtDecode(token);
      setRole(decodedToken.role === 'Admin');
      setToken(token);

      try {
        const response = await axios.get(`${process.env.REACT_APP_API}/api/protected`, {
          headers: {
            Authorization: `Bearer ${token}`
          }
        });

        if (response.status === 200) {
          navigate('/home');
        }
      } catch (error) {
        if (error.response && error.response.status === 401) {
          console.log('Token expired');
          localStorage.removeItem('token');
          navigate('/');
        } else {
          console.error(error);
        }
      }

    } catch (error) {
      if (error.response) {
        if (error.response.status === 403) {
          setError(errorB);
        } else {
          setError(errorM);
        }
      } else {
        console.error('An error occurred:', error);
      }
    } finally {
      setLoading(false);
    }
  };

  return (
    <>         <Helmet>
    <title>Login — State of Qatar Open Data</title>
    </Helmet>
      <Navbar />
      <div style={{ paddingTop: '100px' }}>
        <link
          rel="stylesheet"
          href="https://cdnjs.cloudflare.com/ajax/libs/font-awesome/6.2.1/css/all.min.css"
          integrity="sha512-MV7K8+y+gLIBoVD59lQIYicR65iaqukzvf/nwasF0nqhPay5w/9lJmVM2hMDcnK1OnMGCdVK+iQrJ7lzPJQd1w=="
          crossorigin="anonymous"
          referrerpolicy="no-referrer"
        />
        <div style={{ paddingTop: '5.5rem' }}>
          <div className="form_wrapper">
            <div className="form_container">
              <div className="title_container">
                <h2>
                  {language === 'english'
                    ? 'Sign In'
                    : language === 'french'
                    ? 'Connexion'
                    : 'تسجيل الدخول'}
                </h2>
              </div>
              {error && (
                <div
                  style={{
                    color:
                      error ===
                      (language === 'english'
                        ? 'Registration successful'
                        : language === 'french'
                        ? 'Inscription réussie'
                        : 'تم التسجيل بنجاح')
                        ? 'green'
                        : '#721c24',
                    backgroundColor:
                      error ===
                      (language === 'english'
                        ? 'Registration successful'
                        : language === 'french'
                        ? 'Inscription réussie'
                        : 'تم التسجيل بنجاح')
                        ? '#d4edda'
                        : '#f8d7da',
                    borderColor:
                      error ===
                      (language === 'english'
                        ? 'Registration successful'
                        : language === 'french'
                        ? 'Inscription réussie'
                        : 'تم التسجيل بنجاح')
                        ? '#c3e6cb'
                        : '#f5c6cb',
                    padding: '0.75rem 1.25rem',
                    marginBottom: '1rem',
                    border: '1px solid transparent',
                    borderRadius: '0.25rem',
                    textAlign: 'center',
                  }}
                  className="alert"
                >
                  {error}
                </div>
              )}
              <div className="row clearfix">
                <div>
                  <form onSubmit={handleLogin}>
                    <div className="input_field">
                      <span>
                        <i className="fa fa-envelope" aria-hidden="true"></i>
                      </span>
                      <input
                        type="email"
                        name="email"
                        placeholder={
                          language === 'english'
                            ? 'Email'
                            : language === 'french'
                            ? 'Email'
                            : 'البريد الإلكتروني'
                        }
                        required
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                      />
                    </div>

                    <div className="input_field">
                      <span>
                        <i className="fa fa-lock" aria-hidden="true"></i>
                      </span>
                      <input
                        type="password"
                        name="password"
                        placeholder={
                          language === 'english'
                            ? 'Password'
                            : language === 'french'
                            ? 'Mot de passe'
                            : 'كلمة المرور'
                        }
                        required
                        value={password}
                        onChange={(e) => setPassword(e.target.value)}
                      />
                    </div>

                    <input
                      className="button"
                      type="submit"
                      disabled={loading}
                      value={
                        loading
                          ? language === 'english'
                            ? 'Logging in...'
                            : language === 'french'
                            ? 'Connexion en cours...'
                            : 'جارٍ تسجيل الدخول...'
                          : language === 'english'
                          ? 'Login'
                          : language === 'french'
                          ? 'Connexion'
                          : 'تسجيل الدخول'
                      }
                    />

                    <div className="extraLinks">
                      <p className="customLink">
                        {language === 'english'
                          ? 'Create a new account?'
                          : language === 'french'
                          ? 'Créer un nouveau compte ?'
                          : 'إنشاء حساب جديد؟'}
                        {' '}
                        <a href="/Signup">
                          {language === 'english'
                            ? 'here'
                            : language === 'french'
                            ? 'ici'
                            : 'هنا'}
                        </a>
                      </p>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div style={{ paddingTop: '100px' }}>
          <DemoFooter />
        </div>
      </div>
    </>
  );
};

export default Login;