import React from 'react';
import { Container, Row, Col } from 'reactstrap';
import './AboutUs.css';
import IndexNavbar from "./Navbar";
import DemoFooter from "../Footer/Footer";
import { useLanguage } from "../../LanguageContext"; // Import the useLanguage hook
import translations from '../../../translations.json'; // Import the translations
import { Helmet } from 'react-helmet';
const AboutUs = () => {
  const { language } = useLanguage();
  const currentTranslations = translations[language]; // Get translations for the current language


  return (
    <>
      <link rel="stylesheet" href="https://cdnjs.cloudflare.com/ajax/libs/font-awesome/6.2.1/css/all.min.css" integrity="sha512-MV7K8+y+gLIBoVD59lQIYicR65iaqukzvf/nwasF0nqhPay5w/9lJmVM2hMDcnK1OnMGCdVK+iQrJ7lzPJQd1w==" crossorigin="anonymous" referrerpolicy="no-referrer" />
      <Helmet>
  <title>About Us — State of Qatar Open Data</title>
  </Helmet>
      <div>
        <IndexNavbar />
      </div>
      <div style={{ paddingTop: "100px" ,direction: language === 'arabic' ? 'rtl' : ''  }}>
        <Container className="about-us-container">
          <Row className="about-us-row">
            <Col md="12">
              <h1 className="about-us-title">{currentTranslations['AboutUsTitle']}</h1>
              <p className='about-us-description'>{currentTranslations['AboutUsDescription']}</p> 
           </Col>
          </Row>
        </Container>
      </div>
      <div style={{ paddingTop: "100px" }}>
        <DemoFooter />
      </div>
    </>
  );
};

export default AboutUs;
