import React from 'react';
import { Container, Row, Col } from 'reactstrap';
import { FaFacebookF, FaTwitter, FaLinkedinIn, FaYoutube, FaPhone, FaEnvelope } from 'react-icons/fa';
import Logo1 from './images/IvAbTQWuG2pO.png';
import Logo2 from './images/xJJxdjGXQTkP.png';
import { useLanguage } from '../../LanguageContext';
import translations from '../../../translations.json';
import './Footer.css';

const Footer = () => {
  const { language } = useLanguage();
  const currentTranslations = translations[language];

  return (
    <footer id="footer" role="contentinfo" >
      <div className="top-footer">
        <Container>
          <Row>
            <Col sm="3">
              <h3 className="footer-title">{currentTranslations["Contact UsF"]}</h3>
              <ul className="footer-links">
                <li><a href="/pages/open_data_policy1" className="footer-link">{currentTranslations["Open Data Policy"]}</a></li>
                <li><a href="/pages/license/annexes" className="footer-link">{currentTranslations["Open Data License"]}</a></li>
                <li><a href="/pages/handbook/" className="footer-link">{currentTranslations["Open Data Handbook"]}</a></li>
              </ul>
            </Col>
            <Col sm="5">
              <h3 className="footer-title">{currentTranslations["Useful Links"]}</h3>
              <ul className="footer-links">
                <li><a href="https://www.psa.gov.qa/en/Pages/default.aspx" className="footer-link">{currentTranslations["Planning and Statistics Authority"]}</a></li>
                <li><a href="http://portal.www.gov.qa/wps/portal" className="footer-link">{currentTranslations["Hukoomi portal"]}</a></li>
                <li><a href="http://www.almeezan.qa/Default.aspx?language=en" className="footer-link">{currentTranslations["Qatar Legal Portal"]}</a></li>
              </ul>
            </Col>
            <Col sm="4">
              <div className="government-logo" style={{ paddingTop: "2rem" }}>
                <img src={Logo1} alt="Logo 1" width="140" />
                <img src={Logo2} alt="Logo 2" style={{ marginLeft: '40px' }} width="150" />
              </div>
            </Col>
          </Row>
        </Container>
      </div>
      <div className="bottom-footer">
        <Container>
          <Row className="justify-content-center align-items-center">
            <Col md="7" className="mb-4 mb-md-0">
              <div className="legal">
                <p className="footer-text">{currentTranslations["© 2023 Government of Qatar"]}</p>
                <a style={{paddingLeft:"10px"}}  href="/pages/terms-of-use" className="footer-link">{currentTranslations["Terms of use"]}</a>
                <a href="https://de.ftp.opendatasoft.com/qatar/docs/English%20User%20Guide.pdf" className="footer-link" target="_blank" rel="noopener noreferrer" style={{ marginLeft: '10px' }}>{currentTranslations["Portal User Guide"]}</a>
                <a href="/pages/contact/" className="footer-link" target="_blank" rel="noopener noreferrer" style={{ marginLeft: '10px' }}>{currentTranslations["To Request Datasets click here"]}</a>
              </div>
            </Col>
            <Col md="3" className="mb-4 mb-md-0 text-center">
              <div className="social-links">
                <a href="https://www.facebook.com/HukoomiQatar/" target="_blank" rel="noopener noreferrer" className="social-link">
                  <FaFacebookF className="social-icon" />
                </a>
                <a href="https://twitter.com/HukoomiQatar" target="_blank" rel="noopener noreferrer" className="social-link">
                  <FaTwitter className="social-icon" />
                </a>
                <a href="https://www.linkedin.com/company/hukoomi-qatar?trk=tyah&trkInfo=clickedVertical:company,clickedEntityId:13250665,idx:2-1-2,tarId:1486036498240,tas:Hukoomi" target="_blank" rel="noopener noreferrer" className="social-link">
                  <FaLinkedinIn className="social-icon" />
                </a>
                <a href="https://www.youtube.com/channel/UCqM3dVIzE8lbCU6cfUsbChg" target="_blank" rel="noopener noreferrer" className="social-link">
                  <FaYoutube className="social-icon" />
                </a>
              </div>
            </Col>
            <Col md="2" className="mb-4 mb-md-0 text-center">
              <div className="contact">
                <div className="contact-item">
                  <FaPhone className="social-icon" />
                  <p>+974-44958888</p>
                </div>
                <div className="contact-item">
                  <FaEnvelope className="social-icon" />
                  <p>
                    <a className="footer-link" href="mailto:data@psa.gov.qa" title="data@psa.gov.qa">data@psa.gov.qa</a>
                  </p>
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </footer>
  );
}

export default Footer;
