import axios from 'axios';
import * as echarts from 'echarts';
import React, { useEffect, useRef, useState } from 'react';
import { useLanguage } from '../../../../../LanguageContext';

const DrivingLicensesChart = () => {
    const { language } = useLanguage();
    const [option, setOption] = useState(null);
    const [selectedYear, setSelectedYear] = useState("2021");
    const chartRef = useRef(null);
    const [key, setKey] = useState(0);

    const monthTranslations = {
        January: { english: 'January', french: 'Janvier', arabic: 'يناير' },
        February: { english: 'February', french: 'Février', arabic: 'فبراير' },
        March: { english: 'March', french: 'Mars', arabic: 'مارس' },
        April: { english: 'April', french: 'Avril', arabic: 'أبريل' },
        May: { english: 'May', french: 'Mai', arabic: 'مايو' },
        June: { english: 'June', french: 'Juin', arabic: 'يونيو' },
        July: { english: 'July', french: 'Juillet', arabic: 'يوليو' },
        August: { english: 'August', french: 'Août', arabic: 'أغسطس' },
        September: { english: 'September', french: 'Septembre', arabic: 'سبتمبر' },
        October: { english: 'October', french: 'Octobre', arabic: 'أكتوبر' },
        November: { english: 'November', french: 'Novembre', arabic: 'نوفمبر' },
        December: { english: 'December', french: 'Décembre', arabic: 'ديسمبر' },
    };

    const categoryTranslations = {
        'max_average_temperature_celsius': {
            english: 'Max Average Temperature (Celsius)',
            french: 'Température moyenne maximale (Celsius)',
            arabic: 'أعلى متوسط درجة حرارة (درجة مئوية)'
        },
        'min_average_temperature_celsius': {
            english: 'Min Average Temperature (Celsius)',
            french: 'Température moyenne minimale (Celsius)',
            arabic: 'أدنى متوسط درجة حرارة (درجة مئوية)'
        },
        'mean_wind_speed_knots': {
            english: 'Mean Wind Speed (Knots)',
            french: 'Vitesse moyenne du vent (nœuds)',
            arabic: 'متوسط سرعة الرياح (عقد)'
        },
        'average_relative_humidity_percent': {
            english: 'Average Relative Humidity (%)',
            french: 'Humidité relative moyenne (%)',
            arabic: 'متوسط الرطوبة النسبية (%)'
        },
        'msl_pressure_hPa': {
            english: 'Mean Sea Level Pressure (hPa)',
            french: 'Pression au niveau de la mer (hPa)',
            arabic: 'متوسط الضغط عند مستوى سطح البحر (هكتوباسكال)'
        }
    };

    const translate = (text, translations) => translations[text][language];

    const fetchData = async () => {
        try {
            const response = await axios.post(`${process.env.REACT_APP_API}/api/monthly_climate`, { year: selectedYear });
            const rawData = response.data;
            processData(rawData);
        } catch (error) {
            console.error('Error fetching data:', error);
        }
    };

    const processData = (rawData) => {
        const categories = [...new Set(rawData.map(item => item.Category))];

        const seriesList = categories.map(category => {
            const data = rawData.filter(item => item.Category === category)
                .map(item => ({ name: translate(item.Month, monthTranslations), value: parseFloat(item.Value) }));
            return {
                type: 'line',
                name: translate(category, categoryTranslations),
                data: data,
            };
        });

        const newOption = {
            tooltip: {
                trigger: 'axis',
                axisPointer: {
                    type: 'cross'
                },
            },
            legend: {
                data: categories.map(category => translate(category, categoryTranslations)),
                textStyle: {
                    color: "white"
                }
            },
            xAxis: {
                type: 'category',
                boundaryGap: false,
                data: rawData.filter((v, i, a) => a.findIndex(t => (t.Month === v.Month)) === i).map(item => translate(item.Month, monthTranslations)),
                axisLabel: {
                    textStyle: {
                        color: "white"
                    }
                }
            },
            yAxis: {
                type: 'value',
                axisLabel: {
                    textStyle: {
                        color: "white"
                    }
                }
            },
            series: seriesList
        };

        setOption(newOption);
    };

    useEffect(() => {
        fetchData();
    }, [selectedYear]);

    useEffect(() => {
        if (option && chartRef.current) {
            const myChart = echarts.init(chartRef.current);
            myChart.setOption(option);
        }
    }, [option, language]);

    useEffect(() => {
        fetchData();
    }, [language]);

    return (
        <div key={key} dir={language === 'arabic' ? 'rtl' : 'ltr'}>
            <div>
                <label htmlFor="year" style={{ fontSize: "1rem", fontWeight: "600", color: "white" }}>
                    {language === 'english' ? 'Select a year:' : language === 'french' ? 'Sélectionnez une année:' : 'اختر عامًا:'}
                </label>
                <select className='select' id="year" onChange={(e) => { setSelectedYear(e.target.value); setKey(prevKey => prevKey + 1); }} value={selectedYear}>
                    {["2018", "2019", "2020", "2021", "2022", "2023", "2024", "2025", "2026", "2027", "2028", "2029", "2030", "2031", "2032"].map(year => (
                        <option key={year} value={year}>{year}</option>
                    ))}
                </select>
            </div>
            <div ref={chartRef} style={{ width: "100%", height: "450px" }} />
        </div>
    );
};

export default DrivingLicensesChart;
