import React, { useState, useRef, useEffect } from 'react';
import axios from 'axios';
import './Chatbot.css';
import { useLanguage } from "../../LanguageContext"; // Import the useLanguage hook

const Chatbot = () => {
  const chatboxRef = useRef(null);
  const chatInputRef = useRef(null);
  const [userMessage, setUserMessage] = useState('');
  const [inputInitHeight, setInputInitHeight] = useState(0);
  const [showChatbot, setShowChatbot] = useState(false);
  const { language } = useLanguage();

  useEffect(() => {
    setInputInitHeight(chatInputRef.current?.scrollHeight || 0);
  }, []);

  const initialMessage = language === 'english'
    ? 'Hi there 👋 How can I help you today?'
    : language === 'french'
    ? 'Bonjour 👋 Comment puis-je vous aider aujourd\'hui?'
    : ' مرحبا 👋 كيف يمكنني مساعدتك اليوم؟';

  const [chatHistory, setChatHistory] = useState([
    {
      type: 'incoming',
      message: initialMessage,
    },
  ]);

  const updateChatHistory = (type, message) => {
    setChatHistory(prevChatHistory => [...prevChatHistory, { type, message }]);
  };

  const generateResponse = async () => {
    const API_URL = `${process.env.REACT_APP_API}/api/v_2/chatbot`;

    try {
      const loadingMessage = <div className="dot-ellipsis"><div></div><div></div><div></div><div></div></div>;
      updateChatHistory('incoming', loadingMessage);

      const response = await axios.post(API_URL, { question: userMessage });

      // Remove the loading message before adding the actual response
      setChatHistory(prevChatHistory => prevChatHistory.slice(0, -1));

      const answer = response.data.response;
      updateChatHistory(
        'incoming',
        answer || (language === 'english'
          ? "Sorry, Can't answer that question."
          : language === 'french'
          ? 'Désolé, je ne peux pas répondre à cette question.'
          : 'أنا آسف، لا يمكنني الإجابة على هذا السؤال.')
      );
    } catch (error) {
      // Remove the loading message before adding the error response
      setChatHistory(prevChatHistory => prevChatHistory.slice(0, -1));

      updateChatHistory(
        'error',
        language === 'english'
          ? 'Oops! Something went wrong. Please try again.'
          : language === 'french'
          ? 'Oups ! Quelque chose a mal tourné. Veuillez réessayer.'
          : 'عفواً! حدث خطأ ما. يرجى المحاولة مرة أخرى.'
      );
    } finally {
      chatboxRef.current?.scrollTo(0, chatboxRef.current?.scrollHeight || 0);
    }
  };

  const handleChat = () => {
    const trimmedMessage = userMessage.trim();
    if (!trimmedMessage) return;

    setUserMessage('');
    chatInputRef.current.style.height = `${inputInitHeight}px`;

    updateChatHistory('outgoing', trimmedMessage);

    generateResponse();
  };

  const handleInput = () => {
    chatInputRef.current.style.height = `${inputInitHeight}px`;
    chatInputRef.current.style.height = `${chatInputRef.current?.scrollHeight}px`;
  };

  const handleKeyDown = (e) => {
    if (e.key === 'Enter' && !e.shiftKey && window.innerWidth > 800) {
      e.preventDefault();
      handleChat();
    }
  };

  const toggleChatbot = () => {
    setShowChatbot(!showChatbot);
    document.body.classList.toggle('show-chatbot');
  };

  const isRTL = language === 'arabic';

  return (
    <div>
      <link
        rel="stylesheet"
        href="https://fonts.googleapis.com/css2?family=Material+Symbols+Outlined:opsz,wght,FILL,GRAD@48,400,0,0"
      />
      <link
        rel="stylesheet"
        href="https://fonts.googleapis.com/css2?family=Material+Symbols+Rounded:opsz,wght,FILL,GRAD@48,400,1,0"
      />
      <button className="chatbot-toggler" onClick={toggleChatbot}>
        <span className="material-symbols-rounded">mode_comment</span>
        <span className="material-symbols-outlined">close</span>
      </button>
      
      {showChatbot && (
        <div className="chatbot">
          <header>
            <h2></h2>
            <span className="close-btn material-symbols-outlined" onClick={toggleChatbot}>
              close
            </span>
          </header>
          <ul className="chatbox" ref={chatboxRef}>
            {chatHistory.map((chat, index) => (
              <li key={index} className={`chat ${chat.type}`}>
                {chat.type === 'incoming' && (
                  <img
                    src="https://s3-eu-central-1.amazonaws.com/aws-ec2-eu-central-1-opendatasoft-staticfileset/qatar/favicon?tstamp=16758391398199935"
                    alt="smart_toy"
                    style={{ width: '40px', height: '40px' }}
                  />
                )}
                <p>{chat.message}</p>
              </li>
            ))}
          </ul>
          <div className="chat-input">
            <textarea
              ref={chatInputRef}
              placeholder="Enter a message..."
              spellCheck={false}
              required
              value={userMessage}
              onChange={(e) => setUserMessage(e.target.value)}
              onInput={handleInput}
              onKeyDown={handleKeyDown}
            ></textarea>
            <span id="send-btn" className="material-symbols-rounded" onClick={handleChat}>
              send
            </span>
          </div>
        </div>
      )}
    </div>
  );
};

export default Chatbot;
