// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.navbar-notification {
  position: relative;
  cursor: pointer;
}

.notification-icon {
  font-size: 1.5rem;
  color: #fff;
}

.notification-count {
  position: absolute;
  top: -5px;
  right: -10px;
  background-color: red;
  color: white;
  border-radius: 50%;
  padding: 2px 6px;
  font-size: 0.75rem;
}

.notification-popover-body {
  max-height: 300px;
  overflow-y: auto;
  background: rgba(255, 255, 255, 0.5);
}

/* Custom Scrollbar Styles */
.notification-popover-body::-webkit-scrollbar {
  width: 4px;
}

.notification-popover-body::-webkit-scrollbar-track {
  background: rgba(255, 255, 255, 0.5);
}

.notification-popover-body::-webkit-scrollbar-thumb {
  background-color: #76102B;
  border-radius: 10px;
}

.notification-item {
  margin-bottom: 10px;
}
`, "",{"version":3,"sources":["webpack://./src/Components/HomePage/NavBar/Navbar.css"],"names":[],"mappings":"AAAA;EACE,kBAAkB;EAClB,eAAe;AACjB;;AAEA;EACE,iBAAiB;EACjB,WAAW;AACb;;AAEA;EACE,kBAAkB;EAClB,SAAS;EACT,YAAY;EACZ,qBAAqB;EACrB,YAAY;EACZ,kBAAkB;EAClB,gBAAgB;EAChB,kBAAkB;AACpB;;AAEA;EACE,iBAAiB;EACjB,gBAAgB;EAChB,oCAAoC;AACtC;;AAEA,4BAA4B;AAC5B;EACE,UAAU;AACZ;;AAEA;EACE,oCAAoC;AACtC;;AAEA;EACE,yBAAyB;EACzB,mBAAmB;AACrB;;AAEA;EACE,mBAAmB;AACrB","sourcesContent":[".navbar-notification {\n  position: relative;\n  cursor: pointer;\n}\n\n.notification-icon {\n  font-size: 1.5rem;\n  color: #fff;\n}\n\n.notification-count {\n  position: absolute;\n  top: -5px;\n  right: -10px;\n  background-color: red;\n  color: white;\n  border-radius: 50%;\n  padding: 2px 6px;\n  font-size: 0.75rem;\n}\n\n.notification-popover-body {\n  max-height: 300px;\n  overflow-y: auto;\n  background: rgba(255, 255, 255, 0.5);\n}\n\n/* Custom Scrollbar Styles */\n.notification-popover-body::-webkit-scrollbar {\n  width: 4px;\n}\n\n.notification-popover-body::-webkit-scrollbar-track {\n  background: rgba(255, 255, 255, 0.5);\n}\n\n.notification-popover-body::-webkit-scrollbar-thumb {\n  background-color: #76102B;\n  border-radius: 10px;\n}\n\n.notification-item {\n  margin-bottom: 10px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
