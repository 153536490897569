import React, { useState } from 'react';
import { Dropdown, DropdownToggle, DropdownMenu, DropdownItem } from 'reactstrap';
import Flag from 'react-world-flags';
import './LanguageSwitcher.css'; // Import custom CSS for styling
import { useLanguage } from './LanguageContext'; // Import the useLanguage hook

const LanguageSwitcher = () => {
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const { language, toggleLanguage } = useLanguage(); // Get language and toggleLanguage from context

  const toggleDropdown = () => setDropdownOpen(!dropdownOpen);

  const handleLanguageChange = (newLanguage, flag) => {
    toggleLanguage(newLanguage); // Update language in context
    // No need to manage selectedLanguage and selectedFlag, as the context is handling it
  };

  return (
    <Dropdown isOpen={dropdownOpen} toggle={toggleDropdown} className="language-switcher" >
      <DropdownToggle caret style={{ backgroundColor: 'white', border: '1px solid #ccc', padding: '5px 10px' }}>
        <Flag code={language === 'english' ? 'GB' : language === 'french' ? 'FR' : 'SA'} style={{ width: '20px', marginRight: '10px' }} />
        {language === 'english' ? 'English' : language === 'french' ? 'Français' : 'العربية'}
      </DropdownToggle>
      <DropdownMenu >
        <DropdownItem onClick={() => handleLanguageChange('english', 'GB')}>
          <Flag code="GB" style={{ width: '20px', marginRight: '10px' }} />
          English
        </DropdownItem>
        <DropdownItem onClick={() => handleLanguageChange('french', 'FR')}>
          <Flag code="FR" style={{ width: '20px', marginRight: '10px' }} />
          Français
        </DropdownItem>
        <DropdownItem onClick={() => handleLanguageChange('arabic', 'SA')}>
          <Flag code="SA" style={{ width: '20px', marginRight: '10px' }} />
          العربية
        </DropdownItem>
      </DropdownMenu>
    </Dropdown>
  );
};

export default LanguageSwitcher;