import React from 'react';
import { useLanguage } from '../../../../LanguageContext';
import PieChart from './annual_average_of_air_quality_doha_city_2015_Table';
import '../../../MonthlyStatistics/Tables/PopulationModal/Charts/PieChart.css';

const PieComponent = ({ onClose }) => {
  const { language } = useLanguage();

  return (
    <div id="select-modal1" > {/* Set direction */}
      <div className="modal-container1">
        <div className="modal-header1" dir={language === 'arabic' ? 'rtl' : 'ltr'}>
          <h3 className="modal-title1">
            {language === 'english'
              ? 'Annual Average of Air Quality - Doha City 2015'
              : language === 'french'
                ? 'Moyenne annuelle de la qualité de l’air - Ville de Doha 2015'
                : 'المتوسط السنوي لجودة الهواء - مدينة الدوحة 2015'}
          </h3>
          <button className="close-button1" onClick={onClose}>
            {language === 'english'
              ? 'Close'
              : language === 'french'
                ? 'Fermer'
                : 'أغلق'}
          </button>
        </div>

        <div className="modal-body2">
          <PieChart />
        </div>
      </div>
    </div>
  );
};

export default PieComponent;