import React from 'react';
import { useLanguage } from '../../../../LanguageContext';
import PieChart from './hotels_rooms_beds_occupants_and_touristic_nights__Table';
import '../../../MonthlyStatistics/Tables/PopulationModal/Charts/PieChart.css';

const PieComponent = ({ onClose }) => {
  const { language } = useLanguage();

  return (
    <div id="select-modal1" > {/* Set direction */}
      <div className="modal-container1">
        <div className="modal-header1" dir={language === 'arabic' ? 'rtl' : 'ltr'}>
          <h3 className="modal-title1">
            {language === 'english'
              ? 'HOTELS, ROOMS, BEDS, OCCUPANTS AND TOURISTIC NIGHTS'
              : language === 'french'
                ? 'HÔTELS, CHAMBRES, LITS, OCCUPANTS ET NUITS TOURISTIQUES'
                : 'الفنادق، الغرف، الأسرة، المقيمون وليالي السياحة'}
          </h3>
          <button className="close-button1" onClick={onClose}>
            {language === 'english'
              ? 'Close'
              : language === 'french'
                ? 'Fermer'
                : 'أغلق'}
          </button>
        </div>

        <div className="modal-body2">
          <PieChart />
        </div>
      </div>
    </div>
  );
};

export default PieComponent;