import React, { useState, useEffect } from "react";
import ReactDOM from "react-dom/client";
import { BrowserRouter, Route, Routes, Navigate } from "react-router-dom";
import 'bootstrap/dist/css/bootstrap.min.css';
import { jwtDecode } from 'jwt-decode';
import { LanguageProvider } from './Components/LanguageContext';

import "bootstrap/scss/bootstrap.scss";
import "assets/scss/paper-kit.scss?v=1.3.0";
import "assets/demo/demo.css?v=1.3.0";
import Home from "./Components/HomePage/Index.jsx";
import AboutUs from './Components/HomePage/AboutUs/AboutUs.jsx'
import ContactUs from './Components/HomePage/ContactUs/ContactUs.jsx'
import Login from "Components/NewLogin/Login.jsx";
import Signup from "./Components/NewLogin/Signup.jsx"
import Admin from "./Components/UserMenu/UserManagement.jsx"
import Page404 from "Components/404Page/404.jsx";
import Catalog from './Components/HomePage/Catalog/Catalog.jsx'
import Archive from './Components/HomePage/ArchiveList/CardSlider'
import Component from './Components/HomePage/ChatWindow/ChatbotComponent.jsx'
import Api from './Components/HomePage/Apis/Apis.jsx'
import { setRole ,getRole } from './Components/AuthContext.js';
import ApiDefault from './Components/HomePage/Apis/ApiDefault'

const root = ReactDOM.createRoot(document.getElementById("root"));

const App = () => {

  const [token, setToken] = useState('');
  const storedToken = localStorage.getItem('token') || '';
  let decodedToken = null;
  if (storedToken !== '') {
      decodedToken = jwtDecode(storedToken);
      setRole( decodedToken.role === 'Admin');

  }
  
  useEffect(() => {
    const storedToken = localStorage.getItem('token');

    if (storedToken) {
      const decodedToken = jwtDecode(storedToken);
      const currentTime = Date.now() / 1000;
      if (decodedToken.exp < currentTime) {
        console.log('Token expired');
        localStorage.removeItem('token');
        setToken('');
        setRole(false)}
        if (decodedToken.role !== 'Admin' && decodedToken.role !== 'User') {
            localStorage.removeItem('token');
            setToken('');
            setRole(false)
            window.location.href = '/404';
        }
       else {
        setToken(storedToken);
        setRole(decodedToken.role === 'Admin'); // Initialize role in roleManager
      }
    }
  }, []);
  return (
    <BrowserRouter>
      <LanguageProvider>
      <Component />
      <Routes>
      <Route path="/" element={<Home />} />

        <Route path="/Home" element={<Home />} />
        <Route path="/AboutUs" element={<AboutUs />} />
        <Route path="/ContactUs" element={<ContactUs />} />
        {/* Route for Admin protected page */}
        <Route 
          path="/Admin" 
          element={
            getRole()? <Admin setToken={setToken} /> : <Navigate to="/Home" replace />  
            
          } 
        />
        {/* Route for Login */}
        <Route
          path="/Login"
          element={token && token !== '' ? <Navigate to="/Home" replace />: <Login setToken={setToken}/>}
        />
        {/* Route for Signup */}
        <Route
          path="/Signup"
          element={
            token && token !== '' ?
            
            <Navigate to="/Home" replace /> :<Signup /> 
              
              
          } 
        />
        {/* Route for unknown paths */}
        <Route path="*" element={<Page404 />} />
        <Route path="/Catalog" element={<Catalog />} />
        <Route path="/Archive/:category" element={<Archive />} />
        <Route path="/Api/:dataset" element={<Api />} />
        <Route path="/Apis" element={<ApiDefault />} />




      </Routes>
      </LanguageProvider>
    </BrowserRouter>
    
  );
};

root.render(<App />);