import React, { useState } from 'react';
import { Container, Row, Col, Form, FormGroup, Label, Input, Button } from 'reactstrap';
import ReCAPTCHA from 'react-google-recaptcha';
import './ContactUs.css';
import DemoFooter from "../Footer/Footer";
import Navbar from "../AboutUs/Navbar";
import { useLanguage } from "../../LanguageContext"; // Import the useLanguage hook
import translations from '../../../translations.json'; // Import the translations
import { Helmet } from 'react-helmet';
const ContactUs = () => {
  const { language } = useLanguage();
  const currentTranslations = translations[language]; // Get translations for the current language
  const [captchaValue, setCaptchaValue] = useState(null);

  const handleCaptchaChange = (value) => {
    setCaptchaValue(value);
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    if (!captchaValue) {
      alert(currentTranslations['Please complete the CAPTCHA.']);
      return;
    }
    // Handle form submission
  };

  return (
    <>
         <Helmet>
  <title>Contact Us — State of Qatar Open Data</title>
  </Helmet>
        <link rel="stylesheet" href="https://cdnjs.cloudflare.com/ajax/libs/font-awesome/6.2.1/css/all.min.css" integrity="sha512-MV7K8+y+gLIBoVD59lQIYicR65iaqukzvf/nwasF0nqhPay5w/9lJmVM2hMDcnK1OnMGCdVK+iQrJ7lzPJQd1w==" crossorigin="anonymous" referrerpolicy="no-referrer" />

          <Navbar />
          <div style={{paddingTop:"100px", direction: language === "arabic" ? "rtl" : "ltr" }}>
    <Container className="contact-us-container">
      <Row className="contact-us-row">
        <Col md="12">
          <h1 className="contact-us-title">{currentTranslations['Contact Us']}</h1>
          <p className="contact-us-description">
            {currentTranslations['For any inquiries or Requests for New Datasets, please use the contact form below, or you can reach us on:']}
          </p>
          <p className="contact-us-description">
            {currentTranslations['From inside Qatar: 44958676']}
          </p>
          <p className="contact-us-description">
            {currentTranslations['From outside Qatar: +974-44958676']}
          </p>
          <p className="contact-us-description">
            {currentTranslations['All fields are mandatory, unless specified otherwise.']}
          </p>
          <Form className="contact-us-form" style={{textAlign:language === "arabic" ? "right" : ""} } onSubmit={handleSubmit}>
            <FormGroup>
              <Label for="name">{currentTranslations['Your Name']}</Label>
              <Input type="text" name="name" id="name" placeholder="John Doe" required />
            </FormGroup>
            <FormGroup>
              <Label for="email">{currentTranslations['Email']}</Label>
              <Input type="email" name="email" id="email" placeholder="johndoe@email.com" required />
            </FormGroup>
            <FormGroup>
              <Label for="subject">{currentTranslations['Subject']}</Label>
              <Input type="text" name="subject" id="subject" required />
            </FormGroup>
            <FormGroup>
              <Label for="message">{currentTranslations['Message']}</Label>
              <Input type="textarea" name="message" id="message" required />
            </FormGroup>
            <FormGroup>
              <ReCAPTCHA
                sitekey="YOUR_RECAPTCHA_SITE_KEY"
                onChange={handleCaptchaChange}
              />
            </FormGroup>
            <FormGroup className="text-center">
            <button className="btnnn" type="submit" color="primary">{currentTranslations['Submit']}</button>
            </FormGroup>
          </Form>
        </Col>
      </Row>
    </Container>
    </div>
        <div style={{paddingTop:"100px"}}>
        <DemoFooter />
        </div>
    </>
  );
};

export default ContactUs;
