import React, { useEffect, useState } from 'react';
import { MaterialReactTable, createMRTColumnHelper, useMaterialReactTable } from 'material-react-table';
import { Box, Button } from '@mui/material';
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import { mkConfig, generateCsv, download } from 'export-to-csv';
import { useLanguage } from '../../../../LanguageContext';

const columnHelper = createMRTColumnHelper();

const csvConfig = mkConfig({
  fieldSeparator: ',',
  decimalSeparator: '.',
  useKeysAsHeaders: true,
});

const translations = {
  activity: {
    english: 'Activity',
    french: 'Activité',
    arabic: 'النشاط'
  },
  l_nsht: {
    english: 'L_NSHT',
    french: 'L_NSHT',
    arabic: 'اسم النشاط' // Replace "اسم النشاط" with the actual Arabic translation
  },
  qtrywn_dhkwr_ql_mn_15_sn_less_than_15_years_qatari_males: {
    english: 'Qatari Males < 15 Years',
    french: 'Hommes qataris < 15 ans',
    arabic: 'ذكور قطريون أقل من 15 سنة'
  },
  qtryt_nth_ql_mn_15_sn_less_than_15_years_qatari_females: {
    english: 'Qatari Females < 15 Years',
    french: 'Femmes qataries < 15 ans',
    arabic: 'إناث قطريات أقل من 15 سنة'
  },
  gyr_qtrywn_dhkwr_ql_mn_15_sn_less_than_15_years_non_qatari_males: {
    english: 'Non-Qatari Males < 15 Years',
    french: 'Hommes non qataris < 15 ans',
    arabic: 'ذكور غير قطريون أقل من 15 سنة'
  },
  gyr_qtryt_nth_ql_mn_15_sn_less_than_15_years_non_qatari_females: {
    english: 'Non-Qatari Females < 15 Years',
    french: 'Femmes non qataries < 15 ans',
    arabic: 'إناث غير قطريات أقل من 15 سنة'
  },
  qtrywn_dhkwr16_19_qatari_males_16_19: {
    english: 'Qatari Males 16-19 Years',
    french: 'Hommes qataris 16-19 ans',
    arabic: 'ذكور قطريون من 16 إلى 19 سنة'
  },
  qtryt_nth_16_19_qatari_females_16_19: {
    english: 'Qatari Females 16-19 Years',
    french: 'Femmes qataries 16-19 ans',
    arabic: 'إناث قطريات من 16 إلى 19 سنة'
  },
  gyr_qtrywn_dhkwr_16_19_non_qatari_males_16_19: {
    english: 'Non-Qatari Males 16-19 Years',
    french: 'Hommes non qataris 16-19 ans',
    arabic: 'ذكور غير قطريون من 16 إلى 19 سنة'
  },
  gyr_qtryt_nth_16_19_non_qatair_females_16_19: {
    english: 'Non-Qatari Females 16-19 Years',
    french: 'Femmes non qataries 16-19 ans',
    arabic: 'إناث غير قطريات من 16 إلى 19 سنة'
  },
  qtrywn_dhkwr21_24_qatari_males_21_24: {
    english: 'Qatari Males 21-24 Years',
    french: 'Hommes qataris 21-24 ans',
    arabic: 'ذكور قطريون من 21 إلى 24 سنة'
  },
  qtryt_nth_21_24_qatari_females_21_24: {
    english: 'Qatari Females 21-24 Years',
    french: 'Femmes qataries 21-24 ans',
    arabic: 'إناث قطريات من 21 إلى 24 سنة'
  },
  gyr_qtrywn_dhkwr_21_24_non_qatari_males_21_24: {
    english: 'Non-Qatari Males 21-24 Years',
    french: 'Hommes non qataris 21-24 ans',
    arabic: 'ذكور غير قطريون من 21 إلى 24 سنة'
  },
  gyr_qtryt_nth_21_24_non_qatari_females_21_24: {
    english: 'Non-Qatari Females 21-24 Years',
    french: 'Femmes non qataries 21-24 ans',
    arabic: 'إناث غير قطريات من 21 إلى 24 سنة'
  },
  qtrywn_dhkwr_26_qatari_males_26: {
    english: 'Qatari Males 26+ Years',
    french: 'Hommes qataris 26 ans et plus',
    arabic: 'ذكور قطريون 26 سنة أو أكثر'
  },
  qtryt_nth_26_qatari_females_26: {
    english: 'Qatari Females 26+ Years',
    french: 'Femmes qataries 26 ans et plus',
    arabic: 'إناث قطريات 26 سنة أو أكثر'
  },
  gyr_qtrywn_dhkwr_26_non_qatari_males_26: {
    english: 'Non-Qatari Males 26+ Years',
    french: 'Hommes non qataris 26 ans et plus',
    arabic: 'ذكور غير قطريون 26 سنة أو أكثر'
  },
  gyr_qtryt_nth_26_non_qatari_females_26: {
    english: 'Non-Qatari Females 26+ Years',
    french: 'Femmes non qataries 26 ans et plus',
    arabic: 'إناث غير قطريات 26 سنة أو أكثر'
  },
  exportAllData: {
    english: 'Export All Data',
    french: 'Exporter toutes les données',
    arabic: 'تصدير جميع البيانات'
  },
  exportAllRows: {
    english: 'Export All Rows',
    french: 'Exporter toutes les lignes',
    arabic: 'تصدير جميع الصفوف'
  },
  exportPageRows: {
    english: 'Export Page Rows',
    french: 'Exporter les lignes de la page',
    arabic: 'تصدير صفوف الصفحة'
  },
  exportSelectedRows: {
    english: 'Export Selected Rows',
    french: 'Exporter les lignes sélectionnées',
    arabic: 'تصدير الصفوف المحددة'
  }
};

const Example = () => {
  const { language } = useLanguage();
  const [columns, setColumns] = useState([]);
  const [data, setData] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch(`${process.env.REACT_APP_API}/api/youth_sports_activities`); // Replace with your actual API call
        const result = await response.json();

        if (result.columns && result.data) {
          const fetchedColumns = result.columns.map((col) => {
            // Dynamically set column headers based on language
            const header = translations[col.key] ? translations[col.key][language] : col.header;

            // Hide Arabic-specific columns for English and French
            if (language === 'english' || language === 'french') {
              // Make sure to check the actual keys you want to hide
              if (
                col.key === 'l_nsht' 
              ) {
                return null;
              }
              return columnHelper.accessor(col.key, { header, size: col.size || 150 });
            }
            // Hide English-specific columns for Arabic
            else if (language === 'arabic') {
              // Check the actual keys to be hidden for Arabic
              if (
                col.key === 'activity'
              ) {
                return null;
              }
              return columnHelper.accessor(col.key, { header, size: col.size || 150 });
            }
            return null; // This should never happen, but it's a safety check
          }).filter(Boolean);

          setColumns(fetchedColumns);
          setData(result.data);
        }
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };

    fetchData();
  }, [language]);

  const handleExportRows = (rows) => {
    const rowData = rows.map((row) => row.original);
    const csv = generateCsv(csvConfig)(rowData);
    download(csvConfig)(csv);
  };

  const handleExportData = () => {
    const csv = generateCsv(csvConfig)(data);
    download(csvConfig)(csv);
  };

  const table = useMaterialReactTable({
    columns,
    data,
    enableFullScreenToggle: false,
    enableRowSelection: true,
    columnFilterDisplayMode: 'popover',
    paginationDisplayMode: 'pages',
    positionToolbarAlertBanner: 'bottom',
    renderTopToolbarCustomActions: ({ table }) => (
      <Box
        sx={{
          display: 'flex',
          gap: '16px',
          padding: '8px',
          flexWrap: 'wrap',
        }}
      >
        <Button onClick={handleExportData} startIcon={<FileDownloadIcon />}>
          {translations.exportAllData[language]}
        </Button>
        <Button
          disabled={table.getPrePaginationRowModel().rows.length === 0}
          onClick={() => handleExportRows(table.getPrePaginationRowModel().rows)}
          startIcon={<FileDownloadIcon />}
        >
          {translations.exportAllRows[language]}
        </Button>
        <Button
          disabled={table.getRowModel().rows.length === 0}
          onClick={() => handleExportRows(table.getRowModel().rows)}
          startIcon={<FileDownloadIcon />}
        >
          {translations.exportPageRows[language]}
        </Button>
        <Button
          disabled={!table.getIsSomeRowsSelected() && !table.getIsAllRowsSelected()}
          onClick={() => handleExportRows(table.getSelectedRowModel().rows)}
          startIcon={<FileDownloadIcon />}
        >
          {translations.exportSelectedRows[language]}
        </Button>
      </Box>
    ),
  });

  return (
    <Box>
      <MaterialReactTable table={table} />
    </Box>
  );
};

export default Example;