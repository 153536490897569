import React, { useState, useEffect } from 'react';
import './UserManagement.css';
import Pagination from './Pagination';
import axios from 'axios';
import AddAdmin from './AddAdmin';
import { useNavigate } from 'react-router-dom';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Snackbar from '@mui/material/Snackbar';
import Alert from '@mui/material/Alert';
import Navbar from "../HomePage/AboutUs/Navbar";
import { useLanguage } from "../LanguageContext"; // Import the useLanguage hook
import { Helmet } from 'react-helmet';

const UserManagementTable = () => {
  const navigate = useNavigate();
  const itemsPerPage = 5;
  const [currentPage, setCurrentPage] = useState(1);
  const [users, setUsers] = useState([]);
  const [loading, setLoading] = useState(true);
  const [searchQuery, setSearchQuery] = useState('');
  const [allUsers, setAllUsers] = useState([]);
  const [isAddAdminModalOpen, setIsAddAdminModalOpen] = useState(false);
  const [confirmDeleteDialogOpen, setConfirmDeleteDialogOpen] = useState(false);
  const [userToDelete, setUserToDelete] = useState(null);
  const [showSuccessNotification, setShowSuccessNotification] = useState(false);
  const [showSuccessNotificationU, setShowSuccessNotificationU] = useState(false);
  const { language } = useLanguage(); 

  const [errors, setErrors] = useState([]);

  const openAddAdminModal = () => {
    setIsAddAdminModalOpen(true);
  };

  const closeAddAdminModal = () => {
    setIsAddAdminModalOpen(false);
  };

  useEffect(() => {
    const fetchUsers = async () => {
      try {
        const response = await axios.get(`${process.env.REACT_APP_API}/api/all_users`);
        setUsers(response.data);
        setAllUsers(response.data); // Save the original list of all users
        setLoading(false);
      } catch (error) {
        console.error('Error fetching users:', error);
        setLoading(false);
      }
    };

    fetchUsers();
  }, []);

  useEffect(() => {
    const fetchAllUsers = async () => {
      try {
        const response = await axios.get(`${process.env.REACT_APP_API}/api/all_users`);
        setAllUsers(response.data);
      } catch (error) {
        console.error('Error fetching all users:', error);
      }
    };

    fetchAllUsers();
  }, [searchQuery]);

  const fetchUsersByEndpoint = async (endpoint) => {
    try {
      const response = await axios.get(`${process.env.REACT_APP_API}/api/${endpoint}`);
      setUsers(response.data);
      if (searchQuery === '') {
        setAllUsers(response.data); // Update allUsers only if not searching
      }
      setCurrentPage(1); // Reset current page to 1 when fetching new users
    } catch (error) {
      console.error(`Error fetching ${endpoint} users:`, error);
    }
  };

  const handleTotalUsersClick = () => {
    fetchUsersByEndpoint('all_users');
  };

  const handleInactiveClick = () => {
    fetchUsersByEndpoint('inactive_users');
  };

  const handleActiveClick = () => {
    fetchUsersByEndpoint('active_users');
  };

  const handleSearchChange = (event) => {
    setSearchQuery(event.target.value);
  };

  const filteredUsers = searchQuery === ''
    ? allUsers
    : users.filter(user =>
      user.name.toLowerCase().includes(searchQuery.toLowerCase()) ||
      user.email.toLowerCase().includes(searchQuery.toLowerCase())
    );

  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentUsers = filteredUsers.slice(indexOfFirstItem, indexOfLastItem);

  const totalPages = Math.ceil(filteredUsers.length / itemsPerPage);

  const onPageChange = (page) => {
    setCurrentPage(page);
  };

  const handleDeleteUserClick = (user) => {
    setUserToDelete(user);
    setConfirmDeleteDialogOpen(true);
  };

  const handleConfirmDeleteClose = () => {
    setConfirmDeleteDialogOpen(false);
    setUserToDelete(null);
  };

  const handleConfirmDelete = async () => {
    if (userToDelete) {
      try {
        await axios.post(`${process.env.REACT_APP_API}/api/delete_user`, { email: userToDelete.email });
        // Update the allUsers state to remove the deleted user
        setAllUsers(prevUsers => prevUsers.filter(user => user.email !== userToDelete.email));
        // Update the users state to reflect the filtered users
        setUsers(prevUsers => prevUsers.filter(user => user.email !== userToDelete.email));

        // Show success notification
        setShowSuccessNotification(true); 
      } catch (error) {
        console.error('Error deleting user:', error);
      } finally {
        setConfirmDeleteDialogOpen(false);
        setUserToDelete(null);
      }
    }
  };

  const handleSettingsClick = async (user) => {
    const newStatus = user.status === 'Active' ? 'Inactive' : 'Active';
    try {
      await axios.post(`${process.env.REACT_APP_API}/api/update_status`, { email: user.email, status: newStatus });
      // Update the user's status in the allUsers state
      setAllUsers(prevUsers =>
        prevUsers.map(u =>
          u.email === user.email ? { ...u, status: newStatus } : u
        )
      );
      // Update the users state to reflect the filtered users
      setUsers(prevUsers =>
        prevUsers.map(u =>
          u.email === user.email ? { ...u, status: newStatus } : u
        )
      );
    } catch (error) {
      console.error('Error changing status:', error);
    }
  };

  const handleNotificationClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setShowSuccessNotification(false);
    setShowSuccessNotificationU(false);

  };







  // Sample notification list (replace with your actual data fetching)
  useEffect(() => {
    const fetchErrors = async () => {
      try {
        const response = await axios.get(`${process.env.REACT_APP_API}/api/pending_errors`); // Assuming API is at the root path
        setErrors(response.data);
      } catch (error) {
        console.error('Error fetching pending errors:', error);
      } finally {
        setLoading(false);
      }
    };

    fetchErrors();
  }, []);


  return (
    <>
    <Helmet>
    <title>User Management — State of Qatar Open Data</title>
    </Helmet>
        <link rel="stylesheet" href="https://cdnjs.cloudflare.com/ajax/libs/font-awesome/6.2.1/css/all.min.css" integrity="sha512-MV7K8+y+gLIBoVD59lQIYicR65iaqukzvf/nwasF0nqhPay5w/9lJmVM2hMDcnK1OnMGCdVK+iQrJ7lzPJQd1w==" crossorigin="anonymous" referrerpolicy="no-referrer" />

        <link rel="stylesheet" href="https://fonts.googleapis.com/css?family=Roboto|Varela+Round" />
        <link rel="stylesheet" href="https://fonts.googleapis.com/icon?family=Material+Icons" />
 <Navbar />
 <div style={{ direction: language === 'arabic' ? 'rtl' : 'ltr', textAlign: language === 'arabic' ? 'right' : 'left' ,paddingTop:'100px'}}>
  <div className="search">
    <input
      className="odswidget-searchbox__box"
      type="text"
      placeholder={
        language === 'english'
          ? 'Search Users'
          : language === 'french'
          ? 'Rechercher des utilisateurs'
          : 'البحث عن المستخدمين'
      }
      aria-label='Search'
      value={searchQuery}
      onChange={handleSearchChange}
    />
  </div>

  <div className="table-responsive">
    <div className="table-wrapper">
      <div className="table-title">
        <div className="row">
          <div className="col-sm-4">
            <h2>
              {language === 'english'
                ? 'User Management'
                : language === 'french'
                ? 'Gestion des utilisateurs'
                : 'إدارة المستخدمين'}
            </h2>
          </div>
          <div className="col-sm-8">
            <button className="btn btn-secondary" onClick={openAddAdminModal}>
              <i className="material-icons"></i>
              <span>
                {language === 'english'
                  ? 'Add New Admin'
                  : language === 'french'
                  ? 'Ajouter un nouvel administrateur'
                  : 'إضافة مسؤول جديد'}
              </span>
            </button>
            <button className="btn btn-secondary" onClick={handleInactiveClick}>
              <i className="material-icons"></i>
              <span>
                {language === 'english'
                  ? 'Inactive'
                  : language === 'french'
                  ? 'Inactifs'
                  : 'غير الناشطين'}
              </span>
            </button>
            <button className="btn btn-secondary" onClick={handleActiveClick}>
              <i className="material-icons"></i>
              <span>
                {language === 'english'
                  ? 'Active '
                  : language === 'french'
                  ? 'Actifs'
                  : 'الناشطين'}
              </span>
            </button>
            <button className="btn btn-secondary" onClick={handleTotalUsersClick}>
              <i className="material-icons"></i>
              <span>
                {language === 'english'
                  ? 'Total Users'
                  : language === 'french'
                  ? 'Total  utilisateurs'
                  : 'إجمالي المستخدمين'}
              </span>
            </button>
          </div>
        </div>
      </div>

      {loading ? (
        <p>
          {language === 'english'
            ? 'Loading...'
            : language === 'french'
            ? 'Chargement...'
            : 'جاري التحميل...'}
        </p>
      ) : (
        <table className="table table-striped table-hover">
          <thead>
            <tr>
              <th>
                {language === 'english'
                  ? '#'
                  : language === 'french'
                  ? '#'
                  : '#'}
              </th>
              <th>
                {language === 'english'
                  ? 'Name'
                  : language === 'french'
                  ? 'Nom'
                  : 'الاسم'}
              </th>
              <th>
                {language === 'english'
                  ? 'Email'
                  : language === 'french'
                  ? 'Email'
                  : 'البريد الإلكتروني'}
              </th>
              <th>
                {language === 'english'
                  ? 'Date Created'
                  : language === 'french'
                  ? 'Date de création'
                  : 'تاريخ الإنشاء'}
              </th>
              <th>
                {language === 'english'
                  ? 'Role'
                  : language === 'french'
                  ? 'Rôle'
                  : 'الدور'}
              </th>
              <th>
                {language === 'english'
                  ? 'Status'
                  : language === 'french'
                  ? 'Statut'
                  : 'الحالة'}
              </th>
              <th>
                {language === 'english'
                  ? 'Action'
                  : language === 'french'
                  ? 'Action'
                  : 'الإجراء'}
              </th>
            </tr>
          </thead>
          <tbody>
            {currentUsers.length > 0 ? (
              currentUsers.map((user) => (
                <tr key={user.id}>
                  <td>{user.id}</td>
                  <td>{user.name}</td>
                  <td>{user.email}</td>
                  <td>{user.dateCreated}</td>
                  <td>
                    {language === 'english'
                      ? user.role
                      : language === 'french'
                      ? user.role === 'Admin'
                        ? 'Administrateur'
                        : 'Utilisateur'
                      : user.role === 'Admin'
                      ? 'مسؤول'
                      : 'مستخدم'}
                  </td>
                  <td>
                    <span
                      className={`status ${
                        user.status === 'Active' ? 'text-success' : 'text-danger'
                      }`}
                    >
                      •
                    </span>
                    {language === 'english'
                      ? user.status
                      : language === 'french'
                      ? user.status === 'Active'
                        ? 'Actif'
                        : 'Inactif'
                      : user.status === 'Active'
                      ? 'ناشط'
                      : 'غير ناشط'}
                  </td>
                  <td>
                    <button
                      className="settings transparent-btn"
                      title={
                        language === 'english'
                          ? 'Change status'
                          : language === 'french'
                          ? 'Changer le statut'
                          : 'تغيير الحالة'
                      }
                      data-toggle="tooltip"
                      onClick={() => handleSettingsClick(user)}
                    >
                      <i
                        style={{ fontSize: '26px' }}
                        className={`material-icons ${
                          user.status === 'Active' ? 'text-green' : 'text-gray'
                        }`}
                      >
                        {user.status === 'Active' ? '\ue9f6' : '\ue9f5'}
                      </i>
                    </button>
                    <button
                      className="delete transparent-btn"
                      title={
                        language === 'english'
                          ? 'Delete'
                          : language === 'french'
                          ? 'Supprimer'
                          : 'حذف'
                      }
                      data-toggle="tooltip"
                      onClick={() => handleDeleteUserClick(user)} // Updated onClick handler
                    >
                      <i className="material-icons"></i>
                    </button>
                  </td>
                </tr>
              ))
            ) : (
              <tr>
                <td colSpan="7">
                  {language === 'english'
                    ? 'No users found'
                    : language === 'french'
                    ? 'Aucun utilisateur trouvé'
                    : 'لم يتم العثور على مستخدمين'}
                </td>
              </tr>
            )}
          </tbody>
        </table>
      )}
    </div>
  </div>
</div>

<Pagination
  currentPage={currentPage}
  totalPages={totalPages}
  onPageChange={onPageChange}
/>

{/* Add Admin Modal */}
<div>
  {isAddAdminModalOpen && <AddAdmin onClose={closeAddAdminModal} />}
</div>

{/* Delete Confirmation Dialog */}
<Dialog open={confirmDeleteDialogOpen} onClose={handleConfirmDeleteClose}>
  <DialogTitle>
    {language === 'english'
      ? 'Confirm Delete'
      : language === 'french'
      ? 'Confirmer la suppression'
      : 'تأكيد الحذف'}
  </DialogTitle>
  <DialogContent>
    <DialogContentText>
      {language === 'english'
        ? 'Are you sure you want to delete this user?'
        : language === 'french'
        ? 'Êtes-vous sûr de vouloir supprimer cet utilisateur ?'
        : 'هل أنت متأكد أنك تريد حذف هذا المستخدم؟'}
    </DialogContentText>
  </DialogContent>
  <DialogActions>
    <Button onClick={handleConfirmDeleteClose}>
      {language === 'english'
        ? 'Cancel'
        : language === 'french'
        ? 'Annuler'
        : 'إلغاء'}
    </Button>
    <Button onClick={handleConfirmDelete} color="error">
      {language === 'english'
        ? 'Delete'
        : language === 'french'
        ? 'Supprimer'
        : 'حذف'}
    </Button>
  </DialogActions>
</Dialog>

{/* Success Notification */}
<Snackbar
  open={showSuccessNotification}
  autoHideDuration={3000}
  onClose={handleNotificationClose}
>
  <Alert onClose={handleNotificationClose} severity="success" sx={{ width: '100%' }}>
    {language === 'english'
      ? 'User deleted successfully!'
      : language === 'french'
      ? '! L\'utilisateur a été supprimé avec succès'
      : '! تم حذف المستخدم بنجاح'}
  </Alert>
</Snackbar>
<Snackbar
  open={showSuccessNotificationU}
  autoHideDuration={3000}
  onClose={handleNotificationClose}
>
  <Alert onClose={handleNotificationClose} severity="success" sx={{ width: '100%' }}>
    {language === 'english'
      ? 'Issue solved successfully!'
      : language === 'french'
      ? '! Le problème a été résolu avec succès'
      : '! المشكلة تم حلها بنجاح'}
  </Alert>
</Snackbar>

{/* Notification List (displayed when icon is clicked) */}
    </>
  );
};

export default UserManagementTable;