import React from 'react';
import { useLanguage } from '../../../../LanguageContext';
import PieChart from './budget_of_the_state_of_qatar_for_2021_fiscal_year_Table';
import '../../../MonthlyStatistics/Tables/PopulationModal/Charts/PieChart.css';

const PieComponent = ({ onClose }) => {
  const { language } = useLanguage();

  return (
    <div id="select-modal1" > {/* Set direction */}
      <div className="modal-container1">
        <div className="modal-header1" dir={language === 'arabic' ? 'rtl' : 'ltr'}>
          <h3 className="modal-title1">
            {language === 'english'
              ? 'Budget of the State of Qatar for 2021 Fiscal Year'
              : language === 'french'
                ? 'Budget de l’État du Qatar pour l’exercice fiscal 2021'
                : 'ميزانية دولة قطر للسنة المالية 2021'}
          </h3>
          <button className="close-button1" onClick={onClose}>
            {language === 'english'
              ? 'Close'
              : language === 'french'
                ? 'Fermer'
                : 'أغلق'}
          </button>
        </div>

        <div className="modal-body2">
          <PieChart />
        </div>
      </div>
    </div>
  );
};

export default PieComponent;